import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import ButtonComponent from '../../../Inputs/ButtonComponent';
import localStorageCall from '../../../services/Methods/localstorage.hook';
import { ErrorDisplay, _setTimeOut } from '../../../services/Methods/normalMethods';
import { OneTimePassPropsType } from '../../../services/Methods/PropsTypesInterface';
import { ValidateCustomerProfile } from '../../../services/Redux/Reducer/LoginSlice';
import MESSAGE_TEXT from '../../../TextConfigs/Message.text';

export const OTPResend = () => {

    const [resentStatus, setResentStatus] = useState('');
    const [timer, setTimer] = useState(30);
    const dispatch = useDispatch();

    useEffect(() => {
        const interval = setInterval(() => {
            if (timer !== 0) {
                setTimer((prv) => prv - 1);
            }
        }, 1000);

        return () => clearInterval(interval)
    })

    function _otpResend() {
        const token = localStorageCall().getItem('Token');
        dispatch(ValidateCustomerProfile(token, (res) => {
            if (res) {
                setResentStatus('Otp sent successfully on your mobile number.');
                _setTimeOut(() => setResentStatus(''), 3000);
            }
        }));
    }

    return (
        <div className='resent_text_section'>
            {timer === 0 ?
                <>
                    <ButtonComponent className='resent_otp_again text-base text-button-color hover:text-button-hover-color' onClick={() => _otpResend()}>Re-send Otp</ButtonComponent>
                    {resentStatus && <p>{resentStatus}</p>}
                </>
                :
                <p>Your code can be resent in : {timer}'s</p>
            }
        </div>
    )
}


const OneTimePass = ({ otp, handleChangeValidate, _handleOtpSubmit, buttonText }) => {
    const memoRizedSelector = createSelector(
        (state) => state.LoginSlice,
        (data) => ({ errorMsg: data.errorMsg })
    )
    const { errorMsg } = useSelector((state) => memoRizedSelector(state));
    return (
        <div className='main_otp_div text-center'>
            <p className='m-0 mb-4'>Please enter that one-time passcode sent to your mobile device.</p>
            <div className='field_sections flex justify-center gap-[10px] my-[10px]'>
                {otp.map((data, index) => {
                    return (
                        <input
                            className="otp-field text-center w-full max-w-[45px] p-[10px] border border-button-color rounded-[8px]"
                            type="text"
                            name="otp"
                            maxLength="1"
                            key={index}
                            value={data}
                            onChange={e => handleChangeValidate(e.target, index)}
                            onFocus={e => e.target.select()}
                        />
                    );
                })}
            </div>
            <OTPResend />
            <div className='button_action mt-8'>
                <ButtonComponent onClick={() => _handleOtpSubmit()}>{buttonText}</ButtonComponent>
            </div>
            {errorMsg && <div className='errorMsg'><ErrorDisplay message={MESSAGE_TEXT?.otp_error} /></div>}
        </div>
    )
}

OneTimePass.propTypes = OneTimePassPropsType;

OneTimePass.defaultProps = {
    otp: new Array(6).fill(''),
    buttonText: 'Submit'
}

export default OneTimePass;