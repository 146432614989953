import React from 'react'
import { ThankyouStyle } from '../../../styles/ThankYouPage.style';
import withRouter from '../../services/HOC/withRouterHOC';
import MainThankYou from './components/MainThankyou';
import useThankYouHook from './components/Thankyou.hook';

const ThankYouPage = (props) => {
    const { searchParams } = props;

    const { data, autoshiporder } = useThankYouHook({
        orderId: searchParams.get('orderId'),
        autoshipId: searchParams.get('autoshipId'), ...props
    });

    return (
        <ThankyouStyle>
            <MainThankYou {...{ data, autoshiporder }} />
        </ThankyouStyle>
    )
}

export default withRouter(ThankYouPage);