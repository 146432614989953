import React, { useMemo } from 'react'
import _ from 'lodash';
import classNames from 'classnames';
import { Delete } from '@mui/icons-material';
import { FloatValues, storeAssigned, _productPrice, getCurrencySign } from '../../../services/Methods/normalMethods';
import localStorageCall from '../../../services/Methods/localstorage.hook';
import { QuantityComponent } from '../../../services/Methods/commonPayload';
import ROUTING_TEXT from '../../../TextConfigs/RoutingAndSmallContent.text';

const MainViewCart = (props) => {
    const {
        navigate, isUserLogin, _minCartAndViewCartQuantityIncDec, addToCartProducts, _changeAutoshipFunction,
        _deleteProductFromCart, _changeFrequencyOfProducts, _autoShipCheck, autoshipOrders
    } = props;

    const role = localStorageCall().getItem('customerTypeDescr')?.role || 'normal';
    const CURRENT_STORE = _.upperCase(storeAssigned()) || 'US';

    const subTotal = useMemo(() => {
        if (addToCartProducts?.normalProducts?.length > 0) {
            return ({
                productCount: _.map(addToCartProducts?.normalProducts, 'quantity').reduce((prv, next) => prv + next),
                productSubTotal: _.map(addToCartProducts?.normalProducts, (row) => ((!!(_.find(autoshipOrders, { isActive: true })) || _autoShipCheck()) ? row['normal_autoship'] : row['normal_' + row?.frequency]) * +row?.quantity).reduce((prv, next) => prv + next)
            })
        }
    }, [addToCartProducts?.normalProducts]);

    return (
        <div className='view-cart'>
            <div className='container mx-auto px-4'>
                <div className="row">
                    <div className='col-md-12'>
                        <div className="view-cart-part">
                            <h2 className='xl:mt-8 mt-5 xl:mb-1 mb-0 tracking-tight text-2xl sm:text-base md:text-lg lg:text-2xl xl:text-3xl f-q-extrabold'>Your Cart</h2>
                            {(addToCartProducts?.normalProducts?.length > 0)
                                ?
                                <div className='table-data'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>PRODUCT</th><th>PRICE</th><th>QUANTITY</th><th>SUBTOTAL</th><th>ACTIONS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                addToCartProducts?.normalProducts?.map((items) => {
                                                    return (
                                                        <tr key={items?.id}>
                                                            <td>
                                                                <div className='product-image'>
                                                                    <img src={items?.image} height="85" />
                                                                    <span className='text-black xl:text-[17px] lg:text-[14px] md:text-[11px] sm:text-[9px] text-lg f-q-extrabold xl:mb-1.5 lg:mb-1 sm:mb-0 mb-2'>{items?.title}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className='price'>
                                                                    {(items?.isEligibleForAutoOrder)
                                                                        ?
                                                                        <div className='most-prices'>
                                                                            <div className='one-time'>
                                                                                {_.map([
                                                                                    ..._.includes(items?.title, 'Mission Promo Pack') ? [
                                                                                        { title: 'Autoship', section: "autoship", price: items[`${role}_autoship`], classes: "py-3 px-3 common_css" },
                                                                                    ] : [
                                                                                        { title: "One time", section: "oneTime", price: (!!(_.find(autoshipOrders, { isActive: true })) || _autoShipCheck()) ? items[`${role}_autoship`] : items[`${role}_oneTime`], classes: "py-3 px-3 common_css" },
                                                                                        { title: 'Autoship', section: "autoship", price: items[`${role}_autoship`], classes: "py-3 px-3 common_css" },
                                                                                    ]], ({ title, section, price, classes }, index) => (

                                                                                        <div key={section}
                                                                                            className={classNames('px-[15px] py-[15px]', classes, { 'selected': (_.find(addToCartProducts?.normalProducts, { id: items?.id })?.frequency === section) })}
                                                                                            onClick={() => _changeFrequencyOfProducts(items?.id, section)}>
                                                                                            <p className='text-black xl:text-[17px] lg:text-[14px] md:text-[11px] sm:text-[9px] text-lg f-q-extrabold m-0'>{title}</p>
                                                                                            <strong className='text-black xl:text-[17px] lg:text-[14px] md:text-[11px] sm:text-[9px] text-lg f-q-extrabold m-0'> {getCurrencySign(CURRENT_STORE)}{FloatValues(price)}</strong>
                                                                                        </div>
                                                                                    ))}
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className='sub-total' style={{ textAlign: "center" }}>
                                                                            {getCurrencySign(CURRENT_STORE)}{FloatValues(items[`${role}_${items?.frequency}`])}
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <QuantityComponent {...{
                                                                    row: items, _minCartQuantityIncDec: _minCartAndViewCartQuantityIncDec
                                                                }} />
                                                            </td>
                                                            <td>
                                                                <div className='sub-total text-left'>
                                                                    {getCurrencySign(CURRENT_STORE)}{
                                                                        FloatValues(
                                                                            ((!!(_.find(autoshipOrders, { isActive: true })) || _autoShipCheck()) ? items[role + '_autoship'] : items[role + '_' + items?.frequency]) || items?.price
                                                                        )
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className='delete-product' style={{ cursor: "pointer" }}>
                                                                    <span className='delete-icon' onClick={() => _deleteProductFromCart(+items?.id)}> <Delete /></span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <div className='price-calulate'>
                                        <div className='subtotal-item text-black xl:text-[17px] lg:text-[14px] md:text-[11px] sm:text-[9px] text-lg f-q-extrabold xl:mb-0 lg:mb-1 sm:mb-0 mb-2'>Subtotal:  {getCurrencySign(CURRENT_STORE)}{FloatValues(subTotal?.productSubTotal)}</div>
                                        <div className='subtotal-item text-black xl:text-[17px] lg:text-[14px] md:text-[11px] sm:text-[9px] text-lg f-q-extrabold xl:mb-0 lg:mb-1 sm:mb-0 mb-2'>Tax:  {getCurrencySign(CURRENT_STORE)}0.00</div>
                                        <div className='subtotal-item text-black xl:text-[17px] lg:text-[14px] md:text-[11px] sm:text-[9px] text-lg f-q-extrabold xl:mb-0 lg:mb-1 sm:mb-0 mb-2'>TOTAL:  {getCurrencySign(CURRENT_STORE)}{FloatValues(subTotal?.productSubTotal)}</div>
                                        <div className='checkout-btn'>
                                            <button className='bg-button-color hover:border-button-color-500' type='button' onClick={() => navigate(!isUserLogin ? `/${storeAssigned()}/${ROUTING_TEXT?.retailSignup_routingName}` : `/${storeAssigned()}/${ROUTING_TEXT?.checkout_routingName}`)}>Checkout</button>
                                        </div>
                                    </div>
                                </div>
                                :
                                <p>Cart is empty</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default MainViewCart;