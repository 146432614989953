import React, { useState } from "react";
import HomeSlider from "./HomeSlider";
import Products from "./Products";
import ImageSections from "./ImageSections";
import HomeTop from "./assets/home-top.jpg";
import Restful from "./assets/restful.jpg";
import Mental from "./assets/mentall.jpg";
import Optimization from "./assets/optimization2.jpg";
import Athletic from "./assets/athletic.jpg";
import Revitalize from "./assets/revitalize2.jpg";
import Deffence from "./assets/defense.jpg";
import Detoxification from "./assets/detoxification.png";
import Reduced from "./assets/reduced2.jpg";
import { HomeText } from "../../TextConfigs/HomeText";
import SliderComponent from "../../Inputs/SliderComponent.inputs";
import { storeAssigned } from "../../services/Methods/normalMethods";
import withRouter from "../../services/HOC/withRouterHOC";
import _ from "lodash";
import HTMLReactParser from "html-react-parser";
import VideoPlayer from "../../Common/Components/VideoPlayer";
import DialogComponent from "../../Inputs/DailogComponent";
import UkImage from "./assets/uk-flag-new.jpg"



const HomePage = ({ COMMON_TEXTS, ROUTING_TEXT }) => {
    const homePageVideoLink = 'https://player.vimeo.com/video/730201850?autoplay=1&loop=1&autopause=0&muted=1&controls=true'
    const BUTTON_ARRAY = [
        { name: COMMON_TEXTS?.explore_health, link: `/${storeAssigned()}/${ROUTING_TEXT?.explore_health_routingName}` },
        { name: COMMON_TEXTS?.explore_business, link: `/${storeAssigned()}/${ROUTING_TEXT?.explore_business_routingName}` },
        { name: COMMON_TEXTS?.create_account, link: `/${storeAssigned()}/${ROUTING_TEXT?.signup_routingName}` },
    ];
    

    const sliderItems = [
        {
            imgSrc: HomeTop, title: `<span className="block sm:text-[40px] text-[31px] leading-[1] normal-case font-extrabold"><span className='font-light'>Experience better solutions for </span>physical and financial <span className='font-light'>
        well being.
      </span></span>`, color: "#fff", sliderClasses: "hometop"
        },

        { imgSrc: Restful, title: `<span className='font-light'> Restful  </span> Sleep `, color: "#fff", sliderClasses: "restfull" },

        {
            imgSrc: Mental, title: `   <span>
        <span className='font-light' style='color: #383737  '>MENTAL PERFORMANCE</span><span className='font-light' style='color: #fff; display:block;'> & CLARITY</span>
    </span>`, color: "#000", sliderClasses: "mental"
        },

        { imgSrc: Optimization, title: `<span className='font-light'> OPTIMIZATION OF  </span> ENERGY `, color: "#fff", sliderClasses: "optimization" },
        { imgSrc: Athletic, title: `<span className='font-light'> ATHLETIC PERFORMANCE  </span> <span style='display: block;'> & RECOVERY</span>`, color: "#fff", sliderClasses: "athletic" },
        { imgSrc: Reduced, title: `<span className='font-light'> REDUCED EFFECTS  </span> <span style='display: block;'> OF STRESS</span>`, color: "#fff", sliderClasses: " reduced" },
        { imgSrc: Revitalize, title: `<span className='font-light'>  REVITALIZE YOUR SKIN &  </span> YOUTHFUL APPEARANCE `, color: "#383737", sliderClasses: "revitalize" },
        { imgSrc: Deffence, title: `<span className='font-light'> DEFENSE   </span> SYSTEMS `, color: "#fff", sliderClasses: "defense" },
        { imgSrc: Detoxification, title: ` DETOXIFICATION `, color: "#383737", sliderClasses: "detoxification" },
    ];  

    return (<>
        <SliderComponent classFor='home-banner-slider'>
            <div className="relative lg:h-[680px] sm:h-[580px] h-[500px] !block bg-black">
                <VideoPlayer url={homePageVideoLink} style={{ width: '100%', height: '100%', right: 0, }} />
            </div>
            {_.map(sliderItems, (item, index) => (
                <React.Fragment key={index + 1}>
                    <HomeText sliderIndex={index} sliderClasses={item.sliderClasses} imgSrc={item.imgSrc} title={HTMLReactParser(item.title)} color={HTMLReactParser(item.color)} buttonArray={BUTTON_ARRAY} />
                </React.Fragment>
            ))}
        </SliderComponent>
        <Products />
        <ImageSections />
    </>
    )
};

export default withRouter(HomePage);