import React from 'react';
import { useOutletContext } from 'react-router-dom';
import SingleProductPageCSS from '../../../styles/SingleProductPage.style';
import LoaderComponent from '../../Inputs/LoaderComponent';
import MainSingleProduct from './components/MainSingleProduct';
import useSingleProductHook from './components/singleProduct.hook';

function SingleProductPage(props) {
    const {
        addToCartProducts, setAddToCartProduct, setOpenMiniCart, params, dispatch, AllProductAndCheckoutText, COMMON_TEXTS
    } = useOutletContext();
    const { loading, ...data } = useSingleProductHook({
        ...props, addToCartProducts, setAddToCartProduct, setOpenMiniCart, params, dispatch
    });
    return (
        <SingleProductPageCSS className='main_single_page bg-[#fafafa] pt-10'>
            {loading && <LoaderComponent />}
            <MainSingleProduct {...{
                section: 'normal', AllProductAndCheckoutText, COMMON_TEXTS, ...data
            }} />
        </SingleProductPageCSS>
    );
}
SingleProductPage.defaultProps = {
    // section: 'normal'
}
export default SingleProductPage;