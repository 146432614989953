import styled from "styled-components";


export const RegisterFormStyle = styled.div`
    /* background-color: red; */

    .form-heading {
        margin-bottom: 20px;
        font-size: 40px;
        line-height: 1.3;
        text-align: center;
    }

    .country_state_main {
        .select {

            select {
                border: 1px solid rgba(0,0,0,0.2);
                padding: 15px 15px;
                border-radius: 4px;
                width: 100%;
            }
        }
    }

`;
