import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

const PAYMENT_STYLE = styled.div`
& 
#collect_form_pay {
    min-height:${props => props?.$minheight || "0px"} !important;
    margin-top: 26px;
    /* @media only screen and (min-width:1440px) and (max-width: 1660px){
        min-height: 540px !important;
    } */
    /* @media only screen and (min-width:1200px) and (max-width: 1439px){
        min-height: 770px !important;
    } */
    /* @media only screen and (min-width:595px) and (max-width: 787px) {
        min-height: 540px;    
    } */

    /* @media only screen and (min-width:360px) and (max-width: 595px) {
        min-height: 770px;    
    } */

    @media only screen and (max-width: 1199px) {
        min-height: 620px;
    }
    @media only screen and (max-width: 797px) {        
        min-height: 540px;
    }
    @media only screen and (max-width: 605px) {        
        min-height: 770px;
    }
}
`;

const PaymentSection = (props) => {
    const { paymentFormHtml, savedThisCard, selectedPayment, setSavedThisCard, minHeight, iframeLoader } = props;

    return (
        <PAYMENT_STYLE $paymentformhtml={paymentFormHtml} $minheight={minHeight} >
            {(!iframeLoader)
                ?
                <iframe id="collect_form_pay" src={paymentFormHtml} style={{ width: '100%', height: '100%' }}></iframe>
                :
                <div className='order-data loader_order text-center px-0 pt-[30px] pb-[10px]'>
                    <CircularProgress />
                </div>
            }
            {(selectedPayment?.selectedPaymentDropdown === 1) &&
                <div className="mb-4 mt-0 condition-checkout flex gap-[20px]">
                    <input type="checkbox"
                        className='w-[24px] h-[33px]'
                        checked={savedThisCard?.checked}
                        onChange={(e) => {
                            setSavedThisCard({ ...savedThisCard, checked: e.target.checked });
                        }}
                        disabled={savedThisCard?.readOnly}
                        id="save_this_card"
                        name="savedThisCard"

                    /><p className='m-0 text-[21px]'>Save My Card</p>
                </div>
            }
        </PAYMENT_STYLE>
    );
}

export default PaymentSection;