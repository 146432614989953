import { Form, Formik } from 'formik';
import React from 'react';
import { useOutletContext } from 'react-router-dom';
import withRouter from '../../services/HOC/withRouterHOC';
import withFormHOC from '../../services/HOC/withFormHOC';
import useCustomerSignupHook from './components/CustomerSignup.hooks';
import { signUpValidationSchema } from '../../services/Methods/validationSchema';
import RegisterForms from '../../Common/Components/AllFormsCommons/RegisterForms';

const CustomerSignUpPage = (props) => {
    const { countryCodes } = useOutletContext();
    const {
        FormId,
        Title,
        formData,
        setFormData,
        countryState,
        setCountryState,
        webAliesError,
        setWebAliesErros,
        _handleSubmit
    } = useCustomerSignupHook(props);
    return (
        <div className="stepper-wrap lg:py-[70px] py-[50px]">
            <div className="container mx-auto max-w-[1260px] px-[15px]">
                <Formik
                    id={FormId}
                    initialValues={formData}
                    validationSchema={() => signUpValidationSchema(1)}
                    onSubmit={(values, actions) => _handleSubmit(values, actions)}
                >
                    {(fieldValue) => {
                        return (
                            <Form>
                                <RegisterForms {...{
                                    Title, fieldValue, countryState, setCountryState, countryCodes, webAliesError, setWebAliesErros
                                }} />
                            </Form>)
                    }}
                </Formik>
            </div>
        </div>
    )
}

export default withRouter(withFormHOC(CustomerSignUpPage));