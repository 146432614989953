import _ from 'lodash';
import React from 'react';
import withRouter from '../../services/HOC/withRouterHOC';
import MainExploreBusiness from './components/MainExploreBusiness';

const ExploreBusinessPage = (props) => {
  const { EXPLORE_BUSINESS_TEXTS, COMMON_TEXTS } = props;
  const EXPLORE_TEXTS = _.assign(EXPLORE_BUSINESS_TEXTS, COMMON_TEXTS);
  return (
    <div>
      <MainExploreBusiness {...{ EXPLORE_TEXTS }} />
    </div>
  )
};

export default withRouter(ExploreBusinessPage);