import React from 'react'
import About1 from '../assets/about1.jpg';
import { storeAssigned } from '../../../services/Methods/normalMethods';
import ROUTING_TEXT from '../../../TextConfigs/RoutingAndSmallContent.text';
import { useNavigate } from 'react-router-dom';


const AboutFirstSection = (props) => {
  const { ABOUT_TEXTS } = props;
  const navigate = useNavigate();
  return (
    <>

      <div className="flex flex-wrap">
        <div className="md:w-1/2 w-full">
          <figure className='h-full w-full'>
            <img src={About1} alt="Image" className='w-full h-full object-cover' />
          </figure>
        </div>
        <div className="md:w-1/2 w-full py-9 md:px-9 px-[15px] flex flex-wrap items-center">
          <div className="lg:py-[36px] max-w-[560px] mx-auto">
            <h2 className="text-[28px] leading-[1.2] font-bold text-heading-color mb-[12px] pb-4">
              {ABOUT_TEXTS?.provision_mission}
            </h2>
            <p className="mb-4 text-base text-content-color pb-4">
              {ABOUT_TEXTS?.provision_globel_para}
            </p>
            <button onClick={() => navigate(`/${storeAssigned()}/${ROUTING_TEXT?.explore_health_routingName}`)} className="bg-button-color text-white text-base  rounded-[2px] hover:bg-button-hover-color py-[13px] px-[48px] uppercase transition-all duration-300 ease-in-out shadow-[0_8px_16px_-8px] shadow-[rgba(0,113,186,0.9)] border-[1px] border-button-color hover:border-button-hover-color hover:shadow-none">
              {ABOUT_TEXTS?.explore_health}
            </button>
          </div>
        </div>
      </div>




    </>
  )
}

export default AboutFirstSection