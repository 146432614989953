import _ from "lodash";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { _checkoutAddressFormat } from "../Methods/checkoutPayloads";
import localStorageCall from "../Methods/localstorage.hook";
import { storeAssigned, _setTimeOut } from "../Methods/normalMethods";
import { InitialValues, _formDefaultFormat } from "../Methods/validationSchema";
import { getCustomerRefferal } from "../Redux/Reducer/CustomerSlice";
import { getAllCustomerData } from "../Redux/Reducer/LoginSlice";

function withFormHOC(Component) {

    const ComponentWithFormProp = (props) => {
        const { dispatch, activeStep, setActiveStep, MESSAGE_TEXT, ROUTING_TEXT, isUserLogin } = props;
        const { userData } = useOutletContext();
        let Dates = _.pick(userData, ['distributorDate', 'signUpDate', 'entryDate']);

        const [formData, setFormData] = useState(InitialValues);
        const [otp, setOtp] = useState(new Array(6).fill(""));
        const [countryState, setCountryState] = useState({
            country: "",
            state: "",
            countryError: "",
            stateError: "",
        });
        const [webAliesError, setWebAliesErros] = useState({
            changeRefferal: false,
            isRefferalError: "",
            isRefferalValid: false,
            isRefferalId: null,
        });
        useEffect(() => {
            const REFFERAL = localStorageCall().getItem('refferal_link');
            if (!REFFERAL?.id) {
                window.location.assign(`/${storeAssigned()}/${ROUTING_TEXT?.signup_routingName}`)
            }
        }, [])

        const [emailExistError, setEmailExistError] = useState();

        function _handleSetFormValues(values, callback) {
            const LOGIN_USER_DATA = _.pick(userData, ['mailingAddress', 'entryDate', 'signUpDate', 'distributorDate', 'billingAddress'])

            if (countryState?.country && countryState?.state) {
                const CHANGED_DATA = {
                    ...values, country: countryState?.country, state: countryState?.state,
                    ...LOGIN_USER_DATA,
                }
                setFormData(CHANGED_DATA);
                callback(CHANGED_DATA);
            } else {
                if (countryState?.country === "") {
                    setCountryState((prv) => ({ ...prv, countryError: "Please select your country" }))
                }
                if (countryState?.state === "") {
                    setCountryState((prv) => ({ ...prv, stateError: "Please select your state" }))
                }
            }
        }

        function _handleInputChange(event) {
            const { name, value } = event.target;
            setFormData({ ...formData, [name]: value, });
        };

        function inCreaseStepper(data, callback) {
            if (data === "VALID") {
                setActiveStep((prv) => prv + 2);
            } else {
                setActiveStep((prv) => prv + 1);
            }
        };

        function _emailValidate(email, callback) {
            dispatch(getAllCustomerData((userDatas) => {
                if (_.find(userDatas, ({ userName }) => _.lowerCase(email) === _.lowerCase(userName))) {
                    setEmailExistError(MESSAGE_TEXT?.email_exist);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    setTimeout(() => setEmailExistError(''), 10000)
                } else {
                    setEmailExistError('');
                    callback();
                }
            }, null, email));
        }

        function _validateRefferalFunction(refferal, callback) {
            dispatch(getCustomerRefferal((data) => {
                if (data?.length === 0) {
                    setWebAliesErros((prv) => ({ ...prv, isRefferalError: "Referral code is not found", isRefferalValid: false, isRefferalId: null }));
                    _setTimeOut(() => setWebAliesErros((prv) => ({ ...prv, isRefferalError: "" })), 1500);
                    callback(false);
                    return;
                } else {
                    setWebAliesErros({ changeRefferal: false, isRefferalError: '', isRefferalValid: true, isRefferalId: data[0]?.customerId });
                    const refferalData = JSON.stringify({ "id": data[0]?.customerId, "name": data[0]?.fullName, "webalies": data[0]?.webAlias });
                    localStorageCall().setItem('refferal_link', refferalData);
                    callback(true);
                }
            }, refferal));
        };

        function _webAliesCheckDuringSubmit(currentWebAlias, callback) {
            if (currentWebAlias !== formData?.webAlias) {
                dispatch(getCustomerRefferal((data) => {
                    if (data?.length > 0) {
                        setWebAliesErros((prv) => ({ ...prv, isWebAliesError: "This webAlies is already used." }));
                        _setTimeOut(() => setWebAliesErros((prv) => ({ ...prv, isWebAliesError: "" })), 10000);
                        return;
                    } else {
                        callback();
                    }
                }, currentWebAlias));
            } else {
                callback();
            }
        }

        function _handleOtpValidate(callback) {
            if (otp?.join('') === '121212') {
                setActiveStep(activeStep + 1);
                callback(false)
            } else {
                callback(true);
            }
        }

        const handleChangeValidate = (element, index) => {
            if (isNaN(element.value)) return false;
            setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
            if (element.nextSibling) {
                element.nextSibling.focus();
            }
        };

        function _updateDataOfUser() {
            if (userData?.emailAddress) {
                const DEFAULT_DETAIL = _formDefaultFormat(userData, formData, countryState);
                setFormData(DEFAULT_DETAIL);
                setCountryState((prv) => ({
                    country: prv?.shipping_country || userData?.countryCode,
                    state: prv?.shipping_state || userData?.shippingAddress?.regionProvState
                }));
            }
        }

        useEffect(() => {
            _updateDataOfUser();
        }, [userData?.emailAddress]);

        return <Component {...{
            formData, setFormData, countryState, setCountryState, webAliesError, setWebAliesErros, otp, emailExistError,
            _handleSetFormValues, inCreaseStepper, _webAliesCheckDuringSubmit, _validateRefferalFunction, _emailValidate,
            _handleOtpValidate, handleChangeValidate, _handleInputChange, Dates
        }} {...props} />
    }

    return ComponentWithFormProp;

}

export default withFormHOC;