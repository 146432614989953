import React from 'react';
import withRouter from '../../services/HOC/withRouterHOC';
import MainCreateAccountComponent from './components/MainCreateAccountComponent';
import SignUpHeader from '../../Common/Components/SignupHeader';
import UseCreateAccountHook from './components/CreateAccount.hooks';
import DialogComponent from '../../Inputs/DailogComponent';
import { CUSTOMER_TYPE, storeAssigned } from '../../services/Methods/normalMethods';
import ROUTING_TEXT from '../../TextConfigs/RoutingAndSmallContent.text';
import _ from 'lodash';
import { useOutletContext } from 'react-router-dom';
import localStorageCall from '../../services/Methods/localstorage.hook';

const CreateSignUpPage = (props) => {
    const {
        openPopup, setOpenPopup, userField, setUserField, webAliesError, setWebAliesErros, handleChange, _validateRefferalFunction, isLoadingTrue,
        navigateLink, _navigateFunction, _checkingCustomerType, isCustomerLoggedIn, setIsCustomerLoggedIn
    } = UseCreateAccountHook(props);

    return (
        <div>
            <SignUpHeader {...props} />
            <MainCreateAccountComponent {...{
                openPopup, setOpenPopup, userField, setUserField, webAliesError, isLoadingTrue, navigateLink,
                setWebAliesErros, handleChange, _validateRefferalFunction, _checkingCustomerType, _navigateFunction, ...props
            }} />
            <DialogComponent opend={isCustomerLoggedIn} handleClose={() => setIsCustomerLoggedIn(false)} title="Info" classFor="isDistributer">
                <div style={{ padding: "0px 20px 0px" }}>
                    <p> You are already a {CUSTOMER_TYPE?.customerType}</p>
                    <p><a className='text-button-color hover:text-button-hover-color transition-all ease-in-out duration-300' href={`/${storeAssigned()}/${ROUTING_TEXT?.Shop_routingName}`}>Click here to shop</a></p>
                </div>
            </DialogComponent>
        </div>
    )
}
export default withRouter(CreateSignUpPage);