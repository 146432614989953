import { useNavigate } from "react-router-dom";

const OutlinedButton = ({
  children,
  className = "",
  link,
  onClick,
  ...rest
}) => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => {
        if (link) {
          navigate(link);
        }
        if (onClick) {
          onClick();
        }
      }}
      className={`relative f-q-heavy text-xs lg:text-[11px] xl:text-[15px] w-[140px] h-[40px] sm:w-[85px] md:w-[100px] md:h-[31px]  lg:w-[135px] lg:h-[38px] xl:w-[170px] xl:h-[50px] z-10 inline-block bg-transparent rounded-full border-2 border-primary hover:text-primary transition-colors duration-200 ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
};

export default OutlinedButton;
