import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { FloatValues, getCurrencySign, storeAssigned } from "../services/Methods/normalMethods";

const HomeText = ({ imgSrc, title, sliderClasses, color, buttonArray }) => {

  return (
    <div className="relative lg:h-[680px] sm:h-[580px] h-[500px]">
      <figure className={`absolute top-0 left-0 w-full h-full z-10 after:content-[''] after:h-full after:w-full after:bg-[rgba(0,0,0,0.4)] after:absolute after:top-0 after:left-0 ${sliderClasses}`} style={{ backgroundImage: `url(${imgSrc})` }}></figure>
      <div className="container max-w-[1260px] mx-auto px-[15px] relative z-20">
        <div className="text-center pb-30px">
          <div className={`max-w-[814px] mx-auto lg:pt-[183px] sm:pt-[50px] pt-[50px] lg:mb-[75px] sm:mb-[40px] mb-[30px] ${sliderClasses === 'mental' && 'mr-0'}`}>
            <h1 className={`lg:text-[64px] sm:text-[55px] text-[31px] lg:leading-[81px] sm:leading-[1.5] leading-[1.3] sm:min-h-[187px] min-h-[150px] font-semibold uppercase ${sliderClasses === 'mental' && 'tracking-[2px]'}`} style={{ color }}>
              <span className="font-light">  </span>
              {title}
            </h1>
          </div>
          <div className="max-w-[750px] mx-auto flex flex-wrap lg:justify-between justify-center lg:items-start items-center lg:flex-row flex-col lg:gap-0 gap-[15px]">
            {_.map(buttonArray, (row, index) => (
              <Link to={row?.link} reloadDocument key={row?.name}
                className="md:w-auto w-[230px] font-semibold text-white  lg:px-[40px] px-[30px] uppercase py-[15px] text-[14px] leading-[1.5] border-[1px] border-white"
              >
                {row?.name}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
};

const HomeTextProducts = ({ imgSrc, altText, productName, prices, CURRENT_STORE }) => (

  <>
    <div className="mb-[42px]">
      <figure className='lg:h-[260px] h-[200px] w-full lg:mb-[40px] mb-4'>
        <img src={imgSrc} alt={altText} className="w-full h-full object-contain" />
      </figure>
      <div>
        <h3 className="text-center font-semibold text-heading-color xl:text-[28px] md:text-[22px] leading-[1.3] lg:mb-4 mb-2">{productName}</h3>
        <p className="text-center text-heading-color xl:text-xl md:text-base leading-[1.2]"> {prices?.normalOneTime !== 0 && getCurrencySign(CURRENT_STORE)}{prices?.normalOneTime !== 0 && FloatValues(prices?.normalOneTime)} {prices?.normalOneTime !== 0 && prices?.isEligibleForAutoOrder && ' | '}{prices?.isEligibleForAutoOrder && `${getCurrencySign(CURRENT_STORE)}${FloatValues(prices?.normalAutoship)} with SmartShip`}</p>
      </div>
    </div>
  </>
);

export { HomeText, HomeTextProducts };
