import _ from 'lodash';
import React from 'react';
import withRouter from '../../../services/HOC/withRouterHOC';

const TermsAndConditions = ({ FOOTER_TEXTS }) => {

  const TERMS_CONDITION_KEYS = [
    'security_disclosure', 'shipping_policy', 'shipping_rates', 'third_party', 'order_disclaimer', 'ftc',
    'warantty', 'limitation', 'notice', 'modification', 'general', 'general_content', 'definition', 'aditional_info'
  ];

  return (
    <section>
      <div className="container mx-auto max-w-[1260px] px-[15px]">
        <div className="flex flex-wrap -mx-[15px]">
          <div className="w-full md:w-2/3 px-[15px] mx-auto">
            <div className="mt-[60px] mb-[100px]">
              <h1 className="text-4xl font-extrabold text-center text-[#373f50]">{FOOTER_TEXTS?.terms_and_conditions}</h1>
            </div>
            <div className='mb-[100px]'>
              <p className='text-base mb-4 text-dark-content'>{FOOTER_TEXTS?.terms_and_conditions_content}</p>
            </div>
            {
              _.map(TERMS_CONDITION_KEYS, (row, keys) => (
                <div className='mb-[100px]' key={keys + '_terms'}>
                  <h2 className='text-[32px] text-heading-color leading-[1.2] mb-5 font-extrabold'> {FOOTER_TEXTS?.[row]}</h2>
                  {_.isArray(FOOTER_TEXTS?.[`terms_${row}_content`])
                    ?
                    _.map(FOOTER_TEXTS?.[`terms_${row}_content`], (paragraph, index) => (
                      <p key={index} className='text-base mb-4 text-dark-content'>{paragraph}</p>
                    ))
                    :
                    <p className='text-base mb-4 text-dark-content'>{FOOTER_TEXTS?.[`terms_${row}_content`]}</p>
                  }
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </section>
  );
};
export default withRouter(TermsAndConditions);
