import styled from "styled-components";

export const MainCheckOutStyle = styled.div`
  
  .checkout-main {
    position: relative;
    .container {
      margin: 0 auto;
    }

    .formAndOrderSummery {
      display: flex;
      gap: 30px;

      @media only screen and (max-width: 1199px) {
        flex-wrap: wrap;
      }
      .shipping-form {
        max-width: calc(100% - 590px);
        width: 100%;

        @media only screen and (max-width: 1280px) {
          max-width: calc(100% - 490px);
        }

        @media only screen and (max-width: 1199px) {
          max-width: 100%;
        }

        .shipping_fields {
          display: flex; 
          flex-wrap: wrap;
          margin: 0 -10px;

          .checkout_heading_address {
            padding: 0 10px;
          }

           .field-class {
            padding: 0 10px;
            width: 100%;
          }
        }

        h2 {
          margin-bottom: 60px;
          font-size: 50px;
          font-weight: 700;
          color:#0071BA;
        }

        h3 {
          border-bottom: 1px solid rgb(196, 196, 196);
          width: 100%;
          font-weight: 600;
          padding-bottom: 25px;
          margin-bottom: 30px;
          font-size: 30px;
          color: rgb(57, 51, 46);
        }

        .shipping_fields {
          .country_state_main {
            .select {
              width: 100%;
              /* flex: 0 0 50%; */
              select {
                width: 100%;
                padding: 16.5px 14px;
                border: 1px solid rgba(0,0,0,0.3);
                border-radius: 4px;
              }
            }
          }
        }
      }

      .mainorder_summery {
        width: 100%;
        max-width: 560px;
        position: absolute;
        right: 0;
        height: 100%;
        top: 0px;

        @media only screen and (max-width: 1199px) {
          position: static;
          max-width: 100%;
          height: auto;
          margin-top: 30px;
        }

        .sticky-wrap {
          width: 100%;
          max-width: 580px;
          font-size: 30px;
          position: sticky;
          top: 10px;

          @media only screen and (max-width: 1199px) {
            position: static;
            max-width: 100%;
          }

          .order-summary {

            box-shadow:  0 0 4px 1px #ddd;
            background: #fff;
            h2 {
              text-align: center;
              background: #0071BA;
              color: #fff;
              font-size: 30px;
              padding: 40px;
              margin: 0;
            }

            .order-data {
              width: 100%;

              table {
                width: 100%;

                tr {
                  padding: 40px 30px;
                  display: flex;
                  width: 100%;
                  min-width: 100%;
                  justify-content: space-between;
                  border-bottom: 1px solid rgba(85, 85, 85, 0.4);

                  @media only screen and (max-width: 1199px) {
                    padding: 20px;
                  }

                  td {
                    font-size: 20px;

                    /* &.products-details-order {
                      line-height: 50px;
                    } */
                  }
                }
              }

              .price-calulate {
                padding: 30px 40px;

                @media only screen and (max-width: 1199px) {
                  padding: 20px;
                }

                .subtotal-item {
                  margin-bottom: 10px;
                  font-size: 20px;

                }
              }
            }
          }
        }
      }
    }
  }
  
 
`;
