import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import { useEffect } from "react";
import { NewPasswordApi, ResetEmailApi } from "../../../services/Redux/Reducer/CustomerSlice";


const ResetPasswordhook = (props) => {

    const dispatch = useDispatch();
    const { searchParams } = props;

    // ******************************************** RESET EMAIL  FUNCTIONALITY ************************************************************************* /
    const emailState = useSelector((state) => state?.CustomerSlice);

    function _onHandleEmailSubmit(values) {
        dispatch(ResetEmailApi(values));
    }

    // useEffect(() => {
    //     if (emailState?.data) {
    //         window?.location.reload();
    //         window.scroll(0, 0);
    //     }
    // }, [emailState?.data]);

    // ******************************************** NEW  PASSWORD FUNCTIONALITY ************************************************************************* /

    const passwordState = useSelector((state) => state?.CustomerSlice);

    const newpasswordvalidation = Yup.object({

        password: Yup.string().min(6).required("Please enter your password"),
        confirm_password: Yup.string().required('Confirm Password is required.').test('confirm_password', 'password and confirm password should be same', function (val) {
            const password = this.resolve(Yup.ref('password'));
            if (val !== password) return false;
            return true;
        }),
        key: Yup.string().required('This field is required')
    });

    function _onHandlePasswordSubmit(values) {
        dispatch(NewPasswordApi({ ...values, sessionId: searchParams.get('sessionId'), passwordKey: values?.key }));
    }
    // useEffect(() => {
    //     if (passwordState?.data) {
    //         window?.location.reload();
    //         window.scroll(0, 0);
    //     }
    // }, [passwordState?.data]);

    return {
        newpasswordvalidation,
        _onHandleEmailSubmit,
        emailState,
        _onHandlePasswordSubmit,

    }
}

export default ResetPasswordhook;