import _ from 'lodash';
import React from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import ShoppingCartComponent from '../Common/Components/AllFormsCommons/ShoppingCartComponent';
import { storeAssigned } from '../services/Methods/normalMethods';

const StorePage = (props) => {
    const data = useOutletContext();
    const { ROUTING_TEXT, location } = data;
    return (
        <div className="us_store relative z-10">
            <Outlet context={{ ...data, ...props }} />
            {!_.includes([
                `/${storeAssigned()}/${ROUTING_TEXT?.retailSignup_routingName}`,
                `/${storeAssigned()}/${ROUTING_TEXT?.preferredSignup_routingName}`,
                `/${storeAssigned()}/${ROUTING_TEXT?.IBOSignup_routingName}`
                // `/${storeAssigned()}/${ROUTING_TEXT?.ktm_routingName}`
            ], location?.pathname) &&
                <ShoppingCartComponent
                    section='normal'
                    _minCartQuantityIncDec={data?._minCartAndViewCartQuantityIncDec}
                    openMiniCart={data?.openMiniCart}
                    setOpenMiniCart={data?.setOpenMiniCart}
                    _deleteProductFromCart={data?._deleteProductFromCart}
                    productData={data?.addToCartProducts?.normalProducts}
                    _autoShipCheck={data?._autoShipCheck} />}

        </div>
    )
}

export default StorePage;