import React from 'react';
import { Delete } from '@mui/icons-material';
import _ from 'lodash';
import ButtonComponent from '../../../Inputs/ButtonComponent';
import { FloatValues, getCurrencySign, storeAssigned } from '../../../services/Methods/normalMethods';

const OrderSummery = ({ cartData, _increseCheckoutQuantity, _deleteProductFunction, calculate, section }) => {
    const CURRENT_STORE = _.upperCase(storeAssigned()) || 'US';

    return (
        <div className='order-summary mb-[30px]' style={{ padding: "0" }}>
            <h2>{section} Order Summary</h2>
            <div className='order-data'>
                <table>
                    <tbody>
                        {cartData?.map((items) => {
                            return (
                                <React.Fragment key={items?.id}>
                                    <tr className='all-products' >
                                        <td className='products-details-order'>
                                            <h4> {items?.productName} </h4>
                                            {_increseCheckoutQuantity
                                                ?
                                                <ButtonComponent classes="bg-inherit  w-full max-w-[110px]">
                                                    <div className="flex items-center text-button-color justify-around lg:text-sm xl:text-lg !leading-3">
                                                        <span
                                                            className="hover:text-primary cursor-pointer py-2 px-2 hover:!text-[#f586a3]"
                                                            onClick={() => console.log(id, "sub")}
                                                        >
                                                            -
                                                        </span>
                                                        <span>{items?.quantity}</span>
                                                        <span
                                                            className="hover:text-primary cursor-pointer py-2 px-2 hover:!text-[#f586a3]"
                                                            onClick={() => console.log(id, "add")}
                                                        >
                                                            +
                                                        </span>
                                                    </div>
                                                </ButtonComponent>
                                                :
                                                <span className="item-qty font-extralight   ">QTY:{items?.quantity}</span>
                                            }
                                            <span className='iteam-para'>{items?.para}</span>
                                        </td>
                                        <td className='item-icon'>
                                            {(!(['member']?.includes(items?.autoship)) && _deleteProductFunction) &&
                                                <span className='delete-icon' onClick={() => {
                                                    _deleteProductFunction(+items?.id, items?.autoship);
                                                }}> <Delete /></span>
                                            }
                                            <span className='price-note'>{getCurrencySign(CURRENT_STORE)}{items?.price ? FloatValues(items?.price) : ""}</span>
                                        </td>
                                    </tr>
                                    {_.map(items?.subProducts, (row) => (
                                        <tr className='all-products sub_products_values' key={row?.id + 1}>
                                            <td></td>
                                            <td className='products-details-order'>
                                                <h4> {row?.productName} </h4>
                                                {!(['member', 'now']?.includes(row?.autoship)) &&
                                                    <span className='item-qty'>QTY:{row?.quantity}</span>
                                                }
                                                <span className='iteam-para'>{row?.para}</span>
                                            </td>
                                            <td className='item-icon'>
                                                {/*  <span className='price-note'>${row?.price ? FloatValues(row?.price) : ""}</span>*/}
                                            </td>
                                        </tr>
                                    ))
                                    }
                                </React.Fragment>
                            )
                        })}

                    </tbody>
                </table>
                <div className='price-calulate'>
                    {_.map([
                        { Subtotal: calculate?.subTotal }, { Shipping: calculate?.shippingTotal }, { Taxes: calculate?.taxTotal },
                        { Total: calculate?.orderTotal }
                    ], (row) => _.map(_.entries(row), ([key, value]) => (
                        <div className='subtotal-item' key={key}>{key}: {getCurrencySign(CURRENT_STORE)}{value != null ? FloatValues(value) : value}</div>
                    )))}

                </div>
            </div >
        </div >
    )
};
export default OrderSummery