import { TextField } from "@mui/material";
import React from "react";
import styled from "styled-components";

const TextFieldCss = styled.div`
  &.textField {
    width: 100%;
    .MuiFormLabel-asterisk {
      color: #f00;
    }
    .MuiTextField-root {
      width: 100%;
    }
  }
`;

const TextFieldComponent = ({
  label,
  value,
  type,
  defaultValue,
  readOnly,
  name,
  required,
  onChange,
  errorMessage,
  error,
  ...props
}) => {
  return (
    <TextFieldCss className="textField">
      <TextField
        type={type}
        error={error}
        readOnly={readOnly}
        required={required}
        name={name}
        // id="outlined-required"
        label={label}
        value={value}
        onChange={onChange}
        helperText={errorMessage}
        {...props}
      />
    </TextFieldCss>
  );
};
TextFieldComponent.defaultProps = {
  required: false,
  readOnly: false,
  errorMessage: "",
  error: false,
  type: "text",
};
export default TextFieldComponent;
