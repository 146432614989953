import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types';
import ButtonComponent from '../../../Inputs/ButtonComponent';

const CouponComponentStyle = styled.div`
    margin: 22px 0 30px;
    width: 100%;
    max-width: 70%;
    .inner_point_container {
        padding: 20px 20px;
        width: 100%;
        box-shadow: 0 0 5px 0px #ddd;

        h3 {
            padding-bottom: 10px;
            margin-bottom: 10px;
        }
        .fillCount {
            display: grid;
            gap: 6px;

            input[type='text'] {
                border:1px solid rgb(196, 196, 196) !important;
                padding: 10px 10px 5px;
                font-size: 18px;
                width: 100%;
                max-width: 260px;
                min-height: 45px;
            }
        }
    }
    @media (max-width: 660px){
        max-width: 100%;
    }

`;

const CouponComponent = ({ couponCode, setCouponCode, _onCouponClick, couponErrorData }) => {

    return (
        <CouponComponentStyle className='main_pointSection'>
            <div className='inner_point_container'>
                <h3>Coupon Code</h3>

                <div className='fillCount'>
                    <label htmlFor='coupan' style={{ marginBottom: '10px' }}>Please enter your coupon code</label>
                    {couponCode?.valid
                        ?
                        <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                            <p style={{ marginBottom: 0, fontSize: '30px' }}>{couponCode?.code}</p>
                            <ButtonComponent onClick={() => _onCouponClick('remove')}>Remove</ButtonComponent>
                        </div>
                        :
                        <div style={{ display: 'flex', gap: '20px' }}>
                            <input
                                type="text"
                                value={couponCode?.code}
                                id="coupan"
                                onChange={(e) => setCouponCode({ ...couponCode, code: e.target.value })}
                            />
                            <ButtonComponent onClick={() => _onCouponClick('add')}>Apply</ButtonComponent>
                        </div>
                    }
                </div>
                {couponErrorData && <p style={{ color: "red" }}>{couponErrorData}</p>}
            </div>
        </CouponComponentStyle>
    )
}
CouponComponent.propTypes = {
    couponCode: PropTypes.shape({
        valid: PropTypes.bool,
        code: PropTypes.string,
    }),
    setCouponCode: PropTypes.func,
    _onCouponClick: PropTypes.func,
    couponErrorData: PropTypes.string
}
export default CouponComponent;