import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css';
import ROUTERS from './Urls';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-tabs/style/react-tabs.css'
import { Provider } from 'react-redux';
import store from "./services/Redux/store.js";

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <ROUTERS />
  </Provider>,
)
