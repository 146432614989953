import { useEffect, useState } from "react";

function withStepperHOC(Component) {

    function ComponentWithStepperProp(props) {

        const [activeStep, setActiveStep] = useState(0);

        useEffect(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, [activeStep]);


        function handleNext() {
            setActiveStep((prevStep) => prevStep + 1);
        };

        function handleBack() {
            setActiveStep((prevStep) => prevStep - 1);
        };

        return (
            <Component {...props} {...{ activeStep, setActiveStep, handleBack, handleNext }} />
        );
    }
    return ComponentWithStepperProp;
}

export default withStepperHOC;