import React from 'react';
import { useFormik } from 'formik';
import TextFieldComponent from '../../../Inputs/TextFieldComponent';
import ButtonComponent from '../../../Inputs/ButtonComponent';

const NewPassWord = (props) => {

    const { newpasswordvalidation, _onHandlePasswordSubmit, emailState } = props;

    const { values, errors, handleChange, handleSubmit } = useFormik({
        initialValues: { password: "", confirm_password: "", key: "" },
        validationSchema: newpasswordvalidation,
        onSubmit: (values, action) => _onHandlePasswordSubmit(values),
    });

    return (
        <form className="p-9 rounded-[7px] bg-white shadow-[0px_0px_10px_0px_rgba(0,0,0,0.1)] text-left" onSubmit={handleSubmit}>
            <div className="text-[28px] leading-[1.2] font-extrabold mb-4">
                <h4 >Hello!</h4>
                <h3 >Password Recovery.</h3><br />
            </div>
            <div className='temporary-key'>
                <p><strong>DO NOT REFRESH THIS PAGE.</strong></p>
                <p>Please retrieve the temporary key sent to your email</p>
                <p><strong>(it may be in your spam folder)</strong></p>
            </div>
            <div className="mb-4">
                <label
                    className="text-[14px] leading-[1.5] font-normal mb-[6px] block w-full text-heading-color pt-[1px]"
                    htmlFor="email"
                >
                    Temporary Key
                </label>
                <TextFieldComponent
                    type="text"
                    className={`px-4 py-[10px] rounded-[5px] border-[1px] border-[#dae1e7] text-[15px] leading-[1.5] font-normal inline-block w-full ${errors?.key
                        ? 'border-red-500'
                        : ''
                        }`}
                    name="key" placeholder='Temporary Key'
                    value={values?.key}
                    onChange={handleChange}
                    error={!!errors?.key}
                    errorMessage={errors?.key}
                />

            </div>
            <div className="mb-4">
                <label
                    className="text-[14px] leading-[1.5] font-normal mb-[6px] block w-full text-heading-color pt-[1px]"
                    htmlFor="email"
                >
                    New Password
                </label>
                <TextFieldComponent
                    type="password"
                    className={`px-4 py-[10px] rounded-[5px] border-[1px] border-[#dae1e7] text-[15px] leading-[1.5] font-normal inline-block w-full ${errors?.password
                        ? 'border-red-500'
                        : ''
                        }`}
                    name="password" placeholder='New Password'
                    value={values?.password}
                    onChange={handleChange}
                    error={!!errors?.password}
                    errorMessage={errors?.password}
                />
            </div>
            <div className="mb-4">
                <label
                    className="text-[14px] leading-[1.5] font-normal mb-[6px] block w-full text-heading-color pt-[1px]"
                    htmlFor="email"
                >
                    Confirm Password
                </label>
                <TextFieldComponent
                    type="password"
                    className={`px-4 py-[10px] rounded-[5px] border-[1px] border-[#dae1e7] text-[15px] leading-[1.5] font-normal inline-block w-full ${errors?.confirm_password
                        ? 'border-red-500'
                        : ''
                        }`}
                    name="confirm_password" placeholder='Confirm Password'
                    value={values?.confirm_password}
                    onChange={handleChange}
                    error={!!errors?.confirm_password}
                    errorMessage={errors?.confirm_password}
                />

            </div>
            <ButtonComponent type="submit" >RESET PASSWORD</ButtonComponent>
            {emailState?.successmsg && <div style={{ color: "#295340", fontSize: "20px" }}>{emailState?.successmsg}</div>}
            {emailState?.emailerror && <div style={{ color: "red", fontSize: "14px", textAlign: "center" }}>{emailState?.emailerror}</div>}
            <div className='email-temp' style={{ fontSize: "13px", textAlign: "center" }}>An email with a temporary key will be sent to the email address associated with your account</div>
        </form>

    )

}
export default NewPassWord;