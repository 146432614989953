import React from 'react';
import withRouter from '../../../services/HOC/withRouterHOC';

const ShippingDisclosure = ({ FOOTER_TEXTS }) => {
  return (
    <>
      <section>
        <div className="container mx-auto max-w-[1260px] px-[15px]">
          <div className="flex flex-wrap -mx-[15px]">
            <div className="w-full md:w-2/3 px-[15px] mx-auto">
              <div className="mt-[60px] mb-[100px]">
                <h1 className="text-4xl font-extrabold text-center text-[#373f50]">
                  {FOOTER_TEXTS?.shipping_policy}
                </h1>
              </div>

              <div className='mb-[100px]'>
                {FOOTER_TEXTS?.shipping_policy_content.map((paragraph, index) => (
                  <p key={index} className='text-base mb-4 text-dark-content'>
                    {paragraph}
                  </p>
                ))}
              </div>

              <div className='mb-[100px]'>
                <h2 className='text-[32px] text-heading-color leading-[1.2] mb-5 font-extrabold'> {FOOTER_TEXTS?.shipping_time}</h2>
                {FOOTER_TEXTS?.shipping_time_content.map((paragraph, index) => (
                  <p key={index} className='text-base mb-4 text-dark-content'>
                    {paragraph}
                  </p>
                ))}
              </div>

              <div className='mb-[100px]'>
                <h2 className='text-[32px] text-heading-color leading-[1.2] mb-5 font-extrabold'> {FOOTER_TEXTS?.shipping_delivery_estimate}</h2>
                <p className='text-base mb-4 text-dark-content'>
                  {FOOTER_TEXTS?.shipping_delivery_estimate_content}
                </p>
              </div>

              <div className='mb-[100px]'>
                <h2 className='text-[32px] text-heading-color leading-[1.2] mb-5 font-extrabold'> {FOOTER_TEXTS?.damage}</h2>
                {FOOTER_TEXTS?.damage_content.map((paragraph, index) => (
                  <p key={index} className='text-base mb-4 text-dark-content'>
                    {paragraph}
                  </p>
                ))}
              </div>

              <div className='mb-[100px]'>
                <h2 className='text-[32px] text-heading-color leading-[1.2] mb-5 font-extrabold'> {FOOTER_TEXTS?.shipping_return}</h2>
                <p className='text-base mb-4 text-dark-content'>
                  {FOOTER_TEXTS?.shipping_return_content}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
};
export default withRouter(ShippingDisclosure);
