const EXPLORE_HEALTH_TEXTS = {
    how_many_product_heading: "HOW MANY PRODUCTS COULD YOU REPLACE...",
    with_provizion: "with Provizion's ENERGY & GSH!",
    why_use_gsh: "Why Use Provizion's GSH BioBlend",
    gsh_bioblend: "Provizion's GSH BioBlend is a proprietary fusion of 5 core sciences that empower your body to protect and repair itself at the cellular level.",
    may_support_natural: "†May Support the Body's Natural:",

    defense_system_text: "Defense systems",
    mental_performace_text: "Mental Performance & Clarity",
    protection_harmful_effect: "Protection from the harmful effects of stress",
    optimisation_energy_level: "Optimization of energy levels",

    detoxification_text: "Detoxification",
    rest_and_restorative: "Rest and restorative sleep systems",
    revitalization_youthful: "Revitalization of the skin and youthful appearance",
    athletic_performance: "Athletic ability and performance recovery",

    manufacture_in: "Manufactured in a",
    cgmp_complaint: "CGMP compliant facility",
    nsf_certified: "NSF certified facility",
    fda_registered: "FDA registered facility",
    kosher_and_halal_certified: "kosher and Halal certified facility.",

    GSH_BioBlend_fusion_para: "Provizion’s GSH BioBlend is a fusion of 5 core sciences that may empower your body to protect, repair and rejuvenate itself at the cellular level. Utilizing a bio purposed blend of these, along with other advanced and evidence based nutrients, technologies and ingredient sources forms the basis of our robust product development and effectiveness.",
    gsh_bioBlend_heading: 'GSH BioBlend Inside (5 Core Sciences)',

    per_serving_text: "Per Serving",
    gsh_ingredient_array: [
        { heading: "Calories", subheading: "", perServing: "5", para: "The calorie is a unit of energy widely used in nutrition." },
        { heading: "Vitamin C", subheading: "as Ascorbic Acid", perServing: "105 mg", para: "Vitamin C is an essential nutrient found mainly in fruits and vegetables. The body requires vitamin C to form and maintain bones, blood vessels, and skin. Vitamin C helps produce collagen, a protein needed to develop and maintain healthy teeth, bones, gums, cartilage, vertebrae discs, joint linings, skin and blood vessels." },
        { heading: "Vitamin E", subheading: "as d-Alpha Tocopherol Acid", perServing: "11 mg", para: "Vitamin E can stop the creation of free radical chain reactions. E is a fat-soluble vitamin and is especially effective in halting oxidation of certain fats, such as LDL cholesterol." },
        { heading: "Vitamin B6", subheading: "as Pyridoxal-5-Phosphate Monohydrate", perServing: "5 mg", para: "The vitamin B6 group is especially important to the function of the central nervous system, skin, and blood. Vitamin B6 is involved in the formation of red blood cells since pyridoxal phosphate is the rate-limiting substance in making heme, a component of hemoglobin, the key oxygen-carrying pigment in red blood cells." },
        { heading: "Folate", subheading: "from Calcium L-5-Methyltetrahydrofolate", perServing: "200 mcg", para: "Folate: Folic acid, one of the B vitamins that is a key factor in the synthesis (the making) of nucleic acid (DNA and RNA)" },
        { heading: "Vitamin B12", subheading: "as Methylcobalamin", perServing: "250 mcg", para: "Vitamin B12 is important for the normal formation of red blood cells and the health of the nerve tissues. It is particularly important in the normal functioning of the nervous system via its role in the synthesis of myelin, and in the maturation of developing red blood cells in the bone marrow." },
        { heading: "Biotin", subheading: "", perServing: "2.5 mcg", para: "Biotin is a water-soluble B-complex vitamin involved in carbon dioxide transfer and therefore essential to the metabolism of carbohydrate and fat. A balanced diet usually contains enough biotin. Foods with high biotin levels include nuts, cereals, green leafy vegetables and milk." },
        { heading: "Zinc", subheading: "as Zinc Citrate", perServing: "7.5 mg", para: "Zinc is a mineral that is essential to the body and is a constituent of many enzymes that permit chemical reactions to proceed at normal rates. Zinc is involved in the manufacture of protein (protein synthesis) and in cell division." },
        { heading: "Selenium", subheading: "as Amino Acid Chelate", perServing: "2 mcg", para: "Selenium is an essential mineral that is a component of an antioxidant enzyme, glutathione reductase, that is key in tissue respiration." },
        { heading: "GSH Support Blend", subheading: "", perServing: "639 mg", para: "L-Glutamine, N-Acetyl-D-Glucosamine, L-Glycine, Alpha Lipoic Acid, Quercetin Dihydrate, Milk Thistle Seed Extract, Cordyceps Extract." },
        { heading: "GSH BioBlend", subheading: "", perServing: "685 mg", para: "N-Acetyl-L-Cysteine, L-Glutathione Reduced, S-Acetyl-L-Glutathione, Organic Aloe Vera Powder (Inner Leaf), Superoxide Dismutase " },
    ],
    click_on_ingredient: "Click on any ingredient to learn more!",
    click_view_suppliment: "Click to view Supplement Facts Label",
    energy_ingredient_array: [
        { heading: "Calories", subheading: "", perServing: "5", para: "The calorie is a unit of energy widely used in nutrition." },
        { heading: "Total Carbohydrate", subheading: "", perServing: "< 1 g", para: "" },
        { heading: "Vitamin C", subheading: "as Ascorbic Acid", perServing: "90 mg", para: "Vitamin C is an essential nutrient found mainly in fruits and vegetables. The body requires vitamin C to form and maintain bones, blood vessels, and skin. Vitamin C helps produce collagen, a protein needed to develop and maintain healthy teeth, bones, gums, cartilage, vertebrae discs, joint linings, skin and blood vessels." },
        { heading: "Niacin", subheading: "Vitamin B3", perServing: "25 mg", para: "Niacin's most important role in the body is the formation of NAD and NADP which assist the processing of food for energy. It also operates as antioxidant, helps with cell signaling, and supports healthy DNA structure." },
        { heading: "Vitamin B6", subheading: "as Pyridoxal-5-Phosphate Monohydrate", perServing: "10 mg", para: "The vitamin B6 group is especially important to the function of the central nervous system, skin, and blood. Vitamin B6 is involved in the formation of red blood cells since pyridoxal phosphate is the rate-limiting substance in making heme, a component of hemoglobin, the key oxygen-carrying pigment in red blood cells." },
        { heading: "Vitamin B12", subheading: "as Methylcobalamin", perServing: "500 mcg", para: "Vitamin B12 is important for the normal formation of red blood cells and the health of the nerve tissues. It is particularly important in the normal functioning of the nervous system via its role in the synthesis of myelin, and in the maturation of developing red blood cells in the bone marrow." },
        { heading: "ATP Energy Blend", subheading: "", perServing: "750 mg", para: "Acetyl-L-Carnitine, D-Ribose, Potassium Carbonate, Green Tea Extract, Green Coffee Bean Extract, DiCaffeine Malate, Synephrine HCL, Phosphoric Acid, Magnesium Citrate" },
        { heading: "GSH BioBlend", subheading: "", perServing: "310 mg", para: "L-Glutathione Reduced, Superoxide Dismutase, Organic Aloe Vera Powder (Inner Leaf), L-Cysteine, S-Acetyl-L-Glutathione" },
        { heading: "Focus Blend", subheading: "", perServing: "260 mg", para: "Beta Phenylethylamine HCL (PEA), Eleuthero Root Extract, L-Theanine, Quercetin Dihydrate, Korean Red Ginseng Extract" },
    ],

    ktmist_ingredient_array: [
        { heading: "Purified Water", subheading: "", perServing: "", para: "Pure water is the natural base of the formulation, helping to dissolve other ingredients while providing added hydration to the skin." },
        { heading: "Aloe Barbadensis Leaf Juice", subheading: "", perServing: "", para: "Known for its soothing and moisturizing properties, it can help calm irritated skin and provides gentle hydration back to the surface cells." },
        { heading: "Sorbitan Oleate Decylglucoside Crosspolymer", subheading: "", perServing: "", para: "This is a natural emulsifier that helps to smoothly blend the oils in the formula with the other water-based ingredients for the best possible mist experience." },
        { heading: "Glycerin", subheading: "", perServing: "", para: "As a humectant, Glycerin draws moisture into the skin, thereby keeping it more hydrated and helping to maintain a healthy skin barrier against intruding free radicals another toxins." },
        { heading: "Propolis Extract", subheading: "", perServing: "", para: "Offers antibacterial and anti-inflammatory benefits, potentially aiding in healing and soothing acne-prone or irritated skin." },
        { heading: "Bakuchiol", subheading: "", perServing: "", para: "A plant-based alternative to retinol, known for its ability to help reduce signs of aging, like wrinkles and fine lines, without the irritation commonly associated with retinol." },
        { heading: "Vitamin D3", subheading: "As Cholecalciferol", perServing: "", para: "May help in supporting skin health and repair." },
        { heading: "Tetrahexyldecyl Ascorbate", subheading: "", perServing: "", para: "A stable form of Vitamin C, it brightens the skin, supports collagen synthesis, and provides antioxidant protection." },
        { heading: "Hyaluronic Acid", subheading: "", perServing: "", para: "Helps in retaining moisture in the skin, leading to a plumped, hydrated appearance." },
        { heading: "Niacinamide", subheading: "", perServing: "", para: "Improves skin texture, minimizes pores, and helps in reducing the appearance of fine lines, while also balancing oil production." },
        { heading: "Jojoba Oil / Macadamia Seed Oil Esters", subheading: "", perServing: "", para: "Nourishes and moisturizes the skin, providing essential fatty acids and antioxidants." },
        { heading: "Squalene, Phytosteryl Macadamiate, Phytosterols", subheading: "", perServing: "", para: "These ingredients help in maintaining the skin's moisture balance and barrier function." },
        { heading: "Tocopherol", subheading: "Vitamin E", perServing: "", para: "An antioxidant that protects the skin from environmental stressors and helps in skin repair." },
        { heading: "Citrus Aurantium Bergamia (Bergamot) Fruit Oil:", subheading: "", perServing: "", para: "Provides a pleasant scent and may have some antibacterial properties." },
        { heading: "Melaleuca Alternifolia (Tea Tree) Leaf Oil", subheading: "", perServing: "", para: "Known for its anti-inflammatory and antimicrobial properties, beneficial for acne-prone skin." },
        { heading: "Dimethyl Isosorbide", subheading: "", perServing: "", para: "Enhances the delivery of other ingredients into the skin." },
        { heading: "Acacia Senegal Gum, Xanthan Gum", subheading: "", perServing: "", para: "Used as thickeners and stabilizers in the formulation." },
        { heading: "Tetrasodium Glutamate Diacetate", subheading: "", perServing: "", para: "A safe, non-toxic and biodegradable chelating agent with natural origins that helps to stabilize the product." },
        { heading: "Gluconolactone, Sodium Benzoate", subheading: "", perServing: "", para: "Synergistically act as non-GMO preservatives to ensure the product remains effective and safe over time and has been used in Certified organic cosmetics." },
        { heading: "GSH BioComplex", subheading: "", perServing: "", para: "Superoxide Dismutase, (S-Acetyl)-L-Glutathione, Organic Aloe Vera (Inner Leaf), L-Glutathione Reduced, N-Acetyl-L-Cysteine" },
    ],

    click_to_download_pdf: (section) => <>CLICK TO DOWNLOAD PDF {section} PRODUCT OVERVIEW</>,
    gsh_product_pdf_link: 'https://provizion.com/api/Files/documents/1',
    energy_product_pdf_link: 'https://provizion.com/api/Files/documents/3',
    get_provizion_now_text: "Get Provizion Now!",
    get_provizion_now_para: "If you're looking to strengthen your physical well being with a product and technology you can believe in, then Provizion GSH & ENERGY are right for you.",
    gsn_exploreBusiness_text: "If you're looking for a breakthrough financially and a business model you can believe in, then explore our Microbusiness before getting started.",
    gsh_bioBlend_inside_tabs: [
        "L-Cysteine (or Acetylated L-Cysteine), for intracellular Glutathione production support",
        "Bioavailable L-Glutathione (GSH)",
        "S-Acetyl-L-Glutathione",
        "Organic Aloe Vera Powder (inner Leaf), to provide Acemannan",
        "Superoxide Dismutase"
    ],

    healthFaq: [
        {
            question: '1. What is Provizion GSH?',
            answer: 'Provizion GSH may be the most advanced Glutathione product anywhere! A fusion of five synergistic nutrient technologies is the most innovative supplement developed to provide your body what it needs to increase your energy levels, boost your immune system, help you sleep better, and more.',
        },
        {
            question: '2.What does GSH Stand For ?',
            answer: 'GSH is the chemical symbol for Glutathione.',
        },
        {
            question: '3.What is Glutathione?',
            answer: 'Glutathione is the body’s master antioxidant. It is one of the most studied compounds by the scientific community because of its involvement in every process in the body. Glutathione is so important to the body and it can be found in every cell.',
        },
        {
            question: '4.If our bodies produce glutathione, why do I need to take a GSH supplement?',
            answer: 'Unfortunately as we grow older the levels of glutathione in our bodies decreases due to an increase of oxidative stress, toxins, aging, etc.',
        },
        {
            question: '5.What are the key benefits of Provizion GSH?',
            answer: 'Provizion GSH provides the following key benefits:',
            benefits: [
                'Supports a healthy immune system.', 'Improves concentration & clarity of mind.', 'Protects from the harmful effects of stress.', 'Boosts energy levels.',
                'Improves liver function.', 'Improves sleep.', 'Revitalization of the skin and youthful appearance.', 'Improves stamina and decreases recovery time.',
            ],
        },
        {
            question: '6.What additional ingredients does Provizion GSH contain?',
            answer: 'Provizion GSH includes ingredients that specifically focus on supporting the glutathione network.',
            ingredients: [
                'N-Acetyl L-Cysteine (NAC) - A vital precursor for the production of Glutathione.', 'Bioavailable S-Acetyl-L-Glutathione (SAG) - A powerful antioxidant that provides support against oxidative stress.', 'Aloe Vera Mannose Acemannan - Causes an immediate and profound cellular defense. ', 'Superoxide Dismutase (SOD) - An antioxidant enzyme that is vital in the reduction of oxidative stress.',
                'L-Glutamine - One of the most important nutrients for a healthy digestive system.', 'N-Acetyl-D-Glucosamine - Alleviates joint stiffness and pain, and protects the lining of the stomach and intestines.', 'L-Glycine - Is vital for the production of glutathione, creatine and collagen.', 'Alpha Lipoic Acid - Assists in fighting free radicals and helps regenerate other antioxidants such as vitamins C & E and Glutathione.',
                'Quercetin Dihydrate - Stabilizes cells that release histamine, which helps reduce inflammation.', 'Milk Thistle Seed Extract - Protects your liver, boosts your immune system, and fights free radicals.', 'Cordyceps - Helps support your cardiovascular, respiratory, and immune systems.', 'Vitamin C - A powerful antioxidant that supports your immune system.', 'Vitamin E - Stops the creation of free radical chain reactions.',
                'Vitamin B-6 - Assists in the maintenance of nerve, skin, and red blood cells.', 'Vitamin B-7 - Helps metabolize carbohydrates, fats, and amino acids.', 'Vitamin B-9 - Also known as folate or folic acid. Important for the formation of red blood cell.', 'Vitamin B-12 - A vital nutrient for red blood cell formation, neurological function and DNA synthesis.', 'Zinc Citrate - Acts as an effective anti-inflammatory and antioxidant agent.',
                'Selenium - Plays a critical role in the metabolism and thyroid function.', 'Magnesium Stearate - Helps improve the consistency and quality control of our supplement capsules.', 'Silicon Dioxide - also known as Synthetic amorphous silica (SAS). Acts as an anti-caking agent that occurs in many leafy greens and other vegetables.',
            ],
        },
        {
            question: '7.How often should I take Provizion GSH?',
            answer: 'We recommend taking 2 capsules once or twice daily with 8 oz. of water.',
        },
        {
            question: '8.Can I take Provizion GSH or Provizion Energy if I am taking other medications?',
            answer: 'We recommend you consult with your physician if needed before taking any Provizion products.',
        },
        {
            question: '9.What’s your return policy?',
            answer: 'We are so confident that you’ll feel a difference when you take Provizion GSH that we offer a 30-day money back guarantee from the date of your purchase.',
        },
        {
            question: '10. What are the key benefits of Provizion Energy?',
            answer: 'Provizion Energy provides the following key benefits:',
            ingredients: [
                ' Provides clean energy production.', 'Supports glutathione stores.', 'Supports mood and mental clarity.', 'Helps improve focus and mental performance.',
                'Helps decrease oxidative stress.', 'Helps optimize physical performance & recovery.',
            ],
            statement: [
                'These statements have not been evaluated bythe Food and Drug Administration. This product is not intended to diagnose, treat, cure, or prevent any disease.',
            ]
        },
        {
            question: ' 11. What is Provizion Energy?',
            answer: 'Provizion ENERGY delivers a Tri-Active Blend of Nano Nutrient Technology through its ATP Energy Blend, GSH BioBlend and Focus Blend of ingredients, giving your body the materials it needs to create its own clean energy, increase Glutathione stores, support a better mood and greater mental clarity.        ',
        }, {
            question: '12. How often should I take Provizion Energy?',
            answer: 'Recommended daily usage is one (1) 15ml ampule.',
        },
        {
            question: '13. What ingredients does Provizion Energy contain? ',
            answer: 'ATP Energy Blend Includes:',
            ingredients: [
                <>
                    <ul className="list-disc">
                        <b>  Vitamin C            -  </b> This is a powerful antioxidant that supports a healthy immune system.  <br /> <br />
                        <li> <b>  Niacin - Vitamin B-3 -  </b>   Niacin’s most important role in the body is the formation of NAD and NADP which assist the processing of food for energy. </li> <br />
                        <li> <b>  Vitamin B-12         -  </b>  B-12 is a nutrient that is required for proper red blood cell formation, healthy neurological function, and DNA synthesis. </li> <br />
                        <li> <b>  Vitamin B-6          -  </b>  B6 plays an important role in the body. It is needed to maintain the health of nerves, skin, and red blood cells. </li> <br />
                        <li> <b>  Acetyl L-Carnitine   - </b>  Acetyl-L-carnitine is used to help the body turn fat into energy, and may support mental clarity and reduce nerve agitation. </li> <br />
                        <li> <b>  Potassium Carbonate  - </b>  Potassium carbonate is a mineral supplement used to support healthy amounts of potassium in the blood. </li> <br />
                        <li> <b>  Green Tea Extract    - </b>  Green tea has been shown to help fight free radicals to reduce the occurrence of oxidative stress and can promote general wellness. </li> <br />
                        <li> <b> Green Coffee Bean Extract - </b>  Offers antioxidant and anti-inflammatory benefits, can improve energy and mood, and a high daily dose contains no more than about 20% of the caffeine content of a cup of coffee. </li> <br />
                        <li> <b>  DiCaffeine Malate    - </b> Helps boost energy levels, stimulate metabolism, and improve focus. </li> <br />
                        <li> <b>  Synephrine HCL       -  </b> Has been shown to improve the body's metabolism, increase energy levels, and enhance the process of thermogenesis through which the excess fats in the body are broken down. </li> <br />
                        <li> <b>  Phosphoric Acid      -  </b> Occurs naturally in many fruits. Mitochondria are tiny cylindrical objects in the cells sometimes referred to as “power plants.” Mitochondria contain enzymes that combine adenosine diphosphate (ADP) with one more phosphoric acid molecule to form ATP which is then converted into our energy. Phosphoric acid also adjusts PH balance and acts as a preservative in sweetened liquids. </li> <br />
                        <li> <b>  Magnesium Citrate    -  </b> An electrolyte that is particularly important for the muscles and nerve cells, it may provide benefits like enhancing relaxation, increasing sleep quality, and helping with stress by promoting calmness.  </li> <br /> <br />
                        <strong className="faq_bold"> GSH BioBlend Includes:  </strong>
                        <br />
                        <li> <b>  Bioavailable L-Glutathione - </b> A powerful antioxidant that's made in all of the body's cells. Its levels can decrease as a result of aging, stress, and exposure to toxins and pollutants. </li> <br />
                        <li> <b>  Superoxide Dismutase (SOD) - </b>  SOD is an antioxidant enzyme. Every cell contains some and needs it as part of the body’s antioxidant defense system. It is vital for the reduction of oxidative stress. </li> <br />
                        <li> <b>  Organic Aloe Vera Powder (Inner Leaf) - Acemannan -  </b> The gel within the leaf of the Aloe Vera plant contains over 200 compounds and an arsenal of phytonutrients with antioxidant properties. Its primary effect is that of causing an immediate and profound cellular defense response. </li> <br />
                        <li> <b>  L-Cysteine  - </b>  L-cysteine is a non-essential amino acid and thus is one of the building blocks required for the synthesis of proteins. It is a precursor for the production of glutathione by our cells, and thereby supports the antioxidant qualities of detoxification and neutralization of free radicals. </li> <br />
                        <li> <b>  Bioavailable S-Acetyl-Glutathione (SAG) - </b>  Though GSH has been known for many decades, the vast majority of glutathione supplements are never absorbed by the blood or our cells. This is because the fragile GSH molecules are broken down in the digestive system. Our SAG and L-glutathione are processed to flow through the digestive system and into the blood.</li> <br /> <br />
                        <b className="faq_bold">Focus Blend Includes: </b>
                        <br />
                        <li> <b>  Beta Phenylethylamine HCL (PEA) - </b>An organic compound, PEA acts as a central nervous system stimulant and has the unique ability to increase the activity of the major neurotransmitters. </li> <br />
                        <li> <b>  Eleuthero Root Extract (Siberian Ginseng) - </b> Siberian Ginseng promotes mental energy and helps to reduce fatigue, thereby supporting mental focus and performance and the body’s ability to manage physical and mental stress. </li> <br />
                        <li> <b>  L-Theanine   -  </b> Studies have suggested that L-Theanine could boost the immune system and has a calming effect on the brain, thereby helping the body manage stress, improve mood, and possibly also benefiting sleep quality. </li> <br />
                        <li> <b>  Quercetin Dihydrate -  </b> Quercetin is a polyphenol derived from plants that supports a healthy immune system, and promotes a normal inflammatory response in the body. Quercetin also has antioxidant capabilities. </li> <br />
                        <li> <b>  Korean Red Ginseng Extract - </b> Filled with vitamins, amino acids, essential oils, natural enzymes and natural minerals, it has been shown to be beneficial in promoting calmness and clarity. It may also support the body’s natural immune system and increase concentration.  </li> <br /> <br />
                    </ul>
                </>
            ],
        },
    ]
};

export default EXPLORE_HEALTH_TEXTS;