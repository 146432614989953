import React from 'react';
import { Box, Stepper, Step, StepLabel } from '@mui/material';
import _ from 'lodash';
import { styled } from 'styled-components';

const StepperStyleComponent = styled.div`
        .main_stepperRoot {
            max-width: 332px;
            margin: 40px auto 60px;
            display: flex;
            justify-content: space-between;
        }

        .step_CustomRoot {
            max-width: 85px;
            padding: 0;
            margin-bottom: 16px;

            .MuiStepConnector-root {
                width: 100%;
                left: calc(-50% - 20px);
                top: 20px;
                z-index: 1;

                .MuiStepConnector-line {
                    border-top-width: 2px;
                    border-color: #0071BA;
                }
            }

            .stepLable_Root {
                position: relative;
                z-index: 2;
                .stepLabelContainer {
                    .stepLabel_label {
                        font-weight: 600;
                        font-size: 13px;
                        line-height: 22px;
                        color: #6B6C6F;
                        text-align: center;
                        margin-top: 10px;
                        font-family: 'Open Sans', sans-serif;
                    }
                }
                .MuiStepLabel-iconContainer {
                    height: 42px;
                    width: 42px;

                    svg {
                        height: 100%;
                        width: 100%;
                        fill: #eaeaea;
                        transition: all ease-in-out 0.3s;

                        .MuiStepIcon-text {
                            font-size: 9px;
                            line-height: 1;
                            font-weight: 500;
                            fill: #6B6C6F;
                            font-family: 'Open Sans', sans-serif;
                        }
                    }
                }
            }
        }
    
    .step_CustomRoot.completedStep .MuiStepLabel-iconContainer svg,
    .stepper-wrap .step_CustomRoot.completedStep+.step_CustomRoot .MuiStepLabel-iconContainer svg {
        fill: #0071BA;
        background-color: #fff;
    }

   .step_CustomRoot.completedStep .MuiStepLabel-iconContainer svg .MuiStepIcon-text,
    .stepper-wrap .step_CustomRoot.completedStep+.step_CustomRoot .MuiStepLabel-iconContainer svg .MuiStepIcon-text {
        fill: #fff;
    }
`;

const StepperComponent = ({
    children,
    steps,
    activeStep,
}) => {
    return (
        <StepperStyleComponent>
            <Box sx={{ margin: 'auto' }} >
                <Stepper activeStep={activeStep} alternativeLabel classes={{ root: "main_stepperRoot" }}>
                    {_.map(steps, (label) => (
                        <Step key={label} classes={{ root: "step_CustomRoot", completed: "completedStep" }}>
                            <StepLabel classes={{ root: "stepLable_Root", labelContainer: 'stepLabelContainer', label: "stepLabel_label" }}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div className='stepper_container mb-[60px]'>
                    {children}
                </div>
            </Box>
        </StepperStyleComponent>
    );
};

export default StepperComponent;