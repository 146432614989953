import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import CheckoutSection from '../../Common/Components/AllFormsCommons/CheckoutSection'
import { storeAssigned } from '../../services/Methods/normalMethods';
import { CreateObject } from '../../services/Methods/checkoutPayloads';
import _ from 'lodash';

function CheckoutPage(props) {
    const { navigate, addToCartProducts, countryCodes, setAddToCartProduct } = useOutletContext();
    useEffect(() => {
        if (addToCartProducts?.normalProducts?.length === 0) {
            navigate(`/${storeAssigned()}/shop`)
        }
    }, [])
    const AutoshipTrue = _.find(addToCartProducts?.normalProducts, { frequency: 'autoship' })
    const AUTOSHIP_PRODUCTS_ON_CART = _.map(_.filter(addToCartProducts?.normalProducts, { frequency: 'autoship' }), (r) => CreateObject(r, AutoshipTrue));
    return (
        <div className="container mx-auto py-[100px] max-w-[1280px] px-[15px]">
            <CheckoutSection {...{
                comingFrom: 'normal',
                setAddToCartProduct,
                AUTOSHIP_PRODUCTS_ON_CART,
                addToCartProducts, countryCodes
            }} />
        </div>
    )
}

export default CheckoutPage