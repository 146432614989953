import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import localStorageCall from "../Methods/localstorage.hook";
import { _getPrice } from "../Methods/normalMethods";

function withMiniCartHOC(Component, comingForm) {

    const withMiniCartHOComponent = (props) => {
        const { setActiveStep } = props;

        const [openMiniCart, setOpenMiniCart] = useState(false);
        const [addToCartProducts, setAddToCartProduct] = useState({
            normalProducts: [],
            retailProducts: [],
            preferredProducts: [],
            IBOProducts: [],
        });

        useEffect(() => {
            if (comingForm) {
                const PRODUCTS = localStorageCall().getItem(String(comingForm + 'Products')) || [];
                let PRODUCTS_DATA = PRODUCTS;
                if (comingForm === 'normal') {
                    PRODUCTS_DATA = _.map(PRODUCTS, (row) => {
                        const CUSTOMER_TYPE = localStorageCall().getItem('customerTypeDescr');
                        let UPDATED_PRODUCTS = row;
                        if (_.includes(['preferred', 'IBO'], CUSTOMER_TYPE?.role)) {
                            UPDATED_PRODUCTS = {
                                ...row,
                                normal_autoship: row[`${CUSTOMER_TYPE?.role}_autoship`],
                                normal_oneTime: row[`${CUSTOMER_TYPE?.role}_oneTime`]
                            };
                        }
                        return UPDATED_PRODUCTS;
                    });
                    localStorageCall().setItem(`${comingForm}Products`, JSON.stringify(PRODUCTS_DATA));
                } else {
                    const NORMAL_PRODUCTS = localStorageCall().getItem('normalProducts');
                    PRODUCTS_DATA = _.uniqBy([...PRODUCTS, ...(NORMAL_PRODUCTS?.length > 0) ? NORMAL_PRODUCTS : []], 'id');
                }

                if (PRODUCTS_DATA?.length > 0) {
                    setAddToCartProduct((prv) => ({ ...prv, [comingForm + 'Products']: PRODUCTS_DATA }));
                    if (setActiveStep && (comingForm === 'retail')) {
                        localStorageCall().setItem(`${comingForm}Products`, JSON.stringify(PRODUCTS_DATA));
                        setActiveStep(1);
                    }
                }
            }
        }, [comingForm]);

        //minicart or view cart products
        function _minCartAndViewCartQuantityIncDec(id, section) {
            const PRODUCTS = addToCartProducts[comingForm + 'Products'];

            const FINDINDEX_LOCAL = _.findIndex(PRODUCTS, { id: id });
            if (FINDINDEX_LOCAL > -1) {
                PRODUCTS[FINDINDEX_LOCAL]['quantity'] = (section === "sub") ? ((PRODUCTS[FINDINDEX_LOCAL]?.quantity - 1 > 0) ? PRODUCTS[FINDINDEX_LOCAL]?.quantity - 1 : 1) : PRODUCTS[FINDINDEX_LOCAL]?.quantity + 1;
                localStorageCall().setItem(String(comingForm + 'Products'), JSON.stringify(PRODUCTS));

                const CartData = localStorageCall().getItem(String(comingForm + 'Products'));
                setAddToCartProduct((prv) => ({ ...prv, [comingForm + 'Products']: CartData }));
            }
        }

        function _changeFrequencyOfProducts(id, frequency = "oneTime") {

            let PRODUCTS = addToCartProducts[comingForm + 'Products'];
            const FIND_PRODUCT_INDEX = _.findIndex(PRODUCTS, { id });

            if (FIND_PRODUCT_INDEX > -1) {
                PRODUCTS[FIND_PRODUCT_INDEX] = { ...PRODUCTS[FIND_PRODUCT_INDEX], frequency };
            }
            localStorageCall().setItem(String(comingForm + 'Products'), JSON.stringify(PRODUCTS));
            const CartData = localStorageCall().getItem(String(comingForm + 'Products'));
            setAddToCartProduct((prv) => ({ ...prv, [comingForm + 'Products']: CartData }));
            // setOpenMiniCart(true);
        }

        function _deleteProductFromCart(id) {
            const PRODUCT_DATA = addToCartProducts[comingForm + 'Products'];
            const FINDPRODUCT = _.find(PRODUCT_DATA, { id });

            if (FINDPRODUCT) {
                const REMAIN_DATA = _.reject(PRODUCT_DATA, FINDPRODUCT);
                localStorageCall().setItem(String(comingForm + 'Products'), JSON.stringify(REMAIN_DATA));
                const CartData = localStorageCall().getItem(String(comingForm + 'Products'));
                setAddToCartProduct((prv) => ({ ...prv, [comingForm + 'Products']: CartData }));
            }

            const FIND_NORMAL_PRODUCTS = _.find(localStorageCall().getItem('normalProducts') || [], { id });
            if (FIND_NORMAL_PRODUCTS) {
                const REMAIN_DATA = _.reject(localStorageCall().getItem('normalProducts') || [], FIND_NORMAL_PRODUCTS);
                localStorageCall().setItem('normalProducts', JSON.stringify(REMAIN_DATA));
                setAddToCartProduct((prv) => ({ ...prv, ['normalProducts']: REMAIN_DATA }));
            }
        }
        function _autoShipCheck() {
            let isAutoship = false;
            if (_.includes(['retail', 'preferred', 'IBO', 'normal'], comingForm)) {
                isAutoship = _.find(addToCartProducts[comingForm + 'Products'], { frequency: 'autoship' }) ? true : false;
            }
            return isAutoship;
        }

        const subTotal = useMemo(() => {
            if (addToCartProducts[comingForm + 'Products']?.length > 0) {
                return ({
                    productCount: _.map(addToCartProducts[comingForm + 'Products'], 'quantity').reduce((prv, next) => prv + next),
                    productSubTotal: _.map(addToCartProducts[comingForm + 'Products'], (row) => (_autoShipCheck() ? row[comingForm + '_autoship'] : row[comingForm + '_' + row?.frequency]) * +row?.quantity).reduce((prv, next) => prv + next)
                })
            }
        }, [addToCartProducts[comingForm + 'Products']]);

        return <Component {...{
            openMiniCart, setOpenMiniCart, addToCartProducts, setAddToCartProduct, subTotal, _autoShipCheck,
            _minCartAndViewCartQuantityIncDec, _deleteProductFromCart, _changeFrequencyOfProducts
        }} {...props} />
    }
    return withMiniCartHOComponent;

}

export default withMiniCartHOC;