import React from 'react';
import CountryAndStateInputs from '../../../Inputs/CountryAndState.Input';
import LoaderComponent from '../../../Inputs/LoaderComponent';
import TextFieldComponent from '../../../Inputs/TextFieldComponent';

const AddressSection = (props) => {
    const {
        fieldValue,
        section,
        handleChangeFunction,
        normalAddressError,
        countryState,
        setCountryState,
        isLoading,
        countryCodes,
        disabled,
        sameAsBillingChecked
    } = props;

    function _onChangeField(event, values) {
        if (section === "shipping") {
            fieldValue?.handleBlur(event);
            handleChangeFunction(event, values);
        }
    }

    const InputField = (name, label, type, disable) => {
        return (
            <div className='mb-[30px] field-class'>
                <TextFieldComponent
                    name={`${section}_${name}`}
                    type={type ? type : 'text'}
                    label={label}
                    value={fieldValue?.values?.[`${section}_${name}`]}
                    error={!!(fieldValue?.errors?.[`${section}_${name}`])}
                    onChange={fieldValue?.handleChange}
                    errorMessage={fieldValue?.errors?.[`${section}_${name}`]}
                    disabled={disable}
                    onBlur={(event) => {
                        _onChangeField(event, fieldValue?.values)
                    }}
                />
                {/* {name === "phone" ? <span className="f-q-regular">Note: phone number will be used to validate a code in the next step *</span> : ""} */}
            </div>
        )
    };

    return (
        <>
            {isLoading && <LoaderComponent />}
            {InputField('urname', 'First name', 'text', disabled)}
            {InputField('lastname', 'Last name', 'text', disabled)}
            {section === "billing" &&
                <div className='mb-2 field-class'>
                    {InputField('email', 'Email', 'text', disabled)}
                </div>
            }
            <CountryAndStateInputs  {...{ countryCodes, countryState, setCountryState }} onChange={(value, fromTo = 'country') => {
                const event = { target: { name: `${section}_${fromTo}`, value } }
                if (value !== 'NG') {
                    setTimeout(() => {
                        fieldValue?.setFieldError('shipping_delivery', '');
                        fieldValue?.setFieldTouched('shipping_delivery', true, true);
                    }, 3000)
                }
                _onChangeField(event, fieldValue?.values);
            }} />
            <div className='mb-[30px] field-class'>
                <TextFieldComponent
                    className='form-control'
                    name={section + "_street"}
                    value={fieldValue?.values[`${section + "_street"}`]}
                    onChange={fieldValue?.handleChange}
                    onBlur={(event) => { _onChangeField(event, fieldValue?.values) }}
                    error={!!(normalAddressError) || !!fieldValue?.errors[`${section}_street`]}
                    errorMessage={(normalAddressError && section === "shipping") ? normalAddressError : "" || fieldValue?.errors[`${section}_street`]}
                    label='Street Address*' />
            </div>
            {InputField('street2', 'Apartment, suite, unit, etc (optional)')}
            {InputField('city', 'Town/City')}
            {InputField('zipcode', 'Zip Code')}
            {InputField('phone', 'Phone (optional)')}
            {InputField('delivery', 'Delivery Phone Number (if applicable)')}

        </>

    )
}

export default AddressSection;