import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import localStorageCall from "../../../services/Methods/localstorage.hook";
import { _getPrice, _productPrice } from "../../../services/Methods/normalMethods";
import { getAllProductApi } from "../../../services/Redux/Reducer/AllProductSlice";

const useSingleProductHook = (props) => {

    const { addToCartProducts, setAddToCartProduct, setOpenMiniCart, dispatch, params } = props;

    const memorizeSelector = createSelector(
        state => state?.AllProductSlice,
        (productState) => ({ loading: productState?.isLoading })
    )
    const { loading } = useSelector((state) => memorizeSelector(state))

    const [swiper, setSwiper] = useState(null);
    const [index, setIndex] = useState(0);
    const [singleProductData, setSingleProductData] = useState([]);
    const [extraObjects, setExtraObject] = useState(null);
    const [selectedPrice, setSelectedPrice] = useState('oneTime');

    useEffect(() => {
        if (params?.id) {
            try {
                dispatch(getAllProductApi('normalProduct', (data) => {
                    const EXTRA_DATA = [];
                    const CUSTOMER_TYPE = localStorageCall().getItem('customerTypeDescr');
                    const ProductData = _.compact(_.map(data, (row) => {
                        // const SECTION_USE = (CUSTOMER_TYPE?.role && section === 'normal') ? CUSTOMER_TYPE?.role : section;
                        let CONDITION = _.includes(['IBO', 'preferred'], CUSTOMER_TYPE?.role) ? (_getPrice(row, 3) && _getPrice(row, 6)) : (_getPrice(row, 1) && _getPrice(row, 5));
                        if (CONDITION) {
                            EXTRA_DATA.push({
                                id: row?.itemId,
                                title: row?.title,
                                images: _.compact([row?.mediumImage, row?.tinyImage, row?.smallImage, row?.largeImage]),
                                otherFields: row?.otherFields
                            });
                            let NORMAL_PRICEING = { oneTime: _getPrice(row, 1), autoship: _getPrice(row, 5) }
                            if (_.includes(['preferred', 'IBO'], CUSTOMER_TYPE?.role)) {
                                NORMAL_PRICEING = { oneTime: _getPrice(row, 3), autoship: _getPrice(row, 6) };
                            }
                            return ({
                                id: row?.itemId,
                                itemId: row?.itemId,
                                image: row?.largeImage,
                                title: row?.title,
                                normal_oneTime: +NORMAL_PRICEING?.oneTime || 0,
                                normal_autoship: +NORMAL_PRICEING?.autoship || 0,
                                retail_oneTime: _getPrice(row, 1),
                                retail_autoship: _getPrice(row, 5),
                                preferred_oneTime: _getPrice(row, 3),
                                preferred_autoship: _getPrice(row, 6),
                                IBO_oneTime: _getPrice(row, 3),
                                IBO_autoship: _getPrice(row, 6),
                                quantity: 1,
                                itemCode: row?.fullItemCode,
                                isEligibleForAutoOrder: row?.isEligibleForAutoOrder,
                                stock: "",
                            });

                        }
                    }));
                    const SINGLE_PRODUCT = _.find(ProductData, { id: +params?.id });
                    setSingleProductData(SINGLE_PRODUCT);
                    setExtraObject(_.find(EXTRA_DATA, { id: +params?.id }));
                }));
            } catch (e) {
                console.log('ddddddddddddddddddddddd', e)
            }
        }
    }, [params]);


    function _IncreaseDecreaseFunction(id, section = 'add') {
        singleProductData['quantity'] = (section === "sub") ? ((singleProductData?.quantity - 1 > 0) ? singleProductData?.quantity - 1 : 1) : singleProductData?.quantity + 1;
        setSingleProductData({});
        setSingleProductData(singleProductData);
    }

    function _addToCart(id) {
        const PRODUCTS = addToCartProducts['normalProducts'];
        const FIND_ALREADY_PRODUCT = _.find(PRODUCTS, { id });

        let ADD_PRODUCTS = [];
        if (FIND_ALREADY_PRODUCT) {
            ADD_PRODUCTS = [..._.reject(PRODUCTS, FIND_ALREADY_PRODUCT), {
                ...FIND_ALREADY_PRODUCT,
                quantity: (+singleProductData?.quantity) + (FIND_ALREADY_PRODUCT?.quantity),
                frequency: selectedPrice
            }];
        } else {
            ADD_PRODUCTS = [...PRODUCTS, { ...singleProductData, frequency: selectedPrice }]
        }

        localStorageCall().setItem('normalProducts', JSON.stringify(ADD_PRODUCTS));
        setAddToCartProduct((prv) => ({ ...prv, 'normalProducts': ADD_PRODUCTS }));
        setOpenMiniCart(true);
    }

    return ({
        loading,
        singleProductData, extraObjects,
        swiper, setSwiper, index, setIndex,
        selectedPrice, setSelectedPrice,

        _IncreaseDecreaseFunction, _addToCart
    })
}

export default useSingleProductHook;