import React from 'react';
import HealthBanner from './assets/sections/health-banner2.jpg';
import ExploreHome from './assets/sections/explore-home.jpg';
import Business from './assets/sections/business.jpg';
import { Link } from 'react-router-dom';
import ButtonComponent from '../../Inputs/ButtonComponent';
import TextFieldComponent from '../../Inputs/TextFieldComponent';
import { storeAssigned } from '../../services/Methods/normalMethods';
import ROUTING_TEXT from '../../TextConfigs/RoutingAndSmallContent.text';

const sections = [
  {
    imageSrc: HealthBanner,
    title: 'Explore how one of the most scientifically studied anti-aging molecules is changing lives.',
    link: `/${storeAssigned()}/${ROUTING_TEXT?.explore_health_routingName}`
  },
  {
    imageSrc: ExploreHome,
    title: 'Feeling healthy & energized just got easier with Smartship',
    description:
      'With Smartship your product ships every month on the same day. Cancel or change your subscription at any time. With select product orders, you receive discounted shipping abroad and FREE shipping in the USA!',
  },
  {
    imageSrc: Business,
    title: 'Earn and grow with Provizion',
    description:
      'Change lives as you build an international business',
    link: `/${storeAssigned()}/${ROUTING_TEXT?.explore_business_routingName}`
  },
];

const ImageSections = () => {
  return (
    <>
      {sections.map((section, index) => (
        <section key={index}>
          <div className="relative lg:pt-[145px] sm:pt-[100px] lg:pb-[170px] sm:pb-[100px] py-[50px] bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${section.imageSrc})` }}>
            <div className="bg-[#383737] opacity-80 absolute top-0 left-0 h-full w-full z-10"></div>
            <div className={`container max-w-[1260px] px-[15px] relative z-20  ${index === 1 ? '' : 'mx-auto'}`}>
              <div className="flex flex-wrap -mx-[15px]">
                <div className={`px-[15px] ${index === 1 ? 'w-full ml-0' : 'xl:w-7/12 w-full ml-auto'}`}>
                  <h2 className="lg:text-[32px] text-[24px] lg:mb-6 mb-4 leading-[1.2] text-white text-center">{section.title}</h2>
                  {index === 2 && section.description ? (
                    <p className="lg:text-2xl text-base leading-[1.2] text-white text-center max-w-[980px] mx-auto lg:mb-9 mb-4">{section.description}</p>
                  ) : null}
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {index === 0 || index === 2 ? (
                      <Link to={section.link} className='w-full mx-auto max-w-[250px]'>   <ButtonComponent classes="top-24 w-full max-w-[250px]">Learn More</ButtonComponent> </Link>
                    ) : null}
                  </div>
                  {index === 1 && section.description ? (
                    <p className="lg:text-2xl text-base leading-[1.2] text-white text-center max-w-[980px] mx-auto lg:mb-9 mb-4">{section.description}</p>
                  ) : null}

                </div>
              </div>
            </div>
          </div>
        </section>
      ))}
    </>
  );
};

export default ImageSections;
