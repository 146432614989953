import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

const HealthFaq = ({ HEALTH_TEXT }) => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <>
            <section className="pt-[80px] pb-[100px]">
                <div className="container mx-auto max-w-[1260px] px-[15px]">
                    <div className="text-center mb-[60px]">
                        <h2 className='text-[40px] leading-[1.2] mb-3 text-heading-color font-extrabold'>{HEALTH_TEXT?.faq_text}</h2>
                        <p className='text-[18px] text-dark-content leading-[1.5] mb-4'>{HEALTH_TEXT?.faq_para}</p>
                    </div>
                    <div className="-mx-[15px] flex flex-wrap">
                        {HEALTH_TEXT?.healthFaq.map((item, index) => (
                            <div key={index} className="px-[15px] md:w-1/2 w-full cstm-accordion">
                                <Accordion expanded={expanded === index} onChange={handleChange(index)} className="rounded-[7px]  shadow-[0px_0px_30px_rgba(0,0,0,0.12)] mb-[30px]" tabIndex={index + 1}>
                                    <AccordionSummary className="group flex justify-between px-[30px] py-[30px] items-center transition ease duration-500 cursor-pointer relative">
                                        <div className="group-focus:text-black transition ease duration-500 text-base leading-[26px] font-semibold text-black uppercase">
                                            {item.question}
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails >
                                        <div className="py-[15px]">
                                            {/* <p className=" text-[#252525] ">
                                                <b>ATP Energy Blend Includes: </b>
                                            </p> */}
                                            {item.answer === 'ATP Energy Blend Includes:' ? <p className=" text-[#252525] ">
                                                <b>ATP Energy Blend Includes: </b>
                                            </p> : <p className="text-base leading-[1.8] text-[#252525] font-light">
                                                {item.answer}
                                            </p>}

                                            {item.benefits && (
                                                <ul className='list-disc'>
                                                    {item.benefits.map((benefit, i) => (
                                                        <li className='text-base leading-[1.8] text-[#252525] font-light' key={i}>{benefit}</li>
                                                    ))}
                                                </ul>
                                            )}
                                            {item.ingredients && (
                                                <ul className='list-disc'>
                                                    {item.ingredients.map((ingredient, i) => (
                                                        <li className='text-base leading-[1.8] text-[#252525] font-light' key={i}>{ingredient}</li>
                                                    ))}
                                                </ul>
                                            )}
                                            {item.statement && (
                                                <ul>
                                                    {item.statement.map((statement, i) => (
                                                        <li className='text-base leading-[1.8] text-[#252525] font-light' key={i}>{statement}</li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
};

export default HealthFaq;