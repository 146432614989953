import { styled } from "styled-components";

const SingleProductPageCSS = styled.div`
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #4b566b;

    &.main_single_page {
        .copy-paste-block {
            line-height: 1.5;
            font-size: 16px;
            strong {
                font-weight: 700px !important;
            }
        }
        .listing_style {
            font-size: 16px;
            line-height: 1.5;
            ul {
                list-style: disc !important;
                padding-left: 40px;
            }
        }
    }

`;

export default SingleProductPageCSS;