import * as Yup from 'yup';
import { REFFERAL_VALUES } from './normalMethods';

export const InitialValues = {
    email: "", firstName: "", lastName: "", phone: "", city: "", zipcode: "", address: "",
    address_2: "", webAlias: "", company_name: "", password: "", confirm_password: "",
    refferal: REFFERAL_VALUES?.webalies ? REFFERAL_VALUES?.webalies : "",
};

export const loginIntialValue = { email: '', password: '' }

export function _formDefaultFormat(userData, formData, countryState) {
    return ({
        firstName: formData?.firstName || userData?.firstName,
        lastName: formData?.lastName || userData?.lastName,
        email: formData?.email || userData?.emailAddress,
        phone: formData?.phone || userData?.phoneNumbers?.cellPhone,
        address: formData?.address || userData?.shippingAddress?.address1,
        company_name: formData?.companyName || userData?.companyName,
        address_2: formData?.address_2 || userData?.shippingAddress?.address2,
        city: formData?.city || userData?.shippingAddress?.city,
        zipcode: formData?.zipcode || userData?.shippingAddress?.postalCode,
        country: countryState?.country || userData?.countryCode,
        state: countryState?.state || userData?.shippingAddress?.regionProvState,
        webAlias: formData?.webAlias || userData?.webAlias,
        refferal: REFFERAL_VALUES?.webalies ? REFFERAL_VALUES?.webalies : ""
    })
}

export const loginDefaultSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    password: Yup.string()
        .required('Password is required')
        .min(6, 'Password must be at least 6 characters'),
});

export const signUpValidationSchema = (activestep, isUserLogin, isWebAlias = false, section = "") => {
    const specialCharacterRegex = /^[a-zA-Z0-9_-]+$/;
    const validationArray = [
        Yup.object().shape({ email: Yup.string().required('Email is required').email('Invalid email') }),
        Yup.object().shape({
            email: Yup.string().required('Email is required').email('Invalid email'),
            firstName: Yup.string().required('First Name is required'),
            lastName: Yup.string().required('Last Name is required'),
            // phone: Yup.string()
            //     .required('Phone number is required')
            //     .test('is-10-digits-or-valid-format', 'Invalid phone number', value => {
            //         return /^\d{10}$/.test(value) || /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(value);
            //     }),
            phone: Yup.string()
                .required('Phone number is required')
                .test('is-valid-format', 'Invalid phone number', (value) => {
                    const ValidateNumberValue = value?.replace(/\s/g, '');
                    return /^(?:\+?[0-9]{1,3}[-‑. ]?)?(?:\([0-9]{3}\)|[0-9]{3})[-‑. ]?[0-9]{3}[-‑. ]?[0-9]{4}$/.test(ValidateNumberValue) ||
                        /^\d{10,14}$/.test(ValidateNumberValue);
                })
                .transform((originalValue, originalObject) => {
                    if (originalValue) {
                        return originalValue.replace(/\s/g, '');
                    }
                    return originalValue;
                }),
            password: isUserLogin ? Yup.string().notRequired() : Yup.string().required('Password is required.').min(6, 'Password must be at least 6 characters long'),
            confirm_password: isUserLogin ? Yup.string().notRequired() : Yup.string().required('Confirm Password is required.').test('confirm_password', 'password and confirm password should be same', function (val) {
                const password = this.resolve(Yup.ref('password'));
                if (val !== password) return false;
                return true;
            }),
            city: Yup.string().required('City is required'),
            zipcode: Yup.string().required('Zip code is required'),
            address: Yup.string().required('address is required'),
            address_2: Yup.string().notRequired(),
            webAlias: isWebAlias ? Yup.string().required('webAlias is required').matches(specialCharacterRegex, 'Special characters are not allowed') : Yup.string().notRequired(),

            refferal: Yup.string().required('referral is required'),
            // ageChecked: Yup.boolean().oneOf([true], "Please select the age restrictions."),
            // SmsChecked: Yup.boolean().oneOf([true], "Please agree to be contacted via email and SMS."),
        })
    ];
    return validationArray[activestep];
};

export const SHIPPING_VALIDATION_OBJECT = (country) => Yup.object().shape({
    shipping_urname: Yup.string().required('Name is required.'),
    shipping_lastname: Yup.string().required('Last name is required.'),
    shipping_street: Yup.string().required('Street address is required.'),
    shipping_street2: Yup.string().notRequired(),
    shipping_city: Yup.string().required('City is required.'),
    shipping_zipcode: Yup.string().required('Zipcode is required.'),
    termscondition: Yup.bool().oneOf([true], 'Please accept the terms and conditions'),

    shipping_phone: Yup.string()
        .notRequired()
        .test('is-valid-format', 'Invalid phone number', (value) => {
            if (value) {
                const ValidateNumberValue = value?.replace(/\s/g, '');
                return /^(?:\+?[0-9]{1,3}[-‑. ]?)?(?:\([0-9]{3}\)|[0-9]{3})[-‑. ]?[0-9]{3}[-‑. ]?[0-9]{4}$/.test(ValidateNumberValue) ||
                    /^\d{10,14}$/.test(ValidateNumberValue);
            }
            return true;
        })
        .transform((originalValue) => {
            if (originalValue) {
                return originalValue.replace(/\s/g, '');
            }
            return originalValue;
        }),

    shipping_delivery: (country === 'NG') ? Yup.string().required('Delivery number is required')
        .test('is-valid-format', 'Invalid delivery number', (value) => {
            const ValidateNumberValue = value?.replace(/\s/g, '');
            return /^(?:\+?[0-9]{1,3}[-‑. ]?)?(?:\([0-9]{3}\)|[0-9]{3})[-‑. ]?[0-9]{3}[-‑. ]?[0-9]{4}$/.test(ValidateNumberValue) ||
                /^\d{10,14}$/.test(ValidateNumberValue);
        })
        .transform((originalValue) => {
            if (originalValue) {
                return originalValue.replace(/\s/g, '');
            }
            return originalValue;
        })
        : Yup.string().notRequired()
            .test('is-valid-format', 'Invalid Delivery number', (value) => {
                if (!value) {
                    return true;
                }
                const ValidateNumberValue = value?.replace(/\s/g, '');
                return /^(?:\+?[0-9]{1,3}[-‑. ]?)?(?:\([0-9]{3}\)|[0-9]{3})[-‑. ]?[0-9]{3}[-‑. ]?[0-9]{4}$/.test(ValidateNumberValue) ||
                    /^\d{10,14}$/.test(ValidateNumberValue);
            })
            .transform((originalValue) => {
                if (originalValue) {
                    return originalValue.replace(/\s/g, '');
                }
                return originalValue;
            })

    // .test('is-10-digits-or-valid-format', 'Invalid delivery number', value => {
    //     return /^\d{10}$/.test(value) || /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(value);
    // })
});

export const VALIDATION_SCHEMA = Yup.object().shape({
    shipping_urname: Yup.string().required('First Name is required.'),
    shipping_lastname: Yup.string().required('Last name is required.'),
    shipping_street: Yup.string().required('Street address is required.'),
    shipping_street2: Yup.string().notRequired(),
    shipping_city: Yup.string().required('City is required.'),
    shipping_zipcode: Yup.string().required('Zipcode is required.'),
    shipping_phone: Yup.string()
        .notRequired()
        .test('is-valid-format', 'Invalid Phone number', (value) => {
            if (value) {
                const ValidateNumberValue = value?.replaceAll(/[^\w\s]/gi, '');
                return /^(?:\+?[0-9]{1,3}[-‑. ]?)?(?:\([0-9]{3}\)|[0-9]{3})[-‑. ]?[0-9]{3}[-‑. ]?[0-9]{4}$/.test(ValidateNumberValue) ||
                    /^\d{10,14}$/.test(ValidateNumberValue);
            }
            return true;
        })
        .transform((originalValue) => {
            if (originalValue) {
                return originalValue.replace(/\s/g, '');
            }
            return originalValue;
        }),
    shipping_delivery: Yup.string()
        .test('is-valid-format', 'Invalid Delivery number', (value) => {
            if (!value) {
                return true;
            }
            const ValidateNumberValue = value?.replace(/\s/g, '');
            return /^(?:\+?[0-9]{1,3}[-‑. ]?)?(?:\([0-9]{3}\)|[0-9]{3})[-‑. ]?[0-9]{3}[-‑. ]?[0-9]{4}$/.test(ValidateNumberValue) ||
                /^\d{10,14}$/.test(ValidateNumberValue);
        })
        .transform((originalValue) => {
            if (originalValue) {
                return originalValue.replace(/\s/g, '');
            }
            return originalValue;
        })
});


export const BILLING_VALIDATION_OBJECT = {
    billing_urname: Yup.string().required('Name is required.'),
    billing_lastname: Yup.string().required('Last name is required.'),
    billing_email: Yup.string().required('E-mail is required').email('Please enter valid email'),
    billing_street: Yup.string().required('Street address is required.'),
    billing_street2: Yup.string().notRequired(),
    billing_city: Yup.string().required('City is required.'),
    billing_zipcode: Yup.string().required('Zipcode is required.'),
    termscondition: Yup.bool().oneOf([true], 'Please accept the terms and conditions'),
    termscondition2: Yup.bool().oneOf([true], 'You need to accept the accepted the policies and procedures'),
    privacy_policy: Yup.bool().oneOf([true], 'Please select the privacy policy'),
    membershipCharge: Yup.bool().oneOf([true], 'Please select the membership charge'),
}

export const SUPPORT_VALIDATION_OBJECT = Yup.object().shape({
    firstname: Yup.string().trim().required('First name is required'),
    lastname: Yup.string().trim().required('Last name is required'),
    email: Yup.string().trim().required('Email is required').email('Enter valid email'),
    description: Yup.string().trim().required('Description is required')
})
