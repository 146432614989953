import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { REFFERAL_VALUES } from '../../../services/Methods/normalMethods';
import { SignUpFormApi } from '../../../services/Redux/Reducer/LoginSlice';

const useCustomerSignupHook = (props) => {
    const FormId = 'customer_signup';
    const Title = 'Customer SignUp';
    const dispatch = useDispatch();
    const {
        handleInputChange, formData, setFormData, countryState, isUserLogin,
        setCountryState, webAliesError, setWebAliesErros, navigate
    } = props;

    const memoRizedSelector = createSelector(
        (state) => state.LoginSlice,
        ({ signupData }) => ({ signupData })
    )
    const { signupData } = useSelector((state) => memoRizedSelector(state))

    useEffect(() => {
        if (signupData) {
            navigate('/');
        } else {
            if (isUserLogin) {
                navigate('/');
            }
        }
    }, [isUserLogin]);

    function _sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function _submitForm(values, actions) {
        await _sleep(1000);
        setFormData(values)
        dispatch(SignUpFormApi({ ...values }, 'retail'));
        actions.setSubmitting(false);
    }
    function _handleSubmit(values, actions) {
        if (countryState?.country && countryState?.state) {
            _submitForm({ country: countryState?.country, state: countryState?.state, ...values }, actions);
        }

        else {
            if (countryState?.country === "") {
                setCountryState((prv) => ({ ...prv, countryError: "Please select your country" }))
            }
            if (countryState?.state === "") {
                setCountryState((prv) => ({ ...prv, stateError: "Please select your state" }))
            }
        }
    }

    return ({
        FormId,
        Title,
        handleInputChange,
        formData,
        setFormData,
        countryState,
        setCountryState,
        webAliesError,
        setWebAliesErros,
        _handleSubmit
    });
}

export default useCustomerSignupHook;