import _ from 'lodash';
import React from 'react';
import BlueWhiteTwoOption from '../../../Common/Components/BlueWhiteTwoOption';
import ExploreCommonBanner from '../../../Common/Components/ExploreCommonBanner';
import Gsh from './Gsh';
import HealthFaq from './HealthFaq';
import Ingredients from './Ingredients';
import PurchaseNow from './PurchaseNow';
import { storeAssigned } from '../../../services/Methods/normalMethods';
import ROUTING_TEXT from '../../../TextConfigs/RoutingAndSmallContent.text';
import VideoPlayer from '../../../Common/Components/VideoPlayer';

const MainExploreHealth = ({ HEALTH_TEXT }) => {
    const exploreHealthVideoLink = "https://player.vimeo.com/video/737226904?autoplay=1&loop=1&autopause=0&muted=1&controls=true";
    return (
        <>
            <ExploreCommonBanner TEXTS={HEALTH_TEXT} pageText={_.upperCase(HEALTH_TEXT?.health_text)} title={HEALTH_TEXT?.provizion_globel_text} />
            <section className="py-[60px]">
                <div className="container mx-auto max-w-[1260px] px-[15px] flex flex-wrap">
                    <div className="relative pb-[56.5%] w-full">
                        <div className="absolute top-0 left-0 w-full h-full">
                            <VideoPlayer url={exploreHealthVideoLink} style={{ width: '100%', height: '100%', right: 0, }} />
                        </div>
                    </div>
                </div>
            </section>
            <BlueWhiteTwoOption data={[
                { heading: HEALTH_TEXT?.get_provizion_now_text, para: HEALTH_TEXT?.get_provizion_now_para, link: `/${storeAssigned()}/${ROUTING_TEXT?.signup_routingName}` },
                { heading: HEALTH_TEXT?.explore_business, para: HEALTH_TEXT?.gsn_exploreBusiness_text, link: `/${storeAssigned()}/${ROUTING_TEXT?.explore_business_routingName}` }
            ]} TEXT={HEALTH_TEXT} />
            <Gsh {...{ HEALTH_TEXT }} />
            {/* <Ingredients /> */}
            {/* <PurchaseNow /> */}
            <HealthFaq {...{ HEALTH_TEXT }} />
        </>
    )
};

export default MainExploreHealth;