import React, { useMemo } from 'react';
import { Delete } from "@mui/icons-material";
import { CUSTOMER_TYPE, FloatValues, storeAssigned, _productPrice, getCurrencySign } from '../../../services/Methods/normalMethods';
import { Divider, Drawer, IconButton } from '@mui/material';
import ShoppingCartStyleCss from '../../../../styles/ShoppingCartStyleCss';
import _ from 'lodash';
import { Close } from "@mui/icons-material";
import { QuantityComponent } from '../../../services/Methods/commonPayload';
import { Link, useOutletContext } from 'react-router-dom';
import ROUTING_TEXT from '../../../TextConfigs/RoutingAndSmallContent.text';

const ShoppingCartComponent = ({
    openMiniCart,
    setOpenMiniCart,
    productData,
    section,
    _minCartQuantityIncDec,
    _deleteProductFromCart,
    activeStep,
    setActiveStep,
    _autoShipCheck
}) => {
    const { isUserLogin, autoshipOrders } = useOutletContext();
    const toggleDrawer = (open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setOpenMiniCart(false)
    };

    const subTotal = useMemo(() => {
        if (productData?.length > 0) {
            return ({
                productCount: _.map(productData, 'quantity').reduce((prv, next) => prv + next),
                productSubTotal: _.map(productData, (row) => ((!!(_.find(autoshipOrders, { isActive: true })) || _autoShipCheck()) ? row[section + '_autoship'] : row[section + '_' + row?.frequency]) * +row?.quantity).reduce((prv, next) => prv + next)
            })
        }
    }, [productData]);

    const CURRENT_STORE = _.upperCase(storeAssigned()) || 'US';

    return (
        <>
            <div className="print_logo mini-root-div" onClick={() => setOpenMiniCart(true)}>
                <i className='fa-solid fa-cart-shopping text-white' onClick={() => setOpenMiniCart(true)} />
                {productData?.length > 0 &&
                    <span>{productData?.length} </span>
                }
            </div>
            <Drawer
                anchor={"right"}
                open={openMiniCart}
                onClose={toggleDrawer(false)}
                classes={{ paper: "flayout_cart" }}
            >
                <ShoppingCartStyleCss>
                    <div className="cart-popup-main">
                        <div className="heading_section d-flex">
                            <h1>Your Cart</h1>
                            <div className="side-cart-cross">
                                <IconButton
                                    className="close_button"
                                    onClick={() => setOpenMiniCart(false)}
                                >
                                    <Close />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                    {productData?.length > 0 ? (
                        <>
                            <div className="products_container">
                                {_.map(productData, (row) => (
                                    <div key={row?.id}>
                                        <div className="products_listing">
                                            <div className="product_image">
                                                <img src={row?.image} alt="img" />
                                            </div>
                                            <div className="product_details">
                                                <div className="product_name">
                                                    <h3>{row?.title}</h3>
                                                </div>
                                                <div className="quality-box">
                                                    {(row?.itemCode !== "PVZSAMPLE-1US") &&
                                                        <QuantityComponent {...{ row, _minCartQuantityIncDec }} />
                                                    }
                                                </div>
                                                <p className='text-[22px] ml-[60px] mt-[5px]'>{row?.frequency === "oneTime" ? '' : 'SmartShip'}</p>
                                            </div>
                                            <div className="delete_price">
                                                <div className="deleteIcon" onClick={() => _deleteProductFromCart(+row?.id)}>
                                                    <Delete />
                                                </div>
                                                <div className="price">
                                                    {getCurrencySign(CURRENT_STORE)}{
                                                        FloatValues(
                                                            ((!!(_.find(autoshipOrders, { isActive: true })) || _autoShipCheck()) ? row[section + '_autoship'] : row[section + '_' + row?.frequency]) || row?.price
                                                        )
                                                    }


                                                </div>
                                            </div>
                                        </div>
                                        <Divider className='!my-[20px] !mx-auto' />
                                    </div>
                                ))}
                            </div>
                            <Divider />

                            <div className="footer_section_mincart">
                                <div className="calculation_section">
                                    <div className='capitalize'>Price type: {(section === 'normal') ? CUSTOMER_TYPE?.customerType : section + ' Customer'}</div>
                                    <div className="subtotal_section">
                                        <p> Subtotal {subTotal?.productCount} Items</p>
                                        <p>{getCurrencySign(CURRENT_STORE)}{FloatValues(subTotal?.productSubTotal)}</p>
                                    </div>
                                    {!_.includes(['retail', 'preferred', 'IBO'], section) ?
                                        <Link to={!isUserLogin ? `/${storeAssigned()}/${ROUTING_TEXT?.retailSignup_routingName}` : `/${storeAssigned()}/${ROUTING_TEXT?.checkout_routingName}`}
                                            className="py-[10px] px-[30px] bg-button-color text-white rounded-3xl uppercase"
                                            onClick={() => setOpenMiniCart(false)}
                                        >
                                            Checkout
                                        </Link>
                                        :
                                        <button className="py-[10px] px-[30px] bg-button-color text-white rounded-3xl capitalize"
                                            onClick={() => {
                                                setOpenMiniCart(false);
                                                setActiveStep((prevStep) => prevStep + 1)
                                            }}
                                        >
                                            Next
                                        </button>
                                    }
                                </div>
                                {/* <Divider /> */}
                            </div>
                        </>
                    ) : (
                        <p style={{ textAlign: "center" }}>Cart is Empty</p>
                    )}
                </ShoppingCartStyleCss>
            </Drawer>
        </>
    )
}

ShoppingCartComponent.defaultProps = {
    openMiniCart: false,
    setOpenMiniCart: (val) => alert(`you miss to pass setOpenMiniCart with value ${val}`),
    productData: [],
    section: 'Gsn',
    _minCartQuantityIncDec: (val) => alert(`you miss props _minCartQuantityIncDec with value ${val}`),
    _deleteProductFromCart: (val) => alert(`you miss props  _deleteProductFromCart with value ${val}`),
    _autoShipCheck: (val) => alert(`you miss props _autoShipCheck with value ${val}`)
}

export default ShoppingCartComponent;