import { ChevronLeftOutlined, ChevronRightOutlined } from "@mui/icons-material";
import Slider from "react-slick";

const CustomNextArrow = (props) => (
  <div className={props.className} style={props.style} onClick={props.onClick}>
    <ChevronRightOutlined />
  </div>
);

const CustomPrevArrow = (props) => (
  <div className={props.className} style={props.style} onClick={props.onClick}>
    <ChevronLeftOutlined />
  </div>
);

const SliderComponent = ({ children, ...props }) => {

  const sliderSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: false,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    className: props?.classFor || 'normal_slider_class',
  };
  return (
    <>
      <Slider {...sliderSettings}>
        {children}
      </Slider>
    </>
  )
}

export default SliderComponent;
