import { useNavigate } from "react-router-dom";

const ButtonComponent = ({ children, type, onClick, link, classes }) => {
  const navigate = useNavigate();

  return (
    <button
      type={type}
      className={`xl:mb-4 lg:mb-3 md:mb-2 sm:mb-1.5 mb-3 text-white f-q-regular font-medium text-[16px] rounded-full hover:bg-opacity-90 transition-all duration-200 py-[10px] px-[20px] border-2 bg-button-color border-button-color 
       text-white hover:bg-white hover:text-button-color ${classes} `}

      {...(type !== "submit") ? {
        onClick: () => {
          if (link) {
            navigate(link);
          }
          if (onClick) {
            onClick()
          }
        }
      } : {}}
    >
      {children}
    </button>
  );
};

ButtonComponent.defaultProps = {
  type: "button",
  children: "Button"
}
export default ButtonComponent;
