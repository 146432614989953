const FOOTER_TEXTS = {
  shipping_policy: "Shipping Policy",
  shipping_policy_content: [
    "Thank you for visiting and shopping at www.provizion.com. Included are the terms and conditions that constitute our Shipping Policy.",
  ],
  shipping_time: "Shipment processing time",
  shipping_time_content: [
    "All orders are processed within 2-3 business days. Orders are not shipped or delivered on weekends or holidays.",
    "If we are experiencing a high volume of orders, shipments may be delayed by a few days. Please allow additional days in transit for delivery. If there will be a significant delay in shipment of your order, we will contact you via email or telephone.",
  ],
  shipping_delivery_estimate: "Shipping rates & delivery estimates",
  shipping_delivery_estimate_content:
    "Shipping charges for your order will be calculated and displayed at checkout. Delivery delays can occasionally occur.",
  shipping_return: "Return Policy",
  shipping_return_content:
    "Our Returns Policy provides detailed information about procedures for returning your order.",
  damage: " Damages",
  damage_content: [

    " Provizion Global is not liable for any products damaged or lost during shipping. If you received your order damaged, please contact the shipment carrier to file a claim.",
    " Please save all packaging materials and damaged goods before filing a claim. ",
  ],
  income_disclosure: "Income Disclosure ",
  income_content: [
    " The Provizion Compensation Plan is an exciting opportunity that rewards you for selling products and services and sponsoring other participants who do the same. Although the opportunity is unlimited, individual results will vary depending on commitment levels and sales skills of each participant. Since Provizion has launched, it lacks enough statistical data to prepare reliable income disclosures. There will be certain participants who will earn less while others will earn much more. We’re excited about the Provizion Compensation Plan and we’re confident it will provide you a solid foundation to help you achieve your financial goals. As with all endeavors, hard work and the time you dedicate impacts outcomes. ",
    " If income projections were presented to you prior to your enrollment, such projections are not necessarily representative of the income, if any, that you can earn through your participation in the Compensation Plan. These income projections should not be considered as guarantees or projections of your actual earnings or profits. Success with Provizion results only form hard work, dedication, and leadership."
  ],

  refund_policy: " Refund Policy",
  refund_policy_content: [
    "You may request a refund of any amounts charged by contacting customer service at support@provizion.com. If for any reason you are not completely satisfied with any Provizion Global products purchased directly from Provizion Global via our website, you may request a refund within 30 days from the date of purchase for a full refund or exchange. Refunds and exchanges will be made to the credit card with which the product was purchased. You will need the following to make your request for refund:",

    "Your refund includes only the cost of the product. It does not include the costs of shipping. "],
  return_policy: "Return Policy ",
  return_policy_content: " Before any product is returned to Provizion Global, the IBO or customer must contact customer support to obtain a Return Merchandise Authorization (“RMA”) number. Any package received without this information on the outside of the box may be refused, and may result in a refund not issuing. ",
  smartship: " SmartShip Cancellation ",
  smartship_content: " Please email support to cancel or modify your SmartShip at any time at support@provizion.com, without penalty. You can also modify or cancel your order at any time at www.provizion.com . By selecting the SmartShip option on the website, you are giving Provizion Global authorization to enroll you in the automatic shipping program. Provizion Global will ship your products directly to you. You are also authorizing Provizion Global to charge your credit card for the products you have ordered on a monthly basis. You may cancel at any time without obligation and without penalty by emailing support@provizion.com or canceling the order on the provizion.com portal. All SmartShip cancellations must be performed or delivered to Provizion Global within 3 business days of the next shipment to guarantee cancellation of that shipment. ",
  ibo_refund: "IBO’s Refund Policy",
  ibo_content: " Please reference Provizion Global’s Policies and Procedures for the Refund Policy regarding distributors.",


  terms_and_conditions: "Terms And Conditions",
  terms_and_conditions_content: "The use of this application and/or site or any other site owned or maintained by PROVIZION GLOBAL, LLC a limited liability company organized and existing under the laws of Texas (“Provizion Global”), is governed by the policies, terms and conditions set forth below. Please read them carefully. Your use of this site signifies your acceptance of the terms and conditions set forth below. Your order placed on this site signifies your acceptance of the terms and conditions set forth below.",
  terms_security_disclosure: "Privacy & Security Disclosure",
  terms_security_disclosure_content: "Provision Globals privacy policy may be viewed at www.provizion.com. Provizion Global reserves the right to modify its privacy policy in its reasonable discretion from time to time.",
  terms_shipping_policy: " Shipping Policy ",
  terms_shipping_policy_content: " All orders are processed within 2-3 business days. Orders are not shipped or delivered on weekends or holidays. If we are experiencing a high volume of orders, shipments may be delayed by a few days. Please allow additional days in transit for delivery. If there will be a significant delay in shipment of your order, we will contact you via email or telephone.",
  terms_shipping_rates: " Shipping rates & delivery estimates",
  terms_shipping_rates_content: "Shipping charges for your order will be calculated and displayed at checkout. Delivery delays can occasionally occur.",
  terms_third_party: " Third Party Interactions",
  terms_third_party_content: " During use of the Provizion Global Website, you may enter into correspondence with, purchase goods and/or services from, or participate in promotions of advertisers or sponsors showing their goods and/or services through the Website. Any such activity, and any terms, conditions, warranties or representations associated with such activity, is solely between you and the applicable third-party. Provizion Global shall have no liability, obligation or responsibility for any such correspondence, purchase or promotion between you and any such third party. Provizion Global does not endorse any sites on the Internet that are linked through its Website. Provizion Global provides these links to you only as a matter of convenience, and in no event shall Provizion Global be responsible for any content, products, or other materials on or available from such sites. Provizion Global provides products to you pursuant to the terms and conditions of this Agreement. You recognize, however, that certain third-party providers of ancillary software, hardware or services may require your agreement to additional or different license or other terms prior to your use of or access to such software, hardware or services.",
  terms_order_disclaimer: " Order Disclaimer",
  terms_order_disclaimer_content: " Your electronic order confirmation, or any form of confirmation, does not signify our acceptance of your order. Provizion Global reserves the right to accept or deny shipment to anyone for any reason. Provizion Global reserves the right to require additional information before processing any order. If an order appears fraudulent in any way, Provizion Global reserves the right to cancel the order, notify the card holder and the proper authorities.",
  terms_ftc: " FTC Disclosures",
  terms_ftc_content: " As a participant in the Provizion Global program, all IBO’s will be required to acknowledge the FTC disclosure for participation in the program.",
  terms_warantty: "  Product Disclaimers / Disclaimers of Warranty",
  terms_warantty_content: "PROVIZION GLOBAL MAKES NO REPRESENTATION, WARRANTY, OR GUARANTY AS TO THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, TRUTH, AVAILABILITY, ACCURACY OR COMPLETENESS OF ANY INFORMATION ON THIS SITE. PROVIZION GLOBAL DOES NOT REPRESENT OR WARRANT THAT (A) THE USE OF THE SITE WILL BE SECURE, TIMELY, UNINTERRUPTED OR ERROR-FREE OR OPERATE IN COMBINATION WITH ANY OTHER HARDWARE, SOFTWARE, SYSTEM OR DATA, (B) THE SITE OR PROVIZION GLOBAL'S PRODUCTS WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, (C) ANY STORED DATA WILL BE ACCURATE OR RELIABLE, (D) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, (E) ERRORS OR DEFECTS WILL BE CORRECTED, OR (F) THE SERVICE OR THE SERVER(S) THAT MAKE THE SERVICE AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THE SERVICE AND ALL CONTENT IS PROVIDED TO YOU STRICTLY ON AN “AS IS” BASIS. ALL CONDITIONS, REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT OF THIRDPARTY RIGHTS, ARE HEREBY DISCLAIMED TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW BY PROVIZION GLOBAL.",
  terms_limitation: " Limitation of Liability ",
  terms_limitation_content: "IN NO EVENT SHALL EITHER PARTY'S AGGREGATE LIABILITY EXCEED THE AMOUNTS ACTUALLY PAID BY AND/OR DUE FROM YOU IN THE 3-MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO SUCH CLAIM. IN NO EVENT SHALL EITHER PARTY BE LIABLE TO ANYONE FOR ANY INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES OF ANY TYPE OR KIND (INCLUDING LOSS OF DATA, REVENUE, PROFITS, USE OR OTHER ECONOMIC ADVANTAGE) ARISING OUT OF, OR IN ANY WAY CONNECTED WITH THIS SITE, INCLUDING BUT NOT LIMITED TO THE USE OR INABILITY TO USE THE SITE, OR FOR ANY CONTENT OBTAINED FROM O- THROUGH THE SITE, ANY INTERRUPTION, INACCURACY, ERROR OR OMISSION, REGARDLESS OF CAUSE IN ANY INFORMATION CONTAINED HEREIN, EVEN IF THE PARTY FROM WHICH DAMAGES ARE BEING SOUGHT HAVE BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. Certain states and/or jurisdictions do not allow the exclusion of implied warranties or limitation of liability for incidental, consequential or certain other types of damages, so the exclusions set forth above may not apply to you. ",
  terms_notice: " Notice ",
  terms_notice_content: "Provizion Global may give notice by means of a general notice on the www.provizion.com website, electronic mail to your e-mail address on record in Provizion Global's account information, or by written communication sent by first class mail or pre-paid post to your address on record in Provizion Global's account information. Such notice shall be deemed to have been given upon the expiration of 48 hours after mailing or posting (if sent by first class mail or pre-paid post) or 24 hours after sending (if sent by e-mail). You may give notice to Provizion Global (such notice shall be deemed given when received by Provizion Global) at any time by letter delivered by nationally recognized overnight delivery service or first class postage prepaid mail to Provizion Global at the following address: 901 Sam Rayburn HWY, Melissa, Texas 75454 in either case, addressed to the attention of: Legal Department. ",
  terms_modification: " Modification to Terms",
  terms_modification_content: " Provizion Global reserves the right to modify the terms and conditions of this Agreement or its policies relating to its products and services at any time, effective upon posting of an updated version of this Agreement on the www.provizion.com website. You are responsible for regularly reviewing this Agreement. Continued use of the Service after any such changes shall constitute your consent to such changes. ",
  terms_general: " General",
  terms_general_content: " This Agreement shall be governed by Texas law and controlling United States federal law, without regard to the choice or conflicts of law provisions of any jurisdiction, and any disputes, actions, claims or causes of action arising out of or in connection with this Agreement or the Service shall be subject to the exclusive jurisdiction of the state and federal courts located in Texas. If any provision of this Agreement is held by a court of competent jurisdiction to be invalid or unenforceable, then such provision(s) shall be construed, as nearly as possible, to reflect the intentions of the invalid or unenforceable provision(s), with all other provisions remaining in full force and effect. No joint venture, partnership, employment, or agency relationship exists between you and Provizion Global as a result of this agreement or use of this Website. The failure of Provizion Global to enforce any right or provision in this Agreement shall not constitute a waiver of such right or provision unless acknowledged and agreed to by Provizion Global in writing. This Agreement, together with any applicable Form and policies, comprises the entire agreement between you and Provizion Global and supersedes all prior or contemporaneous negotiations, discussions or agreements, whether written or oral, between the parties regarding the subject matter contained herein. ",
  terms_definition: " Definitions",
  terms_definition_content: " As used in this Agreement and in any Order Forms now or hereafter associated herewith: “Agreement” means these online terms of use, any Order Forms, whether written or submitted online via the www.provizion.com Web Site, and any materials available on the Provizion Global Website specifically incorporated by reference herein, as such materials, including the terms of this Agreement, may be updated by Provizion Global from time to time in its sole discretion; “Effective Date” means the earlier of either the date this Agreement is accepted by selecting the “I Accept” option presented on the screen after this Agreement is displayed, the Effective date on the subscription form or the date you begin purchasing products from this site; “Order Form(s)” means the form evidencing your purchase from this site and any subsequent order forms submitted online or in written form, each such Order Form to be incorporated into and to become a part of this Agreement (in the event of any conflict between the terms of this Agreement and the terms of any such Order Form, the terms of this Agreement shall prevail); “Provizion Global” means collectively Provizion Global organized and existing under the laws of the State of Texas doing business as “provizion.com” and having an office at, 901 Sam Rayburn Hwy, Melissa, TX,75454, together with its officers, directors, shareholders, employees, agents and affiliated companies. ",
  terms_aditional_info: " Questions or Additional Information",
  terms_aditional_info_content: " If you have questions regarding this information or wish to obtain additional information, please send an e-mail to support@provizion.com.",


  privacy_policy: " Privacy Policy ",
  privacy_policy_content: " (“Provizion Global”, “we” or “us”) respects the privacy of our users (“User” or “You”). This Privacy Policy explains how Provizion Global collects, uses, and discloses, and safeguards Your Information when you visit the Provizion Global website www.provizion.com, including any other media form, media channel, mobile website or mobile application related or connected thereto (collectively “Site” or “Sites”). Please read this Privacy Policy carefully. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THIS SITE. We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will alert you about any changes by updating the “Revised” date of this Privacy Policy. Any changes or modifications will be effective immediately upon posting the updated Privacy Policy on the Site, and you waive the right to receive specific notice of each such change or modification. You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued used of the Site after the date such revised Privacy Policy is posted ) ",

  collection_information: " Collection of Your Information.",
  collection_information_content: [
    "We may collect information about you in a variety of ways. The information we collect on the Site includes: ",
    " Personal Data ",
    "Personally identifiable information, such as your name, shipping address, email address, and telephone number, demographic information such as date of birth, gender, location at time of use, and other information that you voluntarily give to use when you register with the Site or when you choose to participate in various activities related to the Site, such as online chat and message boards. You are under no obligation to provide us with personal information of any kind; however, your refusal to do so may prevent you from using certain Site features. ",
    "Derivative Data",
    "Information our servers automatically collect when you access the Site, such as your IP address, your location at the time of access, your browser type, your operating system, your access times, and the page you have review directly before and after accessing the Site.",
    "Financial Data",
    "Financial Information, such as data related to your payment method (i.e. valid credit card number, card brand, expiration date, billing zip code) that we may collect when you purchase, order, return, exchange or request information about our products or services from the Site. We store only very limited, if any, financial information we collect. Credit card data is tokenized and held by a certified third party. Otherwise, all financial information is stored by our payment processors.",
    "Facebook Permissions.",
    "This Site may by default access your Facebook basic account information, including your name, email, gender, birthday, current city, profile picture, URL, as well as other information that you choose to make public. We may also request access to other permissions related to your account, such as friends, check-ins, and likes. You may choose to grant or deny us access to each of these permissions. For more information about open authorization and Facebook permissions refer to the Facebook Permissions Reference Page.",
    "Data from Contests and Giveaways",
    "Personal and other information you may provide when entering contests or giveaways and/or responding to surveys. ",
  ],

  use_information: " Use of Your Information",
  use_information_content: [
    "Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site to",
    "Assist law enforcement and respond to subpoenas",
    "Compile anonymous statistical data and analysis for use internally or with third parties",
    "Create and manage your account",
    "Deliver targeted advertising, coupons newsletters, and promotions, and other information regarding our website and mobile application to you",
    "Email you regarding your account or order",
    "Fulfill and manage purchases, orders, payments, and other transactions related to the Site",
    "Monitor and analyze usage and trends to improve your experience with the Site",
    "Notify you of updates to the ",
    "Offer new products, services, and/or recommendations to you",
    "Perform other business activities as needed",
    "Prevent fraudulent transactions, monitor against theft, and protect against criminal activity",
    "Process payment and refunds",
    "Resolve disputes and troubleshoot problems",
    "Respond to product and customer service requests",
    "Send you a newsletter or announcement",
  ],

  disclosure_information: "Disclosure of Your Information",
  disclosure_information_content: [
    "We may share information we have collected about you on certain situations. Your information may be disclosed as follows",
    "By Law or to Protect Rights",
    "If we believe the release of information about you is necessary to respond to a legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation. This includes exchanging information with other entities for fraud protection and credit risk reduction",
    "Third Party Service Providers",
    "We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email deliver, hosting services, customer service, and marketing assistance",
    "Other Third Parties",
    "We may share your information with advertisers and investors for the purposes of conducting general business analysis. We may also share your information with such third parties for marketing purposes, as permitted by law.",
    "Sale or Bankruptcy",
    "If we reorganize or sell allor a portion of our assets, undergo a merger, or are acquired by another entity, we may transfer your information to the successor entity. If we go out of business or enter bankruptcy, your information would be an asset transferred or acquired by a third party. You acknowledge that such transfers may occur and that the transferee may decline to honor commitments we made in this Privacy Policy."
  ],

  tracking_technology: "Tracking Technologies ",
  tracking_technology_content: [
    "Cookies and Web Beacons",
    "We may use cookies, web beacons, tracking pixels, and other technologies on the Site to help customize the Site and improve your experience. For more information on how to use cookies, please refer to our Cookie Policy.",
    "Website Analytics.",
    "We may also partner with selected third-party vendors, such as Google Analytics, to allow tracking technologies and remarketing services on the Site through the use of first party cooked and third-party cookies, to allow us to analyze and track users’ use of the Site, determine the popularity of certain content and better understand online activity. By Accessing this Site, you consent to the collection and use of this information by these third-party vendors. You are encouraged to review their privacy policy and contact them with any questions. We do not transfer personal information to these third-party vendors. For more information about opting out of interest-based advertising visit optout.networkadvertising.org.",
    "Controls for Do-No-Track Features",
    "Most web browsers and some mobile operating systems include a Do-Not-Track feature or setting you can activate to signal your desire for privacy and to not have your online browsing activity monitored and collected. If you set the Do-Not-Track signal on your browser, we will honor such signals.",
    "Third-Party Websites",
    "The Site may contain links to third-party websites and applications of interest, including advertisements and external services, that are not affiliated with us. Once you have used these links to leave the Site, any information you provide to these third parties is not covered by this Privacy Policy, and we cannot guarantee the safety and privacy of your information. Before visiting and providing information to any third-party websites, you should inform yourself of the privacy policies and practices of the third party responsible for that website. We are not responsible for the contact or privacy and security practices and policies of any third parties, including other sites, services or applications that may be linked from the Site. ",
  ],

  security_information: "Security of Your Information ",
  security_information_content: "We use administrative, technical and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide us, please be aware that despite our efforts, no security measures are perfect or impenetrable. No method of data transmission can be guaranteed against interception and other types of mis-sue. Although we try to keep the information you trust us with secure, we cannot guarantee complete security of your information. ",
  children: " Children ",
  children_content: " We do not knowingly solicit information from or market to children under the age of 13. If you become aware of any data, we have collected from children under the age of 13, please notify us.",

  option_regarding: "Options Regarding Your Information ",
  option_regarding_content: [
    "Account Information.",
    "You may review or change the information in your account, or terminate your account by emailing customer support. Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with investigations, and enforce our Terms of Use.",
    "Emails and Communications",
    "If you no longer wish to receive correspondence, emails or other communications from us you may opt-out by: (1) Noting your preferences at the time you register your account with us, or (2) contacting our support department. "
  ],

  california_privacy: " California Privacy Rights ",
  california_privacy_content: " California Civil Section 1798.83, permits California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the name and addresses of all third parties for direct marketing purposes in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to our support department. Please be aware that although publicly facing data may be removed, the data may not be completely or comprehensively removed from our systems.  If you are under 18 years old and reside in California, and have a registered account with this Site, you have the right to request removal of unwanted data that you publicly post on the Site. To request removal of such data, please contact the support department. ",
  for_question: " For Questions",
  for_question_content: ["Contact us at: ",
    "    Provizion Global",
    "LLC    901 Sam Rayburn Highway   Melissa",
    "TX 75454 support@provizion.com "],

};

export default FOOTER_TEXTS; 