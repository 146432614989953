import _ from 'lodash';
import rick from '../assets/rick.jpg';
import george from '../assets/george-new.png';

const Leadership = ({ ABOUT_TEXTS }) => {
    return (
        <div className="leadership-wrap py-9 my-9">
            <div className='leadership text-center'>
                <h2 className='text-[32px] font-extrabold text-heading-color mb-9 leading-[1.2]'>
                    {ABOUT_TEXTS?.leadership}
                </h2>
            </div>
            <div className="flex flex-wrap px-4">
                {_.map([

                    { image: rick, name: ABOUT_TEXTS?.global_ceo_name, designation: ABOUT_TEXTS?.global_ceo_text, discription: ABOUT_TEXTS?.global_ceo_discription },
                    { image: george, name: ABOUT_TEXTS?.global_president_name, designation: ABOUT_TEXTS?.global_president_text, discription: ABOUT_TEXTS?.global_president_discription }

                ], ({ image, discription, designation, name }, index) => (
                    <div className="px-4 md:w-1/2 w-full mb-6" key={index + 1}>
                        <div className="flex flex-wrap items-start rounded-[6px] border-[1px] border-[rgba(0,0,0,0.085)]">
                            <figure className='lg:w-2/6 w-full rounded-[6px] overflow-hidden'>
                                <img className='w-full h-full object-cover' src={image} alt="Image" />
                            </figure>
                            <div className='lg:w-4/6 w-full p-5'>
                                <h2 className="text-xl leading-5 font-semibold">
                                    {name}
                                    <small className='text-content-color block text-xs leading-[1.5] mt-2 font-normal'>{designation}</small>
                                </h2>
                                <p className="text-dark-content text-sm leading-[21px]">{discription}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Leadership;