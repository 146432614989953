import React from 'react';
import { RegionDropdown, CountryDropdown } from "react-country-region-selector";
import { CountryAndStatePropsType } from '../services/Methods/PropsTypesInterface';
import _ from 'lodash';
import { storeAssigned } from '../services/Methods/normalMethods';

const CountryAndStateInputs = ({ countryCodes, setCountryState, countryState, onChange }) => {
    return (
        <>
            <div className='country_state_main flex flex-wrap px-[10px] md:w-1/2 w-full'>
                <div className={`select ${countryState?.countryError && "error_wrap"} hide-country w-full mb-[30px]`}>
                    {(countryCodes?.length > 0) &&
                        <CountryDropdown
                            value={countryState?.country}
                            priorityOptions={['US', 'CA']}
                            name="country"
                            valueType="short"
                            whitelist={_.filter(countryCodes, (r) => (r === _.upperCase(storeAssigned())))}
                            onChange={(val) => {
                                onChange(val);
                                if (!val) {
                                    setCountryState((prv) => ({ ...prv, country: val, countryError: "Please select the country" }));
                                }
                                else {
                                    setCountryState((prv) => ({ ...prv, country: val, countryError: "" }));
                                }
                            }} />
                    }
                    {countryState?.countryError && <p className="error Mui-error">{countryState?.countryError}</p>}
                </div>
            </div>
            <div className='country_state_main flex flex-wrap px-[10px] md:w-1/2 w-full'>
                <div className={`select ${countryState?.stateError && "error_wrap"} w-full mb-[30px]`}>
                    <RegionDropdown
                        country={countryState?.country || "US"}
                        disabled={!countryState?.country}
                        value={countryState?.state}
                        name="state"
                        valueType="short"
                        defaultOptionLabel={countryState?.country === 'CA' ? 'Province' : "State"}
                        countryValueType="short"
                        onChange={(val) => {
                            onChange(val, 'state');
                            if (!val) {
                                setCountryState((prv) => ({ ...prv, state: val, stateError: "Please select the state" }));
                            }
                            else {
                                setCountryState((prv) => ({ ...prv, state: val, stateError: "" }));
                            }
                        }}
                    />
                    {countryState?.stateError && <p className="error Mui-error">{countryState?.stateError}</p>}
                </div>
            </div>
        </>
    )
}

CountryAndStateInputs.propTypes = CountryAndStatePropsType;
CountryAndStateInputs.defaultProps = {
    countryCodes: ['US', 'CA'],
    setCountryState: (value) => console.log('setCountryState', value),
    countryState: {},
    onChange: (data, from) => console.log(data, from)
}

export default CountryAndStateInputs;