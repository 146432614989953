import styled from "styled-components";

const AutoshipAddressEditStyle = styled.div`
.autoshipAddressEdit_main {
    .row-form {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        gap: 15px;
        margin-bottom: 15px;
        .md-3-field {
            max-width: 50%;
            flex: 0 0 50%;

            .MuiTextField-root {
                width: 100%;

                input  {
                    height: 15px;
                    border: 1px solid rgb(221, 221, 221) !important;
                }
            }
        }
        .select {
            max-width: 50%;
            flex: 0 0 50%;

            >select {
                width: 100%;
                border: 1px solid rgb(221, 221, 221);
                height: 50px;
                padding: 0px 8px;
                color: rgb(120, 113, 113);
            }
        }
    }
    
}

`;

export default AutoshipAddressEditStyle;