const ABOUT_TEXTS = {
    provision_mission: "Provizion Mission",
    provision_globel_para: " At Provizion Global, we have a singular product focus on supporting your body's Glutathione (GSH) and Master Antioxidant systems. This is our mission. Provizion GSH is a high-performance supplement that is a proprietary blend of 22 complimentary ingredients that empowers your body to protect and repair itself at the cellular level.",
    pro_heart: "ProHeart",
    compassionate_creator: "We are those who believe in a compassionate creator who knows, cares and has given us everything we need for a wonderful bountiful life. At Provizion Global we want to be of one heart, of one mind, and we want to deal with each other equitably. Equitably meaning we want to do what's right by each other and ensure there's a pathway for the weakest among us to succeed. Whether that's physical weakness or financial weakness, there is a place for you to succeed with us at Provizion Global. So we have architected our product, our pricing, and our profit model to empower all of us together.",
    pro_vision: "ProVision",
    our_vision: "Our vision is to Provizion One million people to get the toxins out by putting our GSH micro-nutrients in. Just like the breath of life principle, every day we breathe in what we need and exhale what we don't. Simply by living our daily lives we are exposed to toxins. They're in the food that we eat, the air that we breathe, the water we drink, the sun's UV rays beating down on us, it's everywhere! But the good news is that we have intelligent design coded inside of us. We have a built-in endogenous antioxidant system. And if we eat the right things and make the right lifestyle choices, our bodies are powerful against these free radicals that would try to take over our bodies and impact our health. When our bodies are empowered we fight this oxidative stress naturally, and our bodies remain healthy, robust and strong.",

    global_ceo_name: "Rick S. Nelson",
    global_ceo_text: "Provizion Global CEO",
    global_ceo_discription: `
    Rick is a direct selling enthusiast and an international business development expert in a career spanning more than 25 years operating in major markets around the world. His experience includes 12 years residing and working in South Korea and recent projects in the People's Republic of China, both top revenue markets in direct selling. Rick gained valuable experience in executive roles at a New York Stock Exchange listed, multi-billion dollar direct selling company as they innovated in the industry and successfully expanded throughout the U.S., Canada, and profitably into more than 50 countries. Rick implements and adheres to proven principles and practices that create long term business opportunities for valued Provizion Global field leaders. 
    Rick earned a Bachelor's Degree in International Economics from the University of Utah and speaks fluent Korean.
    `,

    global_president_name: "George G. Reninger",
    global_president_text: "Provizion Global President",
    global_president_discription: `
    George approaches business with a "higher purpose" orientation and integrated history in the areas of Marketing, Sales, and IT Management having served in executive, consulting and technical positions for various fortune 500 companies including IBM, AT&T, the Federal Reserve Bank, and New York Life. George has traveled to more than 45 countries internationally, developing highly profitable distribution networks for Direct Selling companies, aiding in the design and promotion of evidence-based nutritional technologies, educating entrepreneurs, medical professionals, and government agencies on breakthroughs in human health and economic development. 
    George graduated from SUNY College at Buffalo earning his Bachelor’s Degree with honors in Information Systems.
    `,
};

export default ABOUT_TEXTS;