import React from 'react';
import './business.css';
import JoinSection from '../assets/join-section.jpg'
import ExploreDevider from '../assets/explore-devider.jpg'
import SliderComponent from '../../../Inputs/SliderComponent.inputs';
import _ from 'lodash';
import ExploreCommonBanner from '../../../Common/Components/ExploreCommonBanner';
import MicroBusiness from './MicroBusiness';
import ExploreBusinessFaq from './ExploreBusinessFaq';
import BlueWhiteTwoOption from '../../../Common/Components/BlueWhiteTwoOption';
import { storeAssigned } from '../../../services/Methods/normalMethods';
import ROUTING_TEXT from '../../../TextConfigs/RoutingAndSmallContent.text';
import { useNavigate } from 'react-router-dom';
import VideoPlayer from '../../../Common/Components/VideoPlayer';

const ExploreBusiness = ({ EXPLORE_TEXTS }) => {
    const navigate = useNavigate();
    const exploreBusinessVideoLink = 'https://player.vimeo.com/video/738306603?autoplay=1&loop=1&autopause=0&muted=1&controls=true';
    return (
        <>
            <ExploreCommonBanner TEXTS={EXPLORE_TEXTS} pageText={EXPLORE_TEXTS?.business_text} title={EXPLORE_TEXTS?.provizion_globel_text} />
            <section className="pt-[60px]">
                <div className="container mx-auto max-w-[1260px] px-[15px] flex flex-wrap">
                    <div className="relative pb-[56.5%] w-full">
                        <div className="absolute top-0 left-0 w-full h-full">
                            <VideoPlayer url={exploreBusinessVideoLink} style={{ width: '100%', height: '100%', right: 0, }} />
                        </div>
                    </div>
                </div>
            </section>
            <section className="business-slider-section pt-[3.75rem]">
                <div className="container mx-auto max-w-[1260px] px-[15px]">
                    <div className="business-heading text-center">
                        <h2 className='text-heading-color lg:text-[48px] text-[43px] font-extrabold leading-[1] mb-[60px]'>{EXPLORE_TEXTS?.business_heading}</h2>
                    </div>
                    <div className="business-slider-wrap mb-5">
                        <SliderComponent>
                            {_.map(EXPLORE_TEXTS?.slider_comments, (row, index) => (
                                <div className="business-slider- sm:px-[50px] px-[20px]" key={"slide_" + index}>
                                    <div className="business-comment max-w-[930px] mx-auto ">
                                        {_.map(row?.bussiness_comment, (text, index_two) => (
                                            <p key={"message_" + (index_two + 1)} className='text-[15px] font-light leading-[1.5] text-[#333] mb-4'>
                                                {text}
                                            </p>
                                        ))}
                                    </div>
                                    <div className="comment-name max-w-[930px] mx-auto ">
                                        <p className='text-[15px] font-light leading-[1.5] text-[#333] mb-4 text-center'>{row?.comment_name}</p>
                                    </div>
                                </div>
                            ))}
                        </SliderComponent>
                    </div>
                </div>
            </section>

            <section className="start-business bg-cover bg-center bg-no-repeat pt-[145px] relative pb-[170px]" style={{ backgroundImage: `url(${JoinSection})` }}>
                <div className="start-business-overlay absolute top-0 left-0 w-full h-full z-10 opacity-80 bg-[#383737]"></div>
                <div className="container mx-auto max-w-[1260px] px-[15px] relative z-20">
                    <div className="start-business-content text-center max-w-[980px] mx-auto">
                        <h2 className='lg:text-[56px] text-[49px] text-white leading-[1] font-extrabold mb-6'>
                            {EXPLORE_TEXTS?.start_business_heading}
                        </h2>
                        <p className='text-[24px] text-white leading-[1.2] font-normal mb-9'>{EXPLORE_TEXTS?.start_business_para}</p>
                        <button onClick={() => navigate(`/${storeAssigned()}/${ROUTING_TEXT?.signup_routingName}`)} className='inline-block mt-6 py-[19px] px-[45px] rounded-[30px] bg-[#DEAA17] text-[19px]  text-white leading-[1.5] font-normal transition-all duration-300 ease-in-out hover:text-black hover:bg-white'>
                            {_.upperCase(EXPLORE_TEXTS?.register_now)}
                            <span className='ml-[7px]'><i className="fa-solid fa-arrow-right"></i></span>
                        </button>
                    </div>
                </div>
            </section>

            <section className="bg-cover bg-no-repeat py-[150px] relative" style={{ backgroundImage: `url(${ExploreDevider})` }}>
                <div className="absolute top-0 left-0 w-full h-full z-10 opacity-40 bg-[#E6E6E6]"></div>
                <div className="container mx-auto max-w-[1260px] px-[15px] relative z-20">
                    <div className="text-center">
                        <h2 className='text-[48px] text-heading-color leading-[1.46] font-extrabold mb-6'>
                            <span className='font-light'>WHY </span>
                            {EXPLORE_TEXTS?.provizion_globel_text}?
                        </h2>
                        <p className='text-[26px] text-dark-content leading-[1.5] font-normal mb-4'>{EXPLORE_TEXTS?.why_provizion_para}</p>
                    </div>
                </div>
            </section>
            <MicroBusiness {...{ EXPLORE_TEXTS }} />
            <BlueWhiteTwoOption data={[
                { heading: EXPLORE_TEXTS?.get_provizion_now_text, para: EXPLORE_TEXTS?.get_provizion_now_para, link: `/${storeAssigned()}/${ROUTING_TEXT?.signup_routingName}` },
                { heading: EXPLORE_TEXTS?.start_bussiness_now_text, para: EXPLORE_TEXTS?.start_bussiness_now_para, link: `/${storeAssigned()}/${ROUTING_TEXT?.signup_routingName}` }
            ]} TEXT={EXPLORE_TEXTS} />
            <ExploreBusinessFaq {...{ EXPLORE_TEXTS }} />
        </>
    );
}

export default ExploreBusiness;
