import styled from "styled-components";

const AutoshipProductsAddStyle = styled.div`
 .product_listing {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;

    .product_section {
        /* border: 1px solid; */
        max-width: 360px;
        text-align: center;

        .product_image {
            width: 100%;
            img {
                width:100%;
            }
        }

        .select_option {
             a {
                margin: 0 auto;
                color: #fff;
                height: 45px;
             }
        }
    }
 }

`;

export default AutoshipProductsAddStyle;