import React from "react";
import BusinessImage from '../assets/business.jpg';


const ExploreCommonBanner = ({ TEXTS, title, pageText }) => {

    return (
        <section className="explore-banner bg-cover bg-center bg-no-repeat min-h-[500px] relative flex items-center" 
        style={{ backgroundImage: `url(${BusinessImage})` }}
        > 
            <div className="banner-overlay absolute top-0 left-0 w-full h-full opacity-80 z-10" style={{ backgroundImage: "linear-gradient(130deg, #0700dd 0%, #00f2ff 88%)" }}></div>
            <div className="container mx-auto px-[15px] max-w-[1260px] z-30 relative">
                <div className="explore-content-wrap text-center">
                    <h4 className='text-2xl leading-[1.2] font-extrabold text-white mb-3'>
                        <small className='text-[100%] font-light'>{TEXTS?.learn_more} {TEXTS?.about} {TEXTS?.provizion_globel_textl}</small>
                        {title}
                    </h4>
                    <h1 className='lg:text-[72px] text-[61px] leading-[1] mb-3 text-white font-extrabold'>
                        <small className="text-[100%] font-light">{TEXTS?.explore_text} </small> {pageText}</h1>
                </div>
            </div>
            <div className="banner-shape absolute bottom-0 left-0 z-20 h-[80px] w-full rotate-180 overflow-hidden"><svg className="w-[380%] rotate-y h-[120px] relative block left-1/2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none"><path className="elementor-shape-fill fill-white" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7 c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4 c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path></svg></div>
        </section>
    )
}
export default ExploreCommonBanner;
