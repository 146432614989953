import { DialogActions } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import ButtonComponent from '../../Inputs/ButtonComponent';
import DialogComponent from '../../Inputs/DailogComponent';
import { _getCusotmerPriceType } from '../../services/Redux/Reducer/CustomerSlice';
import { getAllCustomerData } from '../../services/Redux/Reducer/LoginSlice';
import localStorageCall from '../Methods/localstorage.hook';
import { getCurrencyCode, storeAssigned } from '../Methods/normalMethods';

function DATES_VALUES(data, e = false, s = false, d = false) {
    return ({
        entryDate: e ? data?.entryDate : moment.utc().format(),
        signUpDate: s ? data?.signUpDate : moment.utc().format(),
        distributorDate: d ? data?.distributorDate : moment.utc().format(),
    });
}

export const _signUpPayload = (data, customerType = 1, section, commingFrom) => {
    let DATES = {};
    let CUSTOMER_TYPE = localStorageCall()?.getItem('customerTypeDescr');
    if (commingFrom === "updated") {

        if ((CUSTOMER_TYPE?.type === 1 && customerType === 2) || (CUSTOMER_TYPE?.type === 1 && customerType === 3)) {
            DATES = DATES_VALUES(data, true, true, false);

        } else if (CUSTOMER_TYPE?.type === 2 && customerType === 3) {
            DATES = DATES_VALUES(data, true, true, true);

        }
        else if ((CUSTOMER_TYPE?.type === 1 && customerType === 1) || (CUSTOMER_TYPE?.type === 2 && customerType === 2) || (CUSTOMER_TYPE?.type === 3 && customerType === 3)) {
            DATES = DATES_VALUES(data, true, true, true);
        }
        else {
            DATES = DATES_VALUES(data, true, true, true);
        }
    }
    else {
        if (customerType === 1) {
            DATES = DATES_VALUES(data, false, false, true);
        } else if (customerType === 2 || customerType === 3) {
            DATES = DATES_VALUES(data, false, false, false);
        } else {
            DATES = DATES_VALUES(data, true);
        }
    }
    const REFERRAL = localStorageCall().getItem('refferal_link');
    return ({
        ...DATES,
        "customerType": customerType,
        "customerSubType": 1,
        "customerStatusType": 1,
        "customerSubStatusType": 1,
        "firstName": data?.firstName || data?.email,
        "lastName": data?.lastName || data?.email,
        "middleName": "",
        "companyName": data?.company_name || "",
        "fullName": (data?.firstName && data?.lastName) ? (data?.firstName + ' ' + data?.lastName) : "",
        "suffix": "",
        "payToName": "",
        "genderType": 1,
        "userName": data?.email,
        "userPassword": data?.password,
        "userCanLogIn": (section === "leadUser") ? false : true,
        "SmsChecked": true,
        "currencyCode": getCurrencyCode(_.upperCase(storeAssigned())),
        "countryCode": data?.country || storeAssigned().toUpperCase(),
        "membershipRenewalDate": null,
        "birthDate": null,
        "phoneNumbers": {
            "cellPhone": data?.phone,
            "homePhone": data?.phone,
            "workPhone": data?.phone,
            "otherPhone1": "",
            "otherPhone2": ""
        },
        "emailAddress": data?.email,
        "emailSubscribed": false,
        "emailSubscribedIp": "",
        "emailSubscribedDate": String(moment.utc().format()),
        "emailUnsubscribedDate": String(moment.utc().format()),
        "email2Address": "",
        "email2Subscribed": false,
        "email2SubscribedIp": "",
        "email2SubscribedDate": String(moment.utc().format()),
        "email2UnsubscribedDate": String(moment.utc().format()),
        "mailingAddress": data?.mailingAddress || {
            "address1": data?.address || "",
            "address2": data?.address_2 || "",
            "address3": "",
            "city": data?.city || "",
            "regionProvState": data?.state || "",
            "postalCode": data?.zipcode || "",
            "countryCode": data?.country || ""
        },
        "shippingAddress": {
            "address1": data?.address || "",
            "address2": data?.address_2 || "",
            "address3": "",
            "city": data?.city || "",
            "regionProvState": data?.state || "",
            "postalCode": data?.zipcode || "",
            "countryCode": data?.country || ""
        },
        "billingAddress": data?.billingAddress || {
            "address1": data?.address || "",
            "address2": data?.address_2 || "",
            "address3": "",
            "city": data?.city || "",
            "regionProvState": data?.state || "",
            "postalCode": data?.zipcode || "",
            "countryCode": data?.country || ""
        },
        "otherAddress1": {
            "address1": "",
            "address2": "",
            "address3": "",
            "city": "",
            "regionProvState": "",
            "postalCode": "",
            "countryCode": ""
        },
        "otherAddress2": {
            "address1": "",
            "address2": "",
            "address3": "",
            "city": "",
            "regionProvState": "",
            "postalCode": "",
            "countryCode": ""
        },
        "profileLink": "",
        "enrollerId": (data?.refferal === REFERRAL?.id) ? data?.refferal : REFERRAL?.id || 2,
        "enrollerBusinessCenterId": 0,
        "webAlias": data?.webAlias || '',
        "publicProfile": {
            "firstName": data?.firstName || "Test",
            "lastName": data?.lastName || "CommandConcepts",
            "fullName": (data?.firstName && data?.lastName) ? (data?.firstName + ' ' + data?.lastName) : "CommandConcepts",
            "phone": `${data?.phone}`,
            "email": data?.email,
            "city": data?.city || "",
            "regionProvState": data?.state || "",
            "countryCode": data?.country || ""
        }
    })
};


export const AddressSuggestionDialog = ({
    errorAddressData, useDefaultAddressOrNot, userData,
    setUseDefaultAddressOrNot, closeFunction
}) => (
    <DialogComponent
        opend={!!errorAddressData && (useDefaultAddressOrNot !== false)}
        handleClose={() => closeFunction()} title="" classFor="address_data">
        <div style={{ padding: "0px 20px 0px" }}>
            <div className='content-popup'>
                <p className='select-shipping-popup'><strong>Please select a shipping address</strong></p>
                <p className='select-shipping-address'>We suggest a modified shipping address</p>
            </div>
            <div className='suggested-addresses'>
                <p className='suggested-address1'><strong>Suggested Address</strong></p>
            </div>
            <div className='content-address'>

                <p className='address1-format'>{errorAddressData?.address_1}</p>
                <p className='address2-format'>{`${errorAddressData?.city}, ${errorAddressData?.state_region} ${errorAddressData?.postal_code}`}</p>
                <p className='address3-format'>{errorAddressData?.country}</p>
            </div>

            <div className='suggested-addresses'>
                <p className='suggested-address1'><strong>Original Address</strong></p>
            </div>
            <div className='content-address'>
                <p className='address1-format'>{userData?.shipping_street || userData?.billing_street}</p>
                <p className='address2-format'>{`${userData?.shipping_city || userData?.billing_city}, ${userData?.shipping_state || userData?.billing_state} ${userData?.shipping_zipcode || userData?.billing_zipcode}`}</p>
                <p className='address3-format'>{userData?.shipping_country || userData?.billing_country}</p>
            </div>
        </div>
        <DialogActions className='button_action'>
            <ButtonComponent classes={"use_address"} onClick={() => {
                setUseDefaultAddressOrNot(errorAddressData)
                closeFunction()
            }}>Use Suggested</ButtonComponent>
            <ButtonComponent classes={"use_oringal"} onClick={() => {
                closeFunction()
                setUseDefaultAddressOrNot(false);
            }}>Use Original</ButtonComponent>
        </DialogActions>
    </DialogComponent>
);


export const QuantityComponent = ({ row, _minCartQuantityIncDec }) => {
    return (
        <ButtonComponent classes="bg-inherit  w-full max-w-[110px] py-[5px] !px-0">
            <div className="flex items-center text-button-color justify-around lg:text-sm xl:text-lg !leading-3">
                <span
                    className="hover:text-primary cursor-pointer py-2 px-2 hover:!text-button-color"
                    onClick={() => _minCartQuantityIncDec(row?.id, "sub")}
                >
                    -
                </span>
                <span>{row?.quantity}</span>
                <span
                    className="hover:text-primary cursor-pointer py-2 px-2 hover:!text-button-color"
                    onClick={() => _minCartQuantityIncDec(row?.id, "add")}
                >
                    +
                </span>
            </div>
        </ButtonComponent>
    )
}

export function _storeCustomerTypes(dispatch, token, searchParams, callback) {
    dispatch(getAllCustomerData((data) => {
        if (data?.customerType === 3) {
            localStorageCall().setItem('customerTypeDescr', JSON.stringify({ type: data?.customerType, role: 'IBO', customerType: data?.customerTypeDescr, priceType: 6, userData: data }));
        } else if (data?.customerType === 2) {
            localStorageCall().setItem('customerTypeDescr', JSON.stringify({ type: data?.customerType, role: 'preferred', customerType: data?.customerTypeDescr, priceType: 6, userData: data }));
        } else {
            // if (!searchParams.get('autoshipId')) {
            //     dispatch(getAutoShipOrders(null, token, (values) => {
            //         if (values?.data?.length > 0) {
            //             localStorage.setItem('customerTypeDescr', JSON.stringify({ type: data?.customerType, customerType: data?.customerTypeDescr, priceType: 2 }), { expires: 60 });
            //         } else {
            //             localStorage.setItem('customerTypeDescr', JSON.stringify({ type: data?.customerType, customerType: data?.customerTypeDescr, priceType: 1 }), { expires: 60 });
            //         }
            //         callback();
            //     }, 1));
            // }
            localStorageCall().setItem('customerTypeDescr', JSON.stringify({ type: data?.customerType, role: 'retail', customerType: data?.customerTypeDescr, priceType: 1, userData: data }));
        }
        // const refferalData = JSON.stringify({ "id": data?.enrollerId, "name": data?.enrollerName, "webalies": data?.enrollerName });
        // localStorageCall().setItem('refferal_link', refferalData);
    }, token))
}

export const USERDATA_PAYLOAD = {
    shipping_urname: "", shipping_lastname: "", shipping_email: "", shipping_phone: "", shipping_delivery: '',
    shipping_street: "", shipping_street2: "", shipping_city: "", shipping_zipcode: "", shipping_country: "", shipping_state: ""
};


export function _sameAsShippingCall(resetForm, countryState, setCountryState, userData, setUserData, values, handleChangeFunction, e) {
    let ADDRESS_STORE = ['urname', 'lastname', 'email', 'phone', 'street', 'street2', 'city', 'zipcode', 'country', 'state'];
    let SHIPPING_ADDRESS = {};
    _.map(ADDRESS_STORE, (row) => {
        _.assign(SHIPPING_ADDRESS, { [`shipping_${row}`]: values[`billing_${row}`] })
    });
    if (e.target.checked) {
        setUserData({ ...values, ...SHIPPING_ADDRESS });
        setCountryState({ ...countryState, shipping_country: countryState?.billing_country, shipping_state: countryState?.billing_state });
        resetForm({ ...values, SHIPPING_ADDRESS });
        handleChangeFunction(null, { ...values, ...SHIPPING_ADDRESS, shipping_country: countryState?.billing_country, shipping_state: countryState?.billing_state });
    }
}

export function onErrorFunction(res) {
}

export function loopFunction(paymentFormHtml, callBackFunction) {
    let tokenId = localStorage.getItem('order_token') ? localStorage.getItem('order_token') : "";
    let preAuthOrderId = localStorage.getItem('preAuthOrderId') ? localStorage.getItem('preAuthOrderId') : '';
    let errorPayment = localStorage.getItem('error-payment') ? localStorage.getItem('error-payment') : "";
    if (tokenId || preAuthOrderId) {
        if (errorPayment) {
            localStorageCall().removeItem('error-payment');
        }
        callBackFunction({ tokenId, preAuthOrderId });
        localStorage.removeItem('order_token');
        localStorage.removeItem('preAuthOrderId')
    } else if (errorPayment) {
        localStorage.removeItem('error-payment');
        // _scriptFunctionCall(paymentFormHtml, callBackFunction)
    } else {
        setTimeout(() => {
            console.log('dddddddddddddddddddddd')
            loopFunction(paymentFormHtml, callBackFunction);
        }, 2000);
    }
}

export function _scriptFunctionCall(paymentFormHtml, callBackFunction, data) {
    let i = 0;
    if (i < 1) {
        try {
            localStorage.removeItem('order_token');
            localStorage.removeItem('preAuthOrderId')
            i++;
            window.submitHandle(data);
            callBackFunction();
            // loopFunction(paymentFormHtml, callBackFunction);
        } catch (e) {
            console.log(e)
        }
    }


} 