import _ from 'lodash';
import React, { useRef, useState } from 'react';
import parse from 'html-react-parser';
import DialogComponent from '../../../Inputs/DailogComponent';
import TextFieldComponent from '../../../Inputs/TextFieldComponent';
import { REFFERAL_VALUES } from '../../../services/Methods/normalMethods';
import LoaderComponent from '../../../Inputs/LoaderComponent';


const MainCreateAccountComponent = (props) => {
    const { openPopup, setOpenPopup, isLoadingTrue, webAliesError, handleChange, _validateRefferalFunction, _checkingCustomerType, SIGNUP_TEXT, _navigateFunction, navigateLink } = props;
    const inputRef = useRef(null);
    const [isButtonDisabled, setButtonDisabled] = useState(true);
    const handleInput = () => {
        const inputValue = inputRef.current.value;
        const isInputValid = inputValue.length > 0;
        setButtonDisabled(!isInputValid);
    };
    const WHOLSALE_SPAN = `<span className='text-white mt-2 text-[1.5rem]'>(${SIGNUP_TEXT?.wholesale_text})</span>`

    const CARDS_VALUES = [
        { id: 1, heading: SIGNUP_TEXT?.retialCustomer_text, discription: SIGNUP_TEXT?.retailDescription, link: `retail_signup` },
        { id: 2, heading: `${SIGNUP_TEXT?.preferredCustomer_text} ${WHOLSALE_SPAN}`, discription: SIGNUP_TEXT?.preferredDescription, link: `preferred_customer` },
        { id: 3, heading: `${SIGNUP_TEXT?.independentOwner_text} ${WHOLSALE_SPAN}`, discription: SIGNUP_TEXT?.independentDescription, link: `business_owner` },
    ];
    return (
        <div className="main-wrapper">
            <h2 className="text-center mt-[1.5rem] text-[32px] font-normal text-[#373f50] mb-3">{SIGNUP_TEXT?.readyTo_checkUp}</h2>
            <h4 className="font-normal text-[1.5rem] text-center leading-tight text-[#373f50] mb-3">{SIGNUP_TEXT?.selectHow_ToJoin}</h4>
            <div className="mx-auto lg:max-w-full  my-0 py-[50px]">
                <div className="xl:gap-[30px] lg:gap-[20px] md:gap-[30px]  flex justify-center flex-wrap w-full">
                    {_.map(CARDS_VALUES, ({ id, heading, discription, link }) => (
                        <div className="h-[400px] xl:max-w-[390px] lg:max-w-[320px] md:max-w-[50%] relative bg-[#E9E7E7]" key={id}>
                            <h2 className="text-center text-white mb-2 h-[175px] bg-[#0071BA] flex flex-col text-[31px] px-[10px] py-[20px] justify-center 
                            align-center leading-[1.2]
                            ">{parse(heading) || heading}</h2>
                            <div className="offer-card-body text-[20px] px-[30px] pb-[10px]">
                                <p className='text-[#373f50] font-light m-0 mb-[15px]'>{discription}</p>
                            </div>
                            <button onClick={() => _checkingCustomerType(link)} className="absolute bottom-[30px] py-[15px] xl:px-[30px] lg:px-[10px] w-full max-w-half bg-[#DEAA17] text-white rounded-[30px] right-0 left-0 mx-auto my-0"
                            >{SIGNUP_TEXT?.createAccount}</button>

                        </div>
                    ))}
                </div>
            </div>

            {!REFFERAL_VALUES?.webalies &&
                <DialogComponent opend={openPopup} handleClose={() => setOpenPopup(false)} title="Who referred you?" classFor="ReferralUser" >
                    <div className='container'>
                        <div className='row' style={{ textAlign: "center" }}>
                            <p style={{ fontSize: "21px" }}>Please provide the referral code from the person who introduced you to Provizion Global</p>
                        </div>
                        {isLoadingTrue ? <LoaderComponent /> : <div className='invitation-code'>
                            <TextFieldComponent type="text" placeholder='Invitation Code (xxxxxx)'
                                onChange={(e) => {
                                    handleChange(e.target.value);
                                    handleInput();
                                }}
                                error={!!webAliesError?.isRefferalError}
                                errorMessage={webAliesError?.isRefferalError}
                                inputRef={inputRef}
                            />
                        </div>
                        }
                    </div>
                    <div className='blank-section'></div>
                    {/* <button
                    className="text-base font-normal inline-block text-button-color transition-all duration-300 ease-in-out hover:text-button-hover-color mt-7"
                    onClick={() => {
                        setOpenPopup(false);
                        _navigateFunction(navigateLink);
                    }}
                >
                    I don't have any referral
                </button> */}
                    <button type="Submit" disabled={isLoadingTrue || isButtonDisabled} className={`invite-referrer ${isLoadingTrue || isButtonDisabled ? 'disabled' : ''}`} onClick={() => _validateRefferalFunction()}>Ok</button>
                </DialogComponent>}
        </div >
    )
};

export default MainCreateAccountComponent;
