import React from "react";
import withRouter from "../../services/HOC/withRouterHOC";
const KTMist = () => {


  return (
    <>
      <div style={{ width: '100%', height: '100vh' }}>
        <iframe src="/pdf/KT_Mist_Product_Sheet.pdf" title="PDF Viewer" style={{ width: '100%', height: '100%', border: 'none' }}></iframe>

        {/* <embed src="/public/pdf/Provizion_Global_P&P's3.7.25.pdf" type="application/pdf" width="100%" height="600px" toolbar="0"/> */}
      </div>
    </>
  )
};
export default withRouter(KTMist);
