import { combineReducers } from '@reduxjs/toolkit';
import LoginSlice from './Reducer/LoginSlice';
import CustomerSlice from './Reducer/CustomerSlice';
import AllProductSlice from './Reducer/AllProductSlice';
import PaymentSlice from './Reducer/PaymentSlice';
import CheckoutSlice from './Reducer/CheckoutSlice';
import OrderSlice from './Reducer/OrderSlice';

const rootReducer = combineReducers({
    // [createDelete.reducerPath]: createDelete.reducer,
    AllProductSlice,
    LoginSlice,
    CustomerSlice,
    PaymentSlice,
    CheckoutSlice,
    OrderSlice
});

export default rootReducer;
