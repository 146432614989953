import React from 'react'

const ErrorElement = () => {
    return (
        <div className='fourZeroFourNotFound bg-[#d7e6ef80] h-[100vh] flex flex-col justify-center items-center w-full '>
            <h1 className='text-[25rem] m-[-6rem] bg-button-color text-transparent bg-clip-text drop-shadow-[0px_10px_4px_#0071BA] font-semibold'>404</h1>
            <h3 className='
                text-[4rem] font-extralight tracking-[14px] ml-[60px] w-full bg-red-500 max-w-[calc(100% - 300px)] drop-shadow-[0px_3px_4px_#f00]
                bg-clip-text text-transparent text-center
            '>
                Page Not Found
            </h3>
        </div>
    );
}

export default ErrorElement;