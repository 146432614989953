import _ from "lodash";
import { useEffect } from "react";
import countryListAllIsoData from "./Common/Components/AllStatesCodeArray";
import { useLocation } from "react-router-dom";

const useIpDetaction = (STORES, currentStore, navigate) => {

    const location = useLocation();

    useEffect(() => {
        console.log((_.find(countryListAllIsoData, (r) => _.lowerCase(r?.code) === currentStore)), currentStore, 'currentStore');
        const DATASTORE = sessionStorage.getItem('alreadyRedirect');
        if (!DATASTORE && (_.find(countryListAllIsoData, (r) => _.lowerCase(r?.code) === currentStore))) {
            success();
        } else if (DATASTORE && (_.find(countryListAllIsoData, (r) => _.lowerCase(r?.code) === currentStore)) && !_.includes(STORES, currentStore)) {
            success();
        }
    }, []);

    async function success(callback = null) {
        const response = await fetch('https://api.ipify.org/');
        const ip = await response.text();
        if (ip) {
            const CurrentCountry = await fetch(`https://pro.ip-api.com/json/${ip}?key=QzWizLOfUTKbkWQ`);
            const VALUES = await CurrentCountry.json();

            if (!callback) {
                sessionStorage.setItem('alreadyRedirect', '`yes');
                if (_.includes(STORES, _.lowerCase(VALUES?.countryCode))) {
                    if (_.split(location?.pathname, '/')?.length > 0 && _.split(location?.pathname, '/')[2]) {
                        navigate(`/${_.lowerCase(VALUES?.countryCode)}/${_.split(location?.pathname, '/')[2]}`);
                    } else {
                        navigate(`/${_.lowerCase(VALUES?.countryCode)}`);
                    }
                } else {
                    if (_.split(location?.pathname, '/')?.length > 0 && _.split(location?.pathname, '/')[2]) {
                        navigate(`/us/${_.split(location?.pathname, '/')[2]}`);
                    } else {
                        navigate(`/us`);
                    }
                }
            } else {
                sessionStorage.setItem('alreadyRedirect', '`yes');
                if (_.includes(STORES, _.lowerCase(VALUES?.countryCode))) {
                    callback(VALUES?.countryCode?.toLowerCase());
                } else {
                    callback(`us`);
                }
            }
        }
    };


    return {
        success
    }

}

export default useIpDetaction;