import classNames from 'classnames';
import _ from 'lodash'
import React from 'react'

const BlueWhiteTwoOption = ({ data, TEXT }) => {
    return (
        <section className="py-[60px]">
            <div className="container mx-auto max-w-[1260px] px-[15px] flex flex-wrap">
                {_.map(data, (row, index) => {
                    const CHECK = index !== 0 ? true : false;
                    return (
                        <div key={index + 1} className={classNames("md:w-1/2 w-full  text-center py-[76px] px-6", { 'bg-[#0071BA]': !CHECK }, { 'bg-[#F0EFEF]': CHECK })}>
                            <h2 className={classNames('text-[40px] leading-[1.2] mb-8  font-extrabold', { 'text-white': !CHECK }, { 'text-heading-color': CHECK })}>{row?.heading}</h2>
                            <p className={classNames('text-[20px] font-normal leading-[1.2] mb-9', { 'text-white': !CHECK }, { 'text-heading-color': CHECK })}>{row?.para}</p>
                            <a href={row.link} className='inline-block py-[19px] px-[45px] rounded-[30px] bg-[#DEAA17] text-[19px] text-white leading-[1.5] font-normal transition-all duration-300 ease-in-out hover:text-black hover:bg-white'>
                                {CHECK ? TEXT?.register_now : TEXT?.purchase_now_text}
                                <span className='ml-[7px]'><i className="fa-solid fa-arrow-right"></i></span>
                            </a>
                        </div>
                    )
                })}
            </div>
        </section>
    )
}

export default BlueWhiteTwoOption;