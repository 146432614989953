

const EXPLORE_BUSINESS_TEXTS = {
    provizion_globel_text: "Provizion Global",
    business_heading: "Business Experiences",
    download_pdf_link: "https://provizion.com/api/Files/documents/0",
    download_pdf_microbusiness_link: 'https://provizion.com/api/Files/documents/2',
    slider_comments: [
        {
            bussiness_comment: [
                `"George Reninger called me and began to tell me about his new company Provizion Global. I had retired from network marketing 4 years before and I was not interested in starting a new one. But because it was George , I listened and of course said yes. It’s one of the best decisions I’ve ever made".`,
                `The company’s GSH product is such a powerful formula, I noticed an uptick in my workout strength, recovery, and especially my mental clarity and focus. I’d been on the best Glutathione product on the market for 10 years, but this product is much more powerful.`,
                `Also, it’s been a joy to see my personally enrolled IBOs experience success within their first week of being in this business, Especially those who’ve never thrived in networking before. It’s so easy to share the opportunity of health and business opportunity with our videos, product documents, IBO weekly calls, and our WakeUpWithProvizion.com morning calls.`,
                `I believe Provizion Global will be my final networking company – my legacy. I want it to go to my children and grandchildren. I have absolute confidence in the corporate team, the IBO leaders, the product, the infrastructure, and our future potential as a billion dollar company that helps millions of people around the world."`
            ],
            comment_name: "- Bill G."
        },
        {
            bussiness_comment: [
                `"I have been in the MLM Industry for 48+ years, and at 76 years of age, I give hope to people that age is just a number. 
                I joined Provizion Global because I know the power of Glutathione or GSH. 
                My first 2 weeks I have helped 22 of my friends begin a home-based business marketing Provizion GSH. 
                Great product, great leadership, and unbelievably fast delivery of the product."`
            ],
            comment_name: "- Carl S."
        },
        {
            bussiness_comment: [
                `After 33 years of being in the Industry, never before have I enjoyed such a successful first week. It is a testimony to the power of this product. It is an honor to be involved with a company so dedicated to support its members.`,
                `Provizion Global has created an incredible opportunity for everyone to be able to join free with a free website that gives us the tools to support our business goals immediately. You can do it all from your own home; all you need is a telephone.`,
                `It gives me enormous pleasure to know I can offer Provizion Global to everyone and teach people how to build a business and empower themselves. Thank you to my new family at Provizion Global where everything is possible!"`,
            ],
            comment_name: "- Anna S."
        },
        {
            bussiness_comment: [
                `"I've been at the top of income earners in 5 different companies. I've seen the good, the bad, and the ugly. 
                The opportunity Provizion Global offers is for everyone. Whether you are brand new, or an experienced leader, 
                there is the opportunity to build a successful business, based on the sales in your team. I love earning commissions weekly, too!"`
            ],
            comment_name: "- Darlene M."
        }
    ],
    start_business_heading: "Start Your Business Today",
    start_business_para: "You can build your own business locally, but you can also develop a business network that reaches out to your family and community, and even across the globe.",

    why_provizion_para: "With Provizion Global we provide better solutions to a world with ever increasing physical and financial challenges. Come join our rescue mission to the human race!",
    provizion_plan_fusion_para: "The Provizion Plan is a fusion between the best of Microbusiness Development and Network Marketing.",

    microbusiness_text: "Microbusiness",
    microbusiness_utilizes_para: "utilizes “Low-to-No Startup Cost” businesses and systems to empower everyday people to create sustainable incomes, by selling high demand consumable products conveniently into the local community.",

    network_marketing_text: "Network Marketing",
    network_marketing_para: "unites the power of advanced products and distribution systems, with the potential for wealth development via globally integrated compensation structures.",

    additionl_reward_text: "ADDITIONAL REWARDS",
    additionl_reward_para: "Provizion Global may also offer additional awards, such as: mobile phones; tablets; Computers; Leadership Dinners, Automobiles; Special Executive Trainings; FREE Registration, Travel, & Lodging for our Global & Regional Conventions; Vacations; Leadership Summits.",

    two_product_incentive_text: "SmartShip Incentive",
    two_product_incentive_para: "One PC & One IBO ranked no higher than Team Builder, who have maintained 100PV or more qualification for 12 consecutive weeks, will be randomly selected to receive 1 Share of the Global Leadership Pool in product value or cash.",

    retail_para: "Buy product at wholesale and sell at a retail.",
    sponsor_para: "When you enroll a new Provizion Global Preferred Customer or Independent Business Owner (IBO) you will receive 10% of their product order PV anytime they order.",
    team_para: "Your personal Sales Organization is divided into a two-team structure. Based on your Leadership Rank you may earn up to 10% of the Product Volume (PV) in your Lesser Team Volume (LTV), up to a Maximum of $20,000 per week for each Business Center!",
    generation_para: "Based on your weekly Paid Rank you will receive a 7% MATCH of the Team Earnings from IBO’s in your Sponsor Team, including up to 10 Generations deep of Area Team Builders or above Leaders.",
    rank_para: "Based on achieving a new weekly Paid Rank, you will receive a one-time financial incentive paid according to company specifications.",
    lifestyle_para: "Based on the highest weekly Paid Rank achieved twice within a Calendar Month you will receive a specific dollar bonus amount for that month.",
    bonuspool_para: "Based on your weekly Paid Rank you will receive between 1 and 10 shares of a Global Leadership Pool filled with 3% of the Total Company Sales Volume.",

    four_foury_four_heading: "444 Strategy, Structure & System",
    four_foury_four_first_para: "When navigating through our compensation plan, we recommend a 444 strategy, structure and system. Investing your time and efforts to invite a minimum of four people a day, for at least four days of the week, and then repeating that same activity for 4 weeks, would mean a minimum of 64 people invited in a single month.",
    four_foury_four_second_para: `As these respond to your personal 
    <strong className="font-semibold">INVITATIONS</strong> and review the corresponding Health and Business related 
    <strong className="font-semibold">PRESENTATIONS</strong>, as you are doing right now, you would ideally see percentage response of completed 
    <strong className="font-semibold">REGISTRATIONS…</strong> the system would automatically balance the placement of these 
    <strong className="font-semibold">REGISTRATIONS</strong> between the left and right teams of your <strong className="font-semibold">ORGANIZATIONS</strong>.`,
    four_foury_four_third_para: `Your goal would be Sponsor 4 serious IBO’s, and support each of these new Microbusiness partners to Sponsor their own 4 IBO’s. With everyone striving to follow this 444 a profitable microbusiness network can begin to develop.`,

    download_m_plan: "CLICK TO DOWNLOAD PDF MICROBUSINESS PLAN",
    get_provizion_now_text: "Get Provizion Now!",
    get_provizion_now_para: "If you're looking to strengthen your physical well being with a product and technology you can believe in, then Provizion GSH & Energy are right for you.",
    start_bussiness_now_text: "Start Your Business Now!",
    start_bussiness_now_para: "If you're looking for a breakthrough financially and a business model you can believe in, then register now and get started today.",

    faq_question_answer: [
        {
            question: 'How much does it cost to join Provizion Global?',
            answer: 'There’s no fee to join or annual fees at Provizion Global.',
        },
        {
            question: 'What marketing and business building tools do I receive when I join Provizion Global as an Independent Business Owner (IBO)?',
            answer: 'Provizion Global provides IBOs with the tools they need to succeed. IBOs receive the following at no cost:',
            subAnswers: [
                'Unique website address i.e. provizion.com/MyUniqueName.',
                'Placement Link Tools.',
                'IBO Back Office dashboard.',
                'Training via team website “teamprovizion.com”.',
                'Purchase Provizion Products at wholesale pricing.',
                'Receive commissions based on Provizion Global’s compensation plan.',
            ],
        },
        {
            question: 'How often do you pay commissions?',
            answer: 'There are 9 different ways to earn commissions, the majority of which are paid weekly (see Provizion Global’s compensation plan). All commissions are currently paid in US dollars.',
        },
        {
            question: 'What countries is Provizion Global currently doing business in?',
            answer: 'We are open in the United States and provide not-for-resale product service in Australia, New Zealand, Canada and United Kingdom. We also provide sample pack only service to many other markets.',
        },
        {
            question: 'When did Provizion Global begin operations?',
            answer: 'Provizion Global officially pre-launched on November 3, 2020, in the United States with not-for-resale product service to Australia, New Zealand, and Canada. On March 18th 2022, we officially launched and have added other markets since then.',
        },
        {
            question: 'Who are the founders of Provizion Global?',
            answer: 'Rick S. Nelson and George G. Reninger are seasoned veterans in the network marketing space and have pooled their knowledge together to create Provizion Global.',
            subAnswers: [
                'Rick Nelson is an accomplished senior executive for multiple network marketing companies including a NYSE company that is doing over $2 billion annually. Rick has developed profitable network marketing companies in dozens of countries.',
                'George Reninger is a former engineering corporate executive and successful former field leader. George has built successful worldwide networks in multiple companies.',
            ],
        },
    ],

};

export default EXPLORE_BUSINESS_TEXTS;