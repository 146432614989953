import MuiDialogTitle from "@mui/material/DialogTitle";
import styled from "styled-components";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

const DialogStyle = styled.div`
  

`;

export default function DialogComponent({
    children,
    opend,
    handleClose,
    titleBanner,
    title,
    classFor,
}) {
    const DialogTitle = (props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle className="dialog_title_for_all" {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        className="close_button"
                        onClick={onClose}
                    >
                        <Close />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    };
    return (
        <Dialog
            open={opend}
            className=""
            classes={{ paper: classFor, scrollPaper: `${classFor} dialog_scrollClass` }}
            onClose={handleClose}
        >
            <DialogStyle>
                <div className={`${classFor}_main_dialog ${(title === "Create Link") ? 'createLink_class' : ""}`}>
                    <DialogTitle
                        id="alert-dialog-title"
                        onClose={handleClose}
                    >
                        {titleBanner ? titleBanner : ""}
                        {title ? title : ""}
                    </DialogTitle>
                    <DialogContent className="container_for_load_data">
                        {children}
                    </DialogContent>
                </div>
            </DialogStyle>
        </Dialog>
    );
}
