import React from 'react'
import { useOutletContext } from 'react-router-dom';
import MainViewCart from './components/MainViewCart';
import { ViewCartStyle } from '../../../styles/viewCartStyle';

const ViewCartPage = (props) => {
    const data = useOutletContext();
    return (
        <ViewCartStyle >
            <MainViewCart {...data} />
        </ViewCartStyle>
    )
}

export default ViewCartPage;