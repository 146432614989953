import React from 'react';
import MainExploreHealth from './components/MainExploreHealth';
import withRouter from '../../services/HOC/withRouterHOC';
import _ from 'lodash';

const ExploreHealthPage = ({ EXPLORE_HEALTH_TEXTS, COMMON_TEXTS }) => {
    const HEALTH_TEXT = _.assign(EXPLORE_HEALTH_TEXTS, COMMON_TEXTS);
    return (
        <>
            <MainExploreHealth {...{ HEALTH_TEXT }} />
        </>
    )
};

export default withRouter(ExploreHealthPage);