import ABOUT_TEXTS from '../../TextConfigs/aboutPage.texts';
import COMMON_TEXTS from '../../TextConfigs/common.texts';
import EXPLORE_BUSINESS_TEXTS from '../../TextConfigs/exploreBusiness.texts';
import EXPLORE_HEALTH_TEXTS from '../../TextConfigs/exploreHealth.texts';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ROUTING_TEXT, { SIGNUP_TEXT, LOGIN_TEXT } from '../../TextConfigs/RoutingAndSmallContent.text';
import FOOTER_TEXTS from '../../TextConfigs/footer.texts';
import localStorageCall from '../Methods/localstorage.hook';
import { useDispatch } from 'react-redux';
import MESSAGE_TEXT from '../../TextConfigs/Message.text';
import { _storeCustomerTypes } from '../Methods/commonPayload';
import AllProductAndCheckoutText from '../../TextConfigs/AllProductAndCheckout.text';

function withRouter(Component) {
    function ComponentWithRouterProp(props) {

        let location = useLocation();
        let navigate = useNavigate();
        const [searchParams] = useSearchParams();
        let isUserLogin = localStorageCall().getSingleItem('Token');
        // const countryCodes2 = createSelector(state => state?.LoginSlice?.alluserData, (userData) => ({ userData }));
        // const { userData } = useSelector((state) => countryCodes2(state));

        let params = useParams();
        const dispatch = useDispatch();


        return (
            <Component {...props} {...{
                // react >> routing
                location, navigate, params, isUserLogin, searchParams, dispatch,
                //react >> TextConfig
                ABOUT_TEXTS, COMMON_TEXTS, EXPLORE_BUSINESS_TEXTS, EXPLORE_HEALTH_TEXTS,
                ROUTING_TEXT, SIGNUP_TEXT, FOOTER_TEXTS, MESSAGE_TEXT, LOGIN_TEXT, AllProductAndCheckoutText

            }} />
        );
    }
    return ComponentWithRouterProp;
};

export default withRouter;