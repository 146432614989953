const AllProductAndCheckoutText = {
    singleOrder_text: "Single Order",
    smartshipOrder_text: "SmartShip Order",
    addToSmartship_text: "Add to SmartShip",
    manufature_in: "Manufactured in a",
    same_as_shipping_text: "Same as shipping",
    CGMP_compliant: {
        text: "CGMP compliant facility",
        link: "https://www.fda.gov/drugs/pharmaceutical-quality-resources/facts-about-current-good-manufacturing-practices-cgmps"
    },
    NSF_certified: {
        text: "NSF certified facility",
        link: "https://www.nsf.org"
    },
    FDA_registered: {
        text: "FDA registered facility",
        link: "https://www.fda.gov/food/guidance-regulation-food-and-dietary-supplements"
    },
    manufature_desc: "Manufactured in a kosher and Halal certified facility.",
    singleProductPageDiscription_text: "WHAT'S INSIDE",
    singleProductPageDiscription: [
        "<strong>Vitamin C</strong> is a powerful antioxidant that protects our bodies from oxidative stress and supports a healthy immune system.",
        "<strong>Vitamin E</strong> helps stop the creation of free radical chain reactions. Vitamin E is a fat-soluble vitamin and is especially effective in halting oxidation of creatin fats, such as LDL cholesterol.",
        "<strong>Vitamin B-6</strong> helps maintain the health of nerves, skin, and red blood cells. It's a precursor to serotonin, helping to normalize the circadian rhythm.",
        "<strong>Vitamin B-7</strong> is known as biotin. It helps the body metabolize carbohydrates, fats, and amino acids. Biotin may also help promote healthy regulation of immune and inflammatory response.",
        "<strong>Vitamin B9</strong> is known as folate or folic acid. It helps with red blood cell formation and, for healthy cell growth and function.",
        "<strong>Vitamin B12</strong> supports energy metabolism and vital red cell development and is required for proper function of the brain and nerve cells. ",
        "<strong>Zinc</strong> is a mineral required for the activity of more than 300 enzymes and is involved in the manufacturing of protein and in cell division.",
        "<strong>Selenium</strong> is a key nutrient that helps support a healthy immune system.",
        "<strong>L-glutamine</strong> is one of the three amino acid building blocks to help your body create glutathione.",
        "<strong>N-Acetyl D-Glucosamine</strong> supports the gastrointestinal absorption of amino acids.",
        "<strong>Quercetin</strong> is a flavonoid antioxidant known to support energy levels.",
        "<strong>Alpha Lipoic Acid</strong> protects the body from oxidative stress and keeps the cells functioning properly.",
        "<strong>Cordyceps</strong> is a mushroom that promotes stamina and vitality.",
        "<strong>GSH BioBlend</strong> is a unique fusion of N-Acetyl-Cysteine, Bioavailable Glutathione, S-Acetyl-Glutathione, Acemannan, and Superoxide Dismutase for optimal glutathione support.",
    ],

    place_orderText: "Place Order",
    customerInformation_text: "Customer Information",
    shippingAddress_text: "Shipping Address",
    PaymentInfo_text: "Payment Info",
    placeOrder_text: "Place Order",
    continueShopping_text: "Continue Shopping",
    updateProduct_and_promotion: "I want to receive updates about products and promotions.",

    ShippingSection_TEXTS: {
        noShipMethod_text: "No shipping method on this address.",
        shipMethod_text: "Shipping Method"
    },

    SavedCards_TEXTS: {
        savedPayment_text: 'Saved Payment Method',
        addPayment_text: 'Add New Payment Method'
    },

    PointCollectionTEXTS: {
        points_text: "Points",
        available_text: "Avaliable :",
        wantTo_get: 'Want to use Points',
        fillAmount_text: "Fill the amount"
    }
}

export default AllProductAndCheckoutText;