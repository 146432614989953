import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ResetPasswordhook from './ResetPassWord.hook';
import ButtonComponent from '../../../Inputs/ButtonComponent';
import { storeAssigned } from '../../../services/Methods/normalMethods';

const ResetEmailVarified = (props) => {

  const { ROUTING_TEXT, _onHandleEmailSubmit, emailState } = props;

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: Yup.object({ email: Yup.string().email('Invalid email address').required('Email is required') }),
    onSubmit: (values, action) => {
      _onHandleEmailSubmit(values);
      action.resetForm();
    },
  });

  return (
    <>
      <form
        className="p-9 rounded-[7px] bg-white shadow-[0px_0px_10px_0px_rgba(0,0,0,0.1)] text-left"
        onSubmit={formik.handleSubmit}
      >
        <h3 className="text-[28px] leading-[1.2] font-extrabold mb-4">Forgot Password</h3>
        <div className="mb-4">
          <label
            className="text-[14px] leading-[1.5] font-normal mb-[6px] block w-full text-heading-color pt-[1px]"
            htmlFor="email"
          >
            Email Address
          </label>
          <input
            type="text"
            id="email"
            name="email"
            placeholder="example@gmail.com"
            className={`px-4 py-[10px] rounded-[5px] border-[1px] border-[#dae1e7] text-[15px] leading-[1.5] font-normal inline-block w-full ${formik.touched.email && formik.errors.email
              ? 'border-red-500'
              : ''
              }`}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-red-500 text-[14px] mt-1">
              {formik.errors.email}
            </div>
          ) : null}
        </div>
        <ButtonComponent
          type="submit"
          className="py-[14px] px-[49px] uppercase bg-button-color text-white transition-all duration-300 ease-in-out hover:bg-button-hover-color"
          disabled={formik.isSubmitting}
        >
          Reset
        </ButtonComponent>
        {emailState?.emailerror && <div style={{ color: "red", fontSize: "14px", textAlign: "center" }}>{emailState?.emailerror}</div>}
      </form>
      <a
        href={`/${storeAssigned()}/${ROUTING_TEXT?.login_routingName}`}
        className="text-base font-normal mt-4 inline-block text-button-color transition-all duration-300 ease-in-out hover:text-button-hover-color"
      >
        Back to Login
      </a>
    </>
  );
};

export default ResetEmailVarified;
