import React from 'react';
import { useOutletContext } from 'react-router-dom';
import withRouter from '../../services/HOC/withRouterHOC';
import LoaderComponent from '../../Inputs/LoaderComponent';
import ProductDisplaySection from '../../Common/Components/AllFormsCommons/ProductDisplaySection';
import localStorageCall from '../../services/Methods/localstorage.hook';
import withProductHOC from '../../services/HOC/withProductHOC';

const Shop = ({ SIGNUP_TEXT, ...props }) => {
    const {
        setOpenMiniCart, addToCartProducts, setAddToCartProduct, navigate
    } = useOutletContext();

    const CUSTOMER_TYPE = localStorageCall().getItem('customerTypeDescr') ? localStorageCall().getItem('customerTypeDescr') : { priceType: 1, customerType: 'Retail Customer (RC)' };
    return (
        <div className="mt-[30px] mx-auto max-w-[1260px] px-[15px]">
            {props?.loading && <LoaderComponent />}
            <div className="bg-[#fafafa] xl:h-[135px] lg:h-[148px] md:h-[112px] sm:h-[93px] h-[60px] flex items-center text-center">
                <h1 className="font-semibold text-button-color xl:text-[64px] lg:text-[50px] md:text-[35px] sm:text-[26px] text-2xl tracking-widest f-besporh text-black container mx-auto xl:mt-4 lg:mt-4 mt-1.5 lg:px-3.5 xl:px-3.5 px-3.5">SHOP</h1>
            </div>
            <div className='items-center text-center py-[30px]'>Price type:{' '} {CUSTOMER_TYPE?.customerType}</div>
            <div className="form_section flex gap-3 sm:pb-[60px] pb-[50px]">
                <ProductDisplaySection {...{
                    navigate,
                    addToCartProducts,
                    cartProducts: addToCartProducts['normalProducts'],
                    setAddToCartProduct,
                    setOpenMiniCart,
                    section: 'normal'
                }} />
            </div>
        </div>
    );
};
export default withRouter(withProductHOC(Shop));