import React, { useEffect } from 'react'

const ScrollToTopOnPathChange = ({ location }) => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [location])

    return (
        <></>
    )
}
export default ScrollToTopOnPathChange