import _ from 'lodash';
import React from 'react';
import withRouter from '../../services/HOC/withRouterHOC';
import Leadership from './components/LeaderShip';
import AboutFirstSection from './components/AboutFirstSection';
import AboutSecondSection from './components/AboutSecondSection';
import AboutThirdSection from './components/AboutThirdSection';

const AboutPage = (props) => {
  const { ABOUT_TEXTS, COMMON_TEXTS } = props;
  const TEXTS = _.assign(ABOUT_TEXTS, COMMON_TEXTS);

  return (
    <>
      <AboutFirstSection ABOUT_TEXTS={TEXTS} />
      <AboutSecondSection ABOUT_TEXTS={TEXTS} />
      <AboutThirdSection ABOUT_TEXTS={TEXTS} />
      <Leadership ABOUT_TEXTS={TEXTS} />
    </>
  )
};

export default withRouter(AboutPage);