const COMMON_TEXTS = {
    shop_text: 'Shop',
    explore_health: "Explore Health",
    explore_text: "Explore",
    business_text: "Business",
    health_text: 'Health',
    explore_business: "Explore Business",
    create_account: "Create Account",
    about: "About",
    sign_in: 'Sign In',
    my_cart: "My Cart",
    reffer_by: "Referred By",
    contact_us: "Contact Us",
    disclosure: "Disclosures",
    income_disclosure: 'Income Disclosure',
    shipping_disclosure: 'Shipping Disclosure',
    terms_condition: 'Terms & Conditions',
    privacy: 'Privacy Policy',
    refund: 'Refund Policy',
    procedures_policies: 'Policies & Procedures',
    compensation: 'Compensation Plan',
    leadership: 'Leadership',
    bonuspool_text: 'Bonus Pool',
    support: "Support",
    learn_more: "Learn more",
    register_now: "Register Now",
    retail_text: "RETAIL",
    sponsor_text: "SPONSOR",
    lifestyle_text: "LIFESTYLE",
    team_text: "TEAM",
    rank_text: "RANK",
    generation_text: "GENERATION",
    download_text: "DOWNLOAD",
    purchase_now_text: "Purchase NOW",
    provizion_text: "Provizion",
    ingredients_text: "Ingredients",
    faq_text: "FAQ",
    faq_para: "Most frequent questions and answers",
    logout: "Logout",
    byNow_text: "Buy Now",
    createAccountSave_text: "Create Account & Save",
    addToCart_text: "Add to Cart",
    saveMyCard_text: "Save My Card",
    welcomeText: 'Welcome',
    footer_discription: `
    †These statements have not been evaluated by the Food and Drug Administration. 
    Provizion products are not intended to diagnose, prevent, treat, or cure any disease. 
    If you are under medical supervision for any allergy, disease, taking prescription medications, or if you are breastfeeding, contact your medical provider before adding any new supplements to your daily regimen. 
    This product is not for use by or sale to persons under the age of 18.
    `
}

export default COMMON_TEXTS;