import { DialogActions } from '@mui/material';
import _ from 'lodash';
import React from 'react'
import ReactCountryFlag from "react-country-flag";
import DialogComponent from '../../Inputs/DailogComponent';
import localStorageCall from '../../services/Methods/localstorage.hook';
import { ButtonCssClass, storeAssigned } from '../../services/Methods/normalMethods';

const CountryFlagDropdown = ({
    countyObjects, showCountries, selectedStore, setSelectedStore, setShowCountries
}) => {
    return (
        <>
            <ul className={`dropdown-menu hidden dropdown-menu-right show did-fade-in ${showCountries ? "open-country" : ""} [&.open-country]:block`} x-placement="top-end">
                {_.map([
                    ..._.reverse(_.filter(countyObjects, (row) => _.includes(['US', 'CA'], row?.code))),
                    ..._.reject(countyObjects, (row) => _.includes(['US', 'CA'], row?.code))],
                    (row) => (
                        <li key={row?.code} className="" onClick={() => {
                            setSelectedStore((prv) => ({
                                ...prv, newCode: _.lowerCase(row?.code)
                            }));
                            setShowCountries(!showCountries);
                        }}>
                            <button className="dropdown-item !py-1">
                                <ReactCountryFlag className="" countryCode={row?.code} style={{ fontSize: '1em', marginRight: '4px', lineHeight: '2em' }} svg />
                                {row?.name}
                            </button>
                        </li>
                    ))}
            </ul>
            <DialogComponent
                opend={!!(selectedStore?.defaultCode !== selectedStore?.newCode)}
                handleClose={() => setSelectedStore((prv) => ({ ...prv, newCode: prv?.defaultCode }))}
                // titleBanner={ }
                title='Change Country'
                classFor={"country_askingPopup"}
            >
                <div>
                    <p>Are you sure you want to change country? Your cart will be emptied and you will be sent back to the homepage.</p>
                </div>
                <DialogActions className='action_button justify-center' >
                    <button className={ButtonCssClass} onClick={() => setSelectedStore((prv) => ({ ...prv, newCode: prv?.defaultCode }))}>Cancel</button>
                    <button className={ButtonCssClass} onClick={() => {
                        _.map(['normal', 'retail', 'preferred', 'IBO'], (row) => {
                            localStorageCall().removeItem(String(`${row}Products`));
                            localStorageCall().setItem('store', selectedStore?.newCode);
                            setSelectedStore((prv) => ({ ...prv, defaultCode: prv?.newCode }))
                            window.location.assign(`/${selectedStore?.newCode}`)
                        })
                    }}>Ok</button>
                </DialogActions>
            </DialogComponent>
        </>
    )
}

export default CountryFlagDropdown;