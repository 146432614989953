import _ from "lodash";
import PaymentSection from "../../../Common/Components/AllFormsCommons/PaymentSection";
import SavedCards from "../../../Common/Components/AllFormsCommons/SavedCards";

const PaymentAndSavedCard = (props) => {
    const {
        isUserLogin, paymentOptions, paymentFormHtml, savedCards, _addNewCard, selectedPayment, setSelectedPayment,
        _handleCardChange, savedThisCard, setSavedThisCard, AllProductAndCheckoutText, sameAsBillingChecked, _paymentOptionSetBilling, setSameAsBillingChecked, addressDetails,
    } = props;
    const { SavedCards_TEXTS } = AllProductAndCheckoutText;
    return (
        <div className="main_PaymentSaved_section">
            <div className="payment_heading">
                <h3>Payment</h3>
            </div>
            <div className="PaymentSaved_css">
                <div className="saved_add_section">
                    {isUserLogin &&
                        <SavedCards {...{
                            paymentOptions, _handleCardChange, selectedPayment, setSelectedPayment, savedCards,
                            SavedCards_TEXTS
                        }} />
                    }
                    {(selectedPayment?.selectedOptionForPayment === 2) &&
                        <>
                            {(selectedPayment?.selectedPaymentDropdown === 1) &&
                                <div className="mt-4 d-flex sameAsShipping-checkbox field-class neweletter-subscrib ">
                                    <input
                                        type="checkbox"
                                        className=''
                                        style={{ top: '-2px' }}
                                        name="sameAsBilling"
                                        checked={sameAsBillingChecked}
                                        onChange={(e) => {
                                            if (e?.target?.checked) {
                                                setSameAsBillingChecked(e?.target?.checked);
                                                _paymentOptionSetBilling(addressDetails);

                                            } else {
                                                setSameAsBillingChecked(e?.target?.checked);
                                                window.paymentOption?.clearBilling();
                                            }
                                        }} />
                                    {/* {(paymentOptions?.length > 0 && paymentOptions[0]?.paymentOptionTypeDescription !== 'Redirect') && */}
                                    <p className='' style={{ fontSize: '20px', margin: 0 }}>Same as shipping</p>
                                    {/* } */}
                                </div>
                            }
                            <PaymentSection {...{
                                selectedPayment,
                                minHeight: _.find(paymentOptions, { paymentOptionId: selectedPayment?.selectedPaymentDropdown })?.paymentOptionTypeDescription === 'Redirect' ? "50px" : "630px",
                                paymentFormHtml,
                                savedThisCard,
                                setSavedThisCard,
                            }} />
                        </>
                    }
                </div>

            </div>
        </div>
    );
}

export default PaymentAndSavedCard;