import React from 'react';
import LoaderComponent from '../../../Inputs/LoaderComponent';
import { ProductDisplayPropsType } from '../../../services/Methods/PropsTypesInterface';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ButtonComponent from '../../../Inputs/ButtonComponent';
import withProductHOC from '../../../services/HOC/withProductHOC';
import { FloatValues, getCurrencySign, storeAssigned } from '../../../services/Methods/normalMethods';
import { useOutletContext } from 'react-router-dom';
import localStorageCall from '../../../services/Methods/localstorage.hook';

const ProductDisplaySection = ({
    onetime, _handleProductAdd, section, cartProducts, activeStep, setActiveStep, allProductQuantity, allProductState, selectedCategory, setSelectedCategory,
    _addToCartFunction, _quantityIncOrDec, isLearnMore, loading, navigate, CATEGORIES,

}) => {
    const FilterData = !onetime ? allProductState?.filter((row) => row?.isEligibleForAutoOrder === true) : allProductState;
    const { autoshipOrders } = useOutletContext();
    const CUSTOMER_TYPE = localStorageCall().getItem('customerTypeDescr');
    const SECTION_USE = (CUSTOMER_TYPE?.role && section === 'normal') ? CUSTOMER_TYPE?.role : section;
    const FilteredCatgories = CATEGORIES.filter(val => val.id !== 1084);

    const NewCategories = !_.includes(['IBO', 'preferred'], SECTION_USE) ? FilteredCatgories : CATEGORIES

    return (
        <>
            {loading ? (
                <LoaderComponent />
            ) : (
                <div className={`flex ${(section === 'normal') ? 'lg:flex-nowrap' : ''} flex-wrap w-full lg:space-x-10`}>
                    {(section === 'normal' && onetime) &&
                        <div className="lg:w-[260px] w-full">
                            <h2 className="text-xl tracking-wider f-q-extrabold mb-4 lg:text-left text-center">
                                Shop By Category
                            </h2>
                            <div className="flex flex-wrap lg:flex-col lg:justify-start justify-center gap-2 lg:max-w-full max-w-[260px] mx-auto">
                                {NewCategories.map((data, index) => (
                                    <p
                                        onClick={() => setSelectedCategory(data?.id)}
                                        className={`lg:w-full w-full px-[20px] rounded-[30px] border-[2px] border-button-color pt-[5px] py-[7px] cursor-pointer hover:text-primary transition-all duration-200 text-base font-medium hover:bg-button-color hover:text-white lg:text-left text-center
                                    ${(selectedCategory === data?.id)
                                                ? "f-q-extrabold bg-button-color text-white"
                                                : "f-q-regular text-button-color "
                                            }`}
                                        key={index}
                                    >
                                        {data?.name?.replace('_', ' ')}
                                    </p>
                                ))}
                            </div>
                        </div>
                    }
                    <div className="w-full lg:mt-0 mt-[30px] my-0 mx-auto grid xl:grid-cols-3 sm:grid-cols-2 xl:gap-6 lg:gap-5 md:gap-4 sm:gap-3 gap-3">
                        {
                            (FilterData?.length > 0) ? _.map(FilterData, ({ points, id, image, title, price, stock, ...row }, index) => (
                                <div key={"Allproduct" + index} className="lg:rounded-3xl md:rounded-2xl rounded-xl shadow-[0px_0px_10px_0_rgba(0,0,0,0.1)] bg-[#fdfdfd] p-5 flex flex-col">
                                    <>
                                        <div
                                            className="xl:h-[250px] h-[200px] lg:mb-5 md:mb-4 sm:mb-2 mb-3 flex items-center justify-center cursor-pointer"
                                            onClick={() => navigate(`/${storeAssigned()}/shop/${id}/${_.lowerCase(title)?.replaceAll(/ /g, "_")}`)}
                                        >
                                            <img
                                                src={image || ""}
                                                className="w-full h-full object-contain"
                                                alt="product"
                                            />
                                        </div>
                                        <div className="flex flex-col justify-between flex-1">
                                            <div className="mb-2 w-full">
                                                <h3 className="md:text-[17px] sm:text-[16px] text-lg f-q-extrabold text text-center">
                                                    {title}
                                                </h3>
                                            </div>
                                            {/* {section === 'normal' && !!points && <p className='flex flex-col justify-between flex-1 text-center mb-2'>Points Earned = {points}</p>} */}
                                            <div className="btn-group w-full">
                                                {_quantityIncOrDec &&
                                                    <ButtonComponent classes="bg-inherit  w-full max-w-[110px]">
                                                        <div className="flex items-center text-button-color justify-around lg:text-sm xl:text-lg !leading-3">
                                                            <span
                                                                className="hover:text-primary cursor-pointer py-2 px-2 hover:!text-[#f586a3]"
                                                                onClick={() => _quantityIncOrDec(id, "sub")}
                                                            >
                                                                -
                                                            </span>
                                                            <span>{_.find(allProductQuantity, { id })?.quantity || row?.quantity}</span>
                                                            <span
                                                                className="hover:text-primary cursor-pointer py-2 px-2 hover:!text-[#f586a3]"
                                                                onClick={() => _quantityIncOrDec(id, "add")}
                                                            >
                                                                +
                                                            </span>
                                                        </div>
                                                    </ButtonComponent>
                                                }
                                                {isLearnMore &&
                                                    <ButtonComponent link={`${id}/${_.lowerCase(title)?.replaceAll(/ /g, "_")}`} text="Learn More" />
                                                }
                                                {(() => {
                                                    let oneTimePrice = row[section + '_oneTime'] || price;
                                                    if (_.includes(['normal', 'retail', 'preferred', 'IBO'], section)) {
                                                        let isAutoship = _.find(cartProducts, { frequency: 'autoship' }) || !!(_.find(autoshipOrders, { isActive: true }));
                                                        if (selectedCategory === 1084) {
                                                            isAutoship = !!(_.find(autoshipOrders, { isActive: true }));

                                                        }
                                                        oneTimePrice = isAutoship ? row[section + '_autoship'] : row[section + '_oneTime'] || price;
                                                    }
                                                    const MissionPackProduct = !_.includes(title, 'Mission Promo Pack');
                                                    const CURRENT_STORE = _.upperCase(storeAssigned()) || 'US';

                                                    return (
                                                        <>
                                                            {onetime && oneTimePrice && MissionPackProduct &&
                                                                <ButtonComponent classes="min-w-[250px] mx-auto flex justify-evenly items-center gap-[5px] !px-[6px]" onClick={() => _addToCartFunction(id, 'oneTime')} >
                                                                    {getCurrencySign(CURRENT_STORE)}{FloatValues(oneTimePrice)} <i className="fas fa-cart-plus"></i> Single Order
                                                                </ButtonComponent>
                                                            }
                                                            {row?.isEligibleForAutoOrder === true && (row[section + '_autoship'] !== 0) &&
                                                                <ButtonComponent classes="min-w-[250px] mx-auto  flex  justify-evenly items-center gap-[5px] !px-[6px]" onClick={() => {
                                                                    if (!onetime) {
                                                                        _handleProductAdd({ points, id, image, title, price, ...row })
                                                                    }
                                                                    else {
                                                                        _addToCartFunction(id, 'autoship')
                                                                    }

                                                                }} >
                                                                    {getCurrencySign(CURRENT_STORE)}{FloatValues(row[section + '_autoship'] || row?.autoship)} <i className="fas fa-cart-plus"></i> Add to SmartShip
                                                                </ButtonComponent>
                                                            }
                                                        </>
                                                    )
                                                })()}
                                            </div>
                                        </div>
                                    </>

                                </div>
                            ))
                                :
                                <div className="flex justify-center items-center w-full text-[18px] col-span-full">No product Found</div>
                        }
                    </div>
                    {(typeof (activeStep) !== 'string') && (
                        <div className='w-full mt-4 justify-center flex gap-x-[20px]'>
                            <ButtonComponent onClick={() => setActiveStep(activeStep + 1)} > Next </ButtonComponent>
                            <ButtonComponent onClick={() => setActiveStep(activeStep + 1)} > Skip </ButtonComponent>
                        </div>
                    )}
                </div>
            )}
        </ >
    )
}

ProductDisplaySection.propTypes = {
    onetime: PropTypes.bool,
    allProductState: PropTypes.arrayOf(PropTypes.shape(ProductDisplayPropsType)),
    _addToCartFunction: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    _quantityIncOrDec: PropTypes.func,
    activeStep: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    allProductQuantity: PropTypes.arrayOf(PropTypes.object),
    addToCartProducts: PropTypes.object,
    setOpenMiniCart: PropTypes.func,
    cartProducts: PropTypes?.arrayOf(PropTypes?.object),
    CATEGORIES: PropTypes?.arrayOf(PropTypes?.object),
    selectedCategory: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.any
    ]),
    setSelectedCategory: PropTypes.func.isRequired,
};

ProductDisplaySection.defaultProps = {
    onetime: true,
    isLearnMore: false,
    allProductState: [],
    _addToCartFunction: (data, section) => console.log(data, section),
    loading: false,
    section: 'retail',
    _quantityIncOrDec: null,
    activeStep: '0',
    allProductQuantity: [],
    addToCartProducts: {},
    cartProducts: [],
    CATEGORIES: [],
    selectedCategory: 0,
    setSelectedCategory: (data) => console.log('setOpenMiniCart', data),
    setOpenMiniCart: (data) => console.log('setOpenMiniCart', data)
}

export default withProductHOC(ProductDisplaySection)