import React from 'react'
import About2 from '../assets/about2.jpg';


const AboutSecondSection = (props) => {
  const { ABOUT_TEXTS } = props;
  return (
    <>
      <div className="flex flex-wrap">
        <div className="md:w-1/2 w-full py-9 md:px-9 px-[15px] md:order-none order-2">
          <div className="lg:py-[36px] max-w-[560px] mx-auto">
            <h2 className="text-[28px] leading-[1.2] font-bold text-heading-color mb-[12px] pb-4">
              {ABOUT_TEXTS?.pro_heart}
            </h2>
            <p className="mb-4 text-base text-content-color pb-4">
              {ABOUT_TEXTS?.compassionate_creator}
            </p>
          </div>
        </div>
        <div className="md:w-1/2 w-full min-h-[240px] bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url(${About2})` }} >
        </div>
      </div>

    </>

  )
}

export default AboutSecondSection;