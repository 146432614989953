import React from 'react';
import { useOutletContext } from 'react-router-dom';
import StepperComponentInputs from '../../Inputs/StepperComponent.inputs';
import withStepperHOC from '../../services/HOC/withStepperHOC';
import withRouter from '../../services/HOC/withRouterHOC';
import withFormHOC from '../../services/HOC/withFormHOC';
import usePreferredSignupHook from './components/PreferredSignup.hooks';
import LoaderComponent from '../../Inputs/LoaderComponent';
import ShoppingCartComponent from '../../Common/Components/AllFormsCommons/ShoppingCartComponent';
import withMiniCartHOC from '../../services/HOC/withMinCartHOC';
import MainStepperSignUp from '../../Common/Components/AllFormsCommons/MainStepperSignUp';
import LayoutComponent from '../../Layout';

const SECTION = 'preferred';
const isWebAlias = true;

const PreferredCustomerSignup = ({ SIGNUP_TEXT, ...props }) => {
    const { countryCodes, ...others } = useOutletContext();
    const data = usePreferredSignupHook(props);

    return (
        <LayoutComponent  {...{ ...props, ...others }}>
            <div className="container mx-auto max-w-[1260px] px-[15px]">
                {data?.isLoginLoading && <LoaderComponent />}
                <StepperComponentInputs steps={SIGNUP_TEXT?.Register_Steps[data?.FormId]} activeStep={data?.activeStep}>
                    <MainStepperSignUp {...{
                        section: SECTION,
                        cartProducts: props?.addToCartProducts?.preferredProducts,
                        countryCodes,
                        navigate: props?.navigate,
                        isWebAlias, ...data
                    }} />
                </StepperComponentInputs>
                <ShoppingCartComponent
                    section={SECTION}
                    _minCartQuantityIncDec={props?._minCartAndViewCartQuantityIncDec}
                    openMiniCart={props?.openMiniCart}
                    setOpenMiniCart={props?.setOpenMiniCart}
                    _deleteProductFromCart={props?._deleteProductFromCart}
                    productData={props?.addToCartProducts?.preferredProducts}
                    activeStep={data?.activeStep}
                    setActiveStep={data?.setActiveStep}
                    _autoShipCheck={props?._autoShipCheck} />
            </div>
        </LayoutComponent>
    );
};
export default withRouter(withStepperHOC(withFormHOC(withMiniCartHOC(PreferredCustomerSignup, SECTION))));