import React from 'react'
import withRouter from '../../services/HOC/withRouterHOC';
import NewPassWord from './components/NewPassword';
import ResetEmailVarified from './components/ResetEmailVarified';
import ResetPasswordhook from './components/ResetPassWord.hook';

function ForgotPasswordPage(props) {
    const { searchParams, ROUTING_TEXT } = props;
    const data = ResetPasswordhook({ searchParams });
    return (
        <section className="min-h-[calc(100vh-256px)] py-[50px] bg-[#f7f7f7]">
            <div className="container xl:max-w-[1260px] mx-auto px-[15px]">
                <div className="max-w-[380px] mx-auto text-center">
                    {!searchParams.get('sessionId')
                        ?
                        <ResetEmailVarified {...{ ROUTING_TEXT, ...data }} />
                        :
                        <NewPassWord  {...{ ROUTING_TEXT, ...data }} />
                    }
                </div>
            </div>
        </section>
    )
}

export default withRouter(ForgotPasswordPage);