import signUpLogoImg from '../assets/logo-black.png';

const SignUpHeader = (props) => {
    return (
        <header className="signup-header ">
            <div className="container mx-auto my-0">
                <div className="py-2">
                    <a href="/">
                        <img src={signUpLogoImg} alt="Provizion Global" className='mx-auto my-0' />
                    </a>
                </div>
            </div>
        </header>
    )
}

export default SignUpHeader;